import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Component } from "react";
import Axios from 'axios';
import { Badge, Button, Card, CardBody, CardText, CardTitle, Col, Container, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import * as XLSX from 'xlsx';
import ReactSpeedometer from "react-d3-speedometer"
import Select from "react-select";

import './../../assets/scss/custom/components/_enterpriseProfle.scss';

const id = window.location.search.replace('?id=', '')

class EnterpriseProfile extends Component {
    constructor(props) {
      super(props);

      this.state = {
        breadcrumbItems: [
          { title: "Fast Sale", link: "/dashboard" },
          { title: "Empreendimentos", link: "#" },
      ],
      enterprise:[],
      erro_send_aprovation:false,
      modal_center:false,
      modal_workflow:false,
      modal_logo:false,
      enterprise_score:0,
      enterprise_types_score:[],
      new_logo: '',
      delete_id:null,
      status:'1',
      workflow_observation:'',
      workflow:'',
      isEditingTitle: false,
      newTitle: '',
      title:'',
      launch:null,
      full:null,
      selectedConstruction:[],
      selectOptionsConstructions:[],
      error_auth:false,
      confirm_both:false,
        error_update:false,
        success_update:false,
      }
      this.tog_center = this.tog_center.bind(this);
      this.tog_workflow = this.tog_workflow.bind(this);
      this.tog_logo = this.tog_logo.bind(this);
      this.createTypeUnit = this.createTypeUnit.bind(this);
      this.deleteType = this.deleteType.bind(this);
    }

    tog_center() {
        this.setState(prevState => ({
          modal_center: !prevState.modal_center
        }));
        this.removeBodyCss();
    }

    tog_workflow() {
        this.setState(prevState => ({
          modal_workflow: !prevState.modal_workflow
        }));
        this.removeBodyCss();
    }

    tog_logo() {
        this.setState(prevState => ({
          modal_logo: !prevState.modal_logo
        }));
        this.removeBodyCss();
    }
    
    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    componentDidMount(){
        document.getElementById('load').classList.add('active')
        Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
            type: localStorage.getItem('access'),
            user_id: localStorage.getItem('id'),
            }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(response => {

                Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/property-verify', {
                        construction_id: localStorage.getItem('access') !== 'S' ? parseInt(localStorage.getItem('id')) : parseInt(localStorage.getItem('construction_id')),
                        property_enterprise_id : parseInt(id),
                    }, {headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
                }).then(response => {

                    if(localStorage.getItem('access') === 'A'){
                        Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/constructions',
                            {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                            .then(response => {
                              this.setState({selectOptionsConstructions : (response.data?.map(item => ({
                                value: item.id,
                                label: item.name
                              })))
                            })})
                    }


                    if(response.data.relacionado || localStorage.getItem('access') === 'A'){
                        Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprises/' + id, {
                            headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
                            }).then(response => {
                                this.setState({enterprise: response.data})
                                this.setState({launch: response.data.launch})
                                this.setState({full: response.data.full})
                                this.setState({status: response.data.status})
                                this.setState({workflow_observation: response.data.workflow_observation})
                                this.setState({workflow: response.data.workflow})
                                localStorage.setItem('enterprise_selected_id', response.data.id)
                                document.getElementById('load').classList.remove('active')
                                
                                if(response.data.construction?.id){
                                    this.setState({selectedConstruction:{
                                        value: response.data.construction.id,
                                        label: response.data.construction.name
                                    }})
                                }

                                this.handleEnterpriseScore(response.data)
                            })
                    }else{
                        setTimeout(() => {
                            window.open("/dashboard", '_parent')
                        }, 300);
                    }
                })

            })
        .catch(response =>{
            setTimeout(() => {
                window.open("/login", '_parent')
            }, 300);
            })            
    }

    handleEnterpriseScore(data){
        let score = 0;
        let subScore = 0;

        if(data.area && data.area !== ''){score += 10}
        if(data.catalog && data.catalog !== ''){score += 40}
        if(data.cep && data.cep !== ''){score += 10}
        if(data.city && data.city !== ''){score += 10}
        if(data.country && data.country !== ''){score += 10}
        if(data.delivery && data.delivery !== ''){score += 30}
        if(data.district && data.district !== ''){score += 10}
        if(data.description && data.description !== ''){score += 40}
        if(data.details && data.details.length > 3){score += 30}
        if(data.docs && data.docs.length > 0){score += 10}
        if(data.floors && data.floors !== 0){score += 10}
        if(data.latitude && data.latitude !== ''){score += 10}
        if(data.longitude && data.longitude !== ''){score += 10}
        if(data.logo && data.logo !== ''){score += 20}
        if(data.number && data.number !== 0){score += 10}
        if(data.payment && data.payment.length > 0){score += 20}
        if(data.photos && data.photos.length > 2){score += 70}
        if(data.plans && data.plans.length > 0){score += 20}
        if(data.videos && data.videos.length > 0){score += 20}
        if(data.progress_photos && data.progress_photos.length > 0){score += 20}
        if(data.progress_videos && data.progress_videos.length > 0){score += 20}
        if(data.progress && data.progress !== 0){score += 30}
        if(data.register && data.register !== ''){score += 10}
        if(data.start && data.start !== ''){score += 20}
        if(data.state && data.state !== ''){score += 10}
        if(data.street && data.street !== ''){score += 10}
        if(data.title && data.title !== ''){score += 20}
        if(data.tour_360 && data.tour_360 !== ''){score += 40}
        if(data.types && data.types.length > 0){score += 30}

        const enterpriseTypesScore = [];

        data.types.forEach(type => {
            let typeScore = 0;
    
            if (type.bathrooms && type.bathrooms !== 0) { typeScore += 10; }
            if (type.description && type.description !== '') { typeScore += 20; }
            if (type.details && type.details.length > 0) { typeScore += 10; }
            if (type.garage && type.garage !== 0) { typeScore += 10; }
            if (type.photos && type.photos.length > 3) { typeScore += 20; }
            if (type.plans && type.plans.length > 0) { typeScore += 20; }
            if (type.private_area && type.private_area !== '') { typeScore += 10; }
            if (type.rooms && type.rooms !== 0) { typeScore += 10; }
            if (type.suites && type.suites !== 0) { typeScore += 10; }
            if (type.title && type.title !== '') { typeScore += 30; }
            if (type.total_area && type.total_area !== '') { typeScore += 10; }
            if (type.units && type.units.length > 0) { typeScore += 40; }
    
            enterpriseTypesScore.push({ id: type.id, total: typeScore /2 });
    
            subScore += typeScore;
        });
    
        score += data.types.length > 0 ? ((subScore * 2) / data.types.length) : 0;
    
        this.setState({ 
            enterprise_score: score,
            enterprise_types_score: enterpriseTypesScore
        });
    }

    createTypeUnit(event) {
        document.getElementById('load').classList.add('active')

        const data = new FormData();
        data.append('title', this.state.title)
        data.append('property_enterprise_id', id)


        Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/enterprise-properties-types', data, {
            headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
        }).then(response => {
            setTimeout(() => {
                window.open('./../empreendimento-tipo?id=' + response.data.data.id, '_parent')
            }, 300);
         })

   }

   deleteType = (id) => {
    this.setState({delete_id: id})
    this.setState({confirm_both: true})
   }

   updateEnterprise(){

    if(localStorage.getItem('access') === 'S' && localStorage.getItem('construction_role') === 'view'){
        this.setState({error_auth:true})
    }else{

    document.getElementById('load').classList.add('active')

        const data = new FormData();
        if(localStorage.getItem('access') === 'T'){
            data.append('construction_id', localStorage.getItem('id'))
        }
        if(localStorage.getItem('access') === 'S'){
            data.append('construction_id', localStorage.getItem('construction_id'))
        }
        if(localStorage.getItem('access') === 'A'){
            data.append('construction_id', this.state.selectedConstruction.value)
        }
        // data.append('construction_id', localStorage.getItem('id'))
        data.append('status', this.state.status)
        data.append('workflow', 0)

        Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprises-update/'+ id, data, {
        headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
        }).then(response => {
            this.setState({success_update : true})
            document.getElementById('load').classList.remove('active')
        }).catch(response => {
            this.setState({error_update : true})
            document.getElementById('load').classList.remove('active')
        })
    }
   }

   createSlug(title) {
    let slug = title.toLowerCase().replace(/[^\w\s-]/g, '');
  
    slug = slug.replace(/\s+/g, '-');
  
    return slug;
  }

   enviarPlanilha(e){
    document.getElementById('load').classList.add('active')
    const file = e.target.files[0];

    const reader = new FileReader();

    reader.onload = (e) => {
        const workbook = XLSX.read(e.target.result, { type: "binary" });

        const worksheet = workbook.Sheets[workbook.SheetNames[0]];

        const data = XLSX.utils.sheet_to_json(worksheet);

        for(let i = 0; data.length > i; i++){
            const type = new FormData();
            type.append('title', data[i]['Tipo'])
            type.append('property_enterprise_id', id)
            type.append('private_area', data[i]['Area Privativa (0,00)'])
            type.append('total_area', data[i]['Area Total (0,00)'])
            type.append('description', data[i]['Descricao'])
            type.append('rooms', data[i]['Dormitorios'])
            type.append('suites', data[i]['Suites'])
            type.append('garage', data[i]['Vagas Garagem'])
            type.append('bathrooms', data[i]['Banheiros'])
    
    
             Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/enterprise-properties-types', type, {
                 headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
                }).then(response => {

                    const dataEnterpriseUnits = new FormData();
                    for(let i = 0; data.length > i; i++){
                        if(data[i]['Tipo'] === response.data.data.title){
                         dataEnterpriseUnits.append(`unit[${i}][slug]`, this.createSlug(response.data.data.title + data[i]['Unidade']) || '')
                         dataEnterpriseUnits.append(`unit[${i}][sku]`, data[i]['Unidade'] || 0)
                         dataEnterpriseUnits.append(`unit[${i}][floor]`, 0)
                         dataEnterpriseUnits.append(`unit[${i}][price]`, (parseInt(data[i]['Valor']) * 100) || 0)
                         dataEnterpriseUnits.append(`unit[${i}][offer_price]`, 0)
                         dataEnterpriseUnits.append(`unit[${i}][payment_condition]`, 0)
                         dataEnterpriseUnits.append(`unit[${i}][status]`, data[i]['Status'] === 'Disponível' ? 1 : data[i]['Status'] === 'Reservado' ? 2 : data[i]['Status'] === 'Vendido' ? 3 : 0)
                         dataEnterpriseUnits.append(`unit[${i}][property_enterprise_type_id]`, response.data.data.id)
                        }

                    }
                    Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/enterprise-properties-types-units', dataEnterpriseUnits, {
                        headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
                    }).then(response => {
                        

                        Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprises/' + id, {
                            headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
                            }).then(response => {
                                this.setState({enterprise: response.data})
                            })

                            setTimeout(() => {
                                document.getElementById('load').classList.remove('active')
                            }, 500);
                    })

                })
        }

    };

    reader.readAsBinaryString(file);
  }

    render(){
        const {enterprise} = this.state;

        const opcoesDisponiveis = [
            'Apartamento Tipo 1',
            'Apartamento Tipo 2',
            'Apartamento Tipo 3',
            'Apartamento Tipo 4',
            'Apartamento Tipo 5',
            'Apartamento Tipo 6',
            'Apartamento Tipo 7',
            'Apartamento Tipo Duplex',
            'Apartamento Tipo Garden (diferenciados)',
            'Cobertura 1',
            'Cobertura 2',
            'Cobertura 3',
            'Cobertura 4',
            'Cobertura 5',
            'Cobertura Duplex',
            'Cobertura Triplex',
            'Sala Comercial',
            'Sala Comercial Tipo 1',
            'Sala Comercial Tipo 2',
            'Sala Comercial Tipo 3',
            'Sala Comercial Tipo 4',
            'Sala Comercial Tipo 5',
          ];

          const opcoesNaoExistentes = opcoesDisponiveis.filter(opcao => {
            return !enterprise.types?.some(type => type.title === opcao);
          });

        return(
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title={enterprise?.title} breadcrumbItems={this.state.breadcrumbItems} />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Row className="actions-row">
                                        {localStorage.getItem('access') === 'A' &&
                                        <Col md={3}>
                                            <Label className="form-label">Construtora</Label>
                                            <Select
                                                value={this.state.selectedConstruction}
                                                placeholder="Selecione"
                                                noOptionsMessage={(inputValue) => `Sem resultados`}
                                                onChange={(e) => {
                                                    const data = new FormData();
                                                    data.append('construction_id', e.value)
                                            
                                                    Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprises-update/'+ id, data, {
                                                    headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
                                                    }).then(response => {
                                                        setTimeout(() => {
                                                            window.location.reload()
                                                        }, 100);
                                                    })
                                                    this.setState({selectedConstruction: e})}}
                                                options={this.state.selectOptionsConstructions}
                                                classNamePrefix="select2-selection"
                                            />
                                        </Col>
                                        }
                                        {enterprise?.workflow !== 11 &&
                                        <Col  className="col-auto" style={{display:'flex', alignItems:'center', gap:'10px'}}>
                                            <Label>Status:</Label>
                                            <select style={{maxWidth:'80px'}} className="form-control" value={this.state.status} onChange={(e) => {
                                                    const data = new FormData();
                                                    if(localStorage.getItem('access') === 'T'){
                                                        data.append('construction_id', localStorage.getItem('id'))
                                                    }
                                                    if(localStorage.getItem('access') === 'S'){
                                                        data.append('construction_id', localStorage.getItem('construction_id'))
                                                    }
                                                    if(localStorage.getItem('access') === 'A'){
                                                        data.append('construction_id', this.state.selectedConstruction.value)
                                                    }
                                                    data.append('status', e.target.value) 
                                            
                                                    Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprises-update/'+ id, data, {
                                                    headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
                                                    }).then(response => {
                                                        setTimeout(() => {
                                                            window.location.reload()
                                                        }, 100);
                                                    })
                                                }}>
                                                <option value={0}>Inativo</option>
                                                <option value={1}>Ativo</option>
                                            </select>
                                        </Col>}
                                        <Col  className="col-auto" style={{paddingBottom:'5px'}}>
                                            <Label style={{display:'flex', gap:'5px', justifyContent:'flex-end'}} onClick={() => {
                                                    this.setState({modal_workflow: true})
                                                }}><span className="desktop-available">Situação:</span><i className="ri-error-warning-line"style={{cursor:'pointer'}}></i> 
                                                {enterprise?.workflow === 0 ? <Badge className="bg-warning me-1 rounded-pill" style={{fontSize:'16px', cursor:'pointer'}}>Pendente</Badge>
                                                : enterprise?.workflow === 1 ? <Badge className="bg-danger me-1 rounded-pill" style={{fontSize:'16px', cursor:'pointer'}}>Rep. Fotos</Badge>
                                                : enterprise?.workflow === 2 ? <Badge className="bg-danger me-1 rounded-pill" style={{fontSize:'16px', cursor:'pointer'}}>Rep. Aut. Venda</Badge>
                                                : enterprise?.workflow === 3 ? <Badge className="bg-danger me-1 rounded-pill" style={{fontSize:'16px', cursor:'pointer'}}>Rep. Prop.</Badge>
                                                : enterprise?.workflow === 4 ? <Badge className="bg-danger me-1 rounded-pill" style={{fontSize:'16px', cursor:'pointer'}}>Rep. Imóvel</Badge>
                                                : enterprise?.workflow === 12 ? <Badge className="bg-danger me-1 rounded-pill">Rep. Fora do padrão</Badge>
                                                : enterprise?.workflow === 5 ? <Badge className="bg-dark me-1 rounded-pill" style={{fontSize:'16px', cursor:'pointer'}}>Repetido</Badge>
                                                : enterprise?.workflow === 6 ? <Badge className="badge-soft-dark rounded-pill me-1" style={{fontSize:'16px', cursor:'pointer'}}>Vendido (ext)</Badge>
                                                : enterprise?.workflow === 8 ? <Badge className="badge-soft-dark rounded-pill me-1" style={{fontSize:'16px', cursor:'pointer'}}>Vendido (fs)</Badge>
                                                : enterprise?.workflow === 9 ? <Badge className="bg-danger me-1 rounded-pill" style={{fontSize:'16px', cursor:'pointer'}}>Rep. Imóvel</Badge>
                                                : enterprise?.workflow === 10 ? <Badge className="badge-soft-dark rounded-pill me-1" style={{fontSize:'16px', cursor:'pointer'}}>Bloqueado</Badge>
                                                : enterprise?.workflow === 11 ? <Badge className="badge-soft-dark rounded-pill me-1" style={{fontSize:'16px', cursor:'pointer'}}>Rascunho</Badge>
                                                : <Badge style={{fontSize:'16px', cursor:'pointer'}} className="bg-success me-1 rounded-pill">Aprovado</Badge>}
                                            </Label>
                                        </Col>
                                        {localStorage.getItem('access') === 'A' && (
                                        <>
                                        <Col  className="col-auto">
                                            <Label className="form-label">Lançamento</Label>
                                            <div className="form-switch" style={{
                                                height: '40px',
                                                display: 'flex',
                                                gap: '10px',
                                                alignItems: 'center'
                                            }}>
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    checked={this.state.launch === 1}
                                                    onChange={(e) => {
                                                        const newLaunchValue = e.target.checked ? 1 : 0;
                                                        this.setState({ launch: newLaunchValue });

                                                        const data = new FormData();
                                                        data.append('launch', newLaunchValue);

                                                        Axios.post(
                                                            process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprises-update/' + id,
                                                            data,
                                                            {
                                                                headers: { "Authorization": "Bearer " + localStorage.getItem('token') },
                                                            }
                                                        )
                                                    }}
                                                />
                                                <Label className="form-check-label" style={{ marginLeft: "10px" }}>
                                                    {this.state.launch === 1 ? "sim" : "Não"}
                                                </Label>
                                            </div>
                                        </Col>
                                        <Col className="col-auto">
                                            <Label className="form-label">100% Vendido</Label>
                                            <div className="form-switch" style={{
                                                height: '40px',
                                                display: 'flex',
                                                gap: '10px',
                                                alignItems: 'center'
                                            }}>
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    checked={this.state.full === 1}
                                                    onChange={(e) => {
                                                        const newFullValue = e.target.checked ? 1 : 0;
                                                        this.setState({ full: newFullValue });

                                                        const data = new FormData();
                                                        data.append('full', newFullValue);

                                                        Axios.post(
                                                            process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprises-update/' + id,
                                                            data,
                                                            {
                                                                headers: { "Authorization": "Bearer " + localStorage.getItem('token') },
                                                            }
                                                        )
                                                    }}
                                                />
                                                <Label className="form-check-label" style={{ marginLeft: "10px" }}>
                                                    {this.state.full === 1 ? "sim" : "Não"}
                                                </Label>
                                            </div>
                                        </Col>
                                        </>
                                    )}
                                        <Col className="col-auto">
                                            <h3 style={{fontSize:'11px', textAlign:'center'}}>Qualidade do cadastro</h3>
                                            <span className="termometer-container">
                                                <ReactSpeedometer
                                                    width={120}
                                                    needleHeightRatio={0.7}
                                                    value={this.state.enterprise_score}
                                                    currentValueText={parseInt(this.state.enterprise_score) + '/1000'}
                                                    ringWidth={7}
                                                    segments={5555}
                                                    maxSegmentLabels={0}
                                                    needleTransitionDuration={3333}
                                                    needleTransition="easeElastic"
                                                    needleColor={'#3E5E3F'}
                                                    textColor={'#333'}
                                                    valueTextFontWeight={'bold'}
                                                    labelFontSize={'8px'}
                                                    valueTextFontSize={'12px'}
                                                />
                                            </span>
                                        </Col>
                                    </Row>
                                    <div id="enterpriseProfile">
                                        <div className="profile">
                                            <div className="enterprise-note">

                                            </div>
                                            <div
                                            onClick={() => {
                                                if(localStorage.getItem('access') === 'S' && localStorage.getItem('construction_role') === 'view'){
                                                    this.setState({error_auth:true})
                                                }else{
                                                    this.tog_logo()
                                                }
                                            }}
                                            style={{
                                                backgroundImage:'url(' + enterprise?.logo + ')',
                                                backgroundSize:'contain',
                                                backgroundPosition:'center center',
                                                width:'200px', height: '200px',
                                                backgroundColor:'#cdcdcd',
                                                backgroundRepeat:'no-repeat',
                                                position:'relative',
                                                borderRadius:'8px',
                                                marginBottom:'15px'
                                                }}>
                                                    <div style={{
                                                        cursor:'pointer',
                                                        display:'flex',
                                                        borderRadius:'10px',
                                                        backgroundColor:'#fff',
                                                        width:'130px',
                                                        padding:'0 10px',
                                                        height:'25px',
                                                        gap:'10px',
                                                        opacity:0.8,
                                                        alignItems:'center',
                                                        justifyContent:'space-around',
                                                        position:'absolute',
                                                        left:'50%',
                                                        bottom:'8px',
                                                        transform:'translate(-50%, 0)',
                                                    }}>Alterar logo <i className="ri-camera-fill"></i></div>
                                                </div>

                                            <span>{!this.state.isEditingTitle ? (
                                                <div>
                                                    <h3>{enterprise?.title}</h3>
                                                    <i 
                                                        className="ri-edit-line" 
                                                        style={{ cursor: 'pointer', fontSize: '20px' }}
                                                        onClick={() => this.setState({ isEditingTitle: true })}
                                                    ></i>
                                                </div>
                                            ) : (
                                                <div>
                                                <Input
                                                    type="text"
                                                    value={this.state.newTitle || enterprise?.title}
                                                    onChange={(e) => this.setState({ newTitle: e.target.value })}
                                                    style={{ marginBottom: '10px' }}
                                                />
                                                <Button
                                                    color="success"
                                                    onClick={() => {
                                                    const data = new FormData();
                                                    data.append('title', this.state.newTitle || enterprise?.title);

                                                    Axios.post(
                                                        process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprises-update/' + id,
                                                        data,
                                                        {
                                                        headers: { "Authorization": "Bearer " + localStorage.getItem('token') },
                                                        }
                                                    )
                                                        .then((response) => {
                                                        this.setState({
                                                            isEditingTitle: false,
                                                            enterprise: { ...enterprise, title: this.state.newTitle },
                                                            newTitle: '',
                                                        });
                                                        // setTimeout(() => {
                                                        //     window.location.reload();
                                                        // }, 300);
                                                        })
                                                        .catch(() => {
                                                        console.error('Erro ao atualizar o título.');
                                                        });
                                                    }}
                                                >
                                                    Salvar
                                                </Button>
                                                <Button
                                                    color="dark"
                                                    style={{ marginLeft: '10px' }}
                                                    onClick={() => this.setState({ isEditingTitle: false, newTitle: '' })}
                                                >
                                                    Cancelar
                                                </Button>
                                                </div>
                                            )}</span>

                                        </div>
                                        <div className="enterpriseContainerButtons">
                                            <div className={`enterpriseButton informacoes ${
                                                (enterprise.register
                                                && enterprise.description
                                                && enterprise.units_number
                                                && enterprise.area
                                                && enterprise.floors
                                                && enterprise.cep
                                                && enterprise.street
                                                && enterprise.number
                                                && enterprise.district
                                                && enterprise.state
                                                && enterprise.city) ? <></> : 'inactive'
                                            }`} onClick={() => {
                                                setTimeout(() => {
                                                    window.open('./../info-empreendimento?id=' + id, '_parent')
                                                }, 100);
                                            }}>
                                                <span className="buttonIcon"></span>
                                                Informações
                                                {!(enterprise.register
                                                && enterprise.description
                                                && enterprise.units_number
                                                && enterprise.area
                                                && enterprise.floors
                                                && enterprise.cep
                                                && enterprise.street
                                                && enterprise.number
                                                && enterprise.district
                                                && enterprise.state
                                                && enterprise.city) &&
                                                    <small>*</small>
                                                }
                                            </div>
                                            <div className={`enterpriseButton caracteristicas ${
                                                enterprise.details?.length > 0
                                                ? <></> : 'inactive'
                                            }`} onClick={() => {
                                                setTimeout(() => {
                                                    window.open('./../caracteristicas-empreendimento?id=' + id, '_parent')
                                                }, 100);
                                            }}>
                                                <span className="buttonIcon"></span>
                                                Características
                                            </div>
                                            <div className={`enterpriseButton pagamento ${
                                                enterprise.payment?.length > 0
                                                ? <></> : 'inactive'
                                            }`} onClick={() => {
                                                setTimeout(() => {
                                                    window.open('./../pagamento-empreendimento?id=' + id, '_parent')
                                                }, 100);
                                            }}>
                                                <span className="buttonIcon"></span>
                                                Condições de Pgto.
                                            </div>
                                            <div className={`enterpriseButton fotos ${
                                                enterprise.photos?.length > 0
                                                ? <></> : 'inactive'
                                            }`} onClick={() => {
                                                setTimeout(() => {
                                                    window.open('./../fotos-empreendimento?id=' + id, '_parent')
                                                }, 100);
                                            }}>
                                                <span className="buttonIcon"></span>
                                                Fotos do empreendimento 
                                                {!(enterprise.photos?.length > 0) &&
                                                    <small>*</small>
                                                }
                                            </div>
                                            <div className={`enterpriseButton plantas ${
                                                enterprise.plans?.length > 0
                                                ? <></> : 'inactive'
                                            }`} onClick={() => {
                                                setTimeout(() => {
                                                    window.open('./../plantas-empreendimento?id=' + id, '_parent')
                                                }, 100);
                                            }}>
                                                <span className="buttonIcon"></span>
                                                Plantas do empreendimento
                                            </div>
                                            <div className={`enterpriseButton divulgacao ${
                                                enterprise.docs?.length > 0
                                                ? <></> : 'inactive'
                                            }`} onClick={() => {
                                                setTimeout(() => {
                                                    window.open('./../anexos-empreendimento?id=' + id, '_parent')
                                                }, 100);
                                            }}>
                                                <span className="buttonIcon"></span>
                                                Documentos Anexos
                                            </div>
                                            <div className={`enterpriseButton videos ${
                                                enterprise.videos?.length > 0
                                                ? <></> : 'inactive'
                                            }`} onClick={() => {
                                                setTimeout(() => {
                                                    window.open('./../videos-empreendimento?id=' + id, '_parent')
                                                }, 100);
                                            }}>
                                                <span className="buttonIcon"></span>
                                                Vídeos
                                            </div>
                                            <div className={`enterpriseButton obra ${
                                                enterprise.start
                                                && enterprise.delivery
                                                && enterprise.progress
                                                ? <></> : 'inactive'
                                            }`} onClick={() => {
                                                setTimeout(() => {
                                                    window.open('./../andamento-empreendimento?id=' + id, '_parent')
                                                }, 100);
                                            }}>
                                                <span className="buttonIcon"></span>
                                                Andamento da Obra
                                            </div>
                                        </div>
                                    </div>
                                    <Row>
                                        <Col xs={12}><span style={{
                                            width:'100%',
                                            height:'1px',
                                            backgroundColor:'#cdcdcd',
                                            display:'block',
                                            margin:'30px auto 30px'
                                        }}></span></Col>
                                    </Row>
                                    <div className="enterpriseTypeUnitsContainer">
                                        <div className="typeUnitsTitle">Unidades</div>

                                        {!(localStorage.getItem('access') === 'S' && localStorage.getItem('construction_role') === 'view') &&
                                        <Row style={{margin: '20px auto 0'}}>
                                            <Col md={12}>
                                                <Card>
                                                    <CardBody>
                                                        <h4 className="card-title">Como cadastrar unidades?</h4>
                                                        <p className="card-title-desc"><b>Dicas:</b> Você pode cadastrar todas as unidades de uma vez, baixando nosso <b>modelo de planilha</b> no botão abaixo.</p>
                                                        <p className="card-title-desc">Para atualizar o cadastro, basta enviar o arquivo modelo de planilha com os dados atualizados.</p>
                                                        <p className="card-title-desc">Se preferir, edite os tipos de plantas separadamente através dos cards abaixo.</p>
                                                        <Row style={{marginTop:'10px', gap:'10px 0'}}>
                                                            <Col md={3} lg={3}>
                                                                <Button onClick={() => {
                                                                    setTimeout(() => {
                                                                        window.open("./../../AddUnidades.xlsx")
                                                                    }, 0);
                                                                }}>Modelo de planilha</Button>
                                                            </Col>
                                                            <Col md={9} lg={9}>
                                                                <div className="input-group">
                                                                    <input type="file" onChange={(e) => this.enviarPlanilha(e)} className="form-control" id="customFile" />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col> 
                                        </Row>
                                        }

                                        <div className="typeUnitsCardsGrid">
                                            {enterprise.types?.map((type, index) => (
                                                <div key={index}>
                                                <div className='type-score'>
                                                    <span className="termometer-container">
                                                        <ReactSpeedometer
                                                            width={100}
                                                            needleHeightRatio={0.7}
                                                            value={this.state.enterprise_types_score.length > 0 ? this.state.enterprise_types_score?.filter(score => score.id === type.id)[0].total : 0}
                                                            currentValueText={this.state.enterprise_types_score?.filter(score => score.id === type.id)[0]?.total + '/100'}
                                                            ringWidth={7}
                                                            segments={5555}
                                                            maxValue={100}
                                                            maxSegmentLabels={0}
                                                            needleTransitionDuration={3333}
                                                            needleTransition="easeElastic"
                                                            needleColor={'#3E5E3F'}
                                                            textColor={'#333'}
                                                            labelFontSize={'8px'}
                                                            valueTextFontSize={'12px'}
                                                        />
                                                    </span>
                                                </div>
                                                <div className="typeUnitsCard"
                                                    onClick={() => {
                                                        setTimeout(() => {
                                                            window.open('./../empreendimento-tipo?id=' + type.id, '_parent')
                                                        }, 300);   
                                                    }}
                                                    style={{
                                                        backgroundImage:'url(' + type.photos?.sort((a, b) => a.order - b.order)[0]?.url + ')',
                                                    }}>

                                                    <div className="content">
                                                        <i style={{
                                                            fontWeight:'200',
                                                            fontSize:'30px',
                                                        }} className="ri-edit-box-line"></i>
                                                        <p>{type.title}</p>
                                                    </div>
                                                    <div className="overlay"></div>
                                                </div>
                                                <div style={{
                                                    backgroundColor:"#da2c3e",
                                                    padding:'5px',
                                                    borderRadius:'0 0 8px 8px',
                                                    display:'flex',
                                                    gap:'0 5px',
                                                    alignItems:'center',
                                                    justifyContent:'center',
                                                    width:'120px',
                                                    margin:'auto',
                                                    cursor:'pointer'
                                                }}
                                                onClick={() => {
                                                    if(localStorage.getItem('access') === 'S' && localStorage.getItem('construction_role') === 'view'){
                                                        this.setState({error_auth:true})
                                                    }else{
                                                        this.deleteType(type.id)
                                                    }
                                                    }}>
                                                    <i
                                                        className="ri-delete-bin-6-line"
                                                        style={{
                                                            fontSize:'15px',
                                                            color:"#fff",
                                                        }}
                                                    ></i>
                                                    <p style={{color:'#fff'}}>Excluir</p>
                                                </div>
                                                </div>
                                            ))}
                                            <div className="typeUnitsCard new"
                                                onClick={() => {
                                                    if(localStorage.getItem('access') === 'S' && localStorage.getItem('construction_role') === 'view'){
                                                        this.setState({error_auth:true})
                                                    }else{
                                                        this.tog_center()
                                                    }
                                                }}>
                                                <div className="content">
                                                    <i style={{
                                                        fontWeight:'200',
                                                        fontSize:'30px',
                                                    }} className="ri-add-circle-line"></i>
                                                    <p>Novo tipo de unidade</p>
                                                </div>
                                                <div className="overlay"></div>
                                            </div>
                                        </div>
                                        {(enterprise.workflow === 11) &&
                                            <div style={{marginTop:'30px', textAlign:'right', display:'flex', justifyContent:'flex-end'}}>
                                            <Button
                                                color="warning"
                                                style={{
                                                    maxWidth:'240px',
                                                    marginTop:'20px',
                                                    display:'flex',
                                                    gap:'5px',
                                                    alignItems:'center',
                                                    fontSize:'18px',
                                                    fontWeight:'800'
                                                }}
                                                onClick={() => {
                                                    if(
                                                        enterprise.register
                                                        && enterprise.description
                                                        && enterprise.units_number
                                                        && enterprise.area
                                                        && enterprise.floors
                                                        && enterprise.cep
                                                        && enterprise.street
                                                        && enterprise.number
                                                        && enterprise.district
                                                        && enterprise.state
                                                        && enterprise.city
                                                        && enterprise.photos?.length > 0
                                                    ){
                                                        this.updateEnterprise()
                                                    }else{
                                                        this.setState({erro_send_aprovation: true})
                                                    }
                                                }}
                                            >Enviar para aprovação<i className="ri-send-plane-fill" style={{fontSize:'20px'}}></i></Button></div>}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <Modal
                    isOpen={this.state.modal_center}
                    toggle={this.tog_center}
                    size="lg"
                    centered={true}
                >
                    <ModalHeader toggle={() => this.setState({ modal_center: false })}>
                    Novo tipo de unidade
                    </ModalHeader>
                    <ModalBody>
                        <Row style={{minWidth:'400px'}}>
                            <Col xs={12}>
                                <Label htmlFor="tituloImovel"  className="col-md-12 col-form-label">Tipo de apartamento</Label>
                                <select value={this.state.title} onChange={(e) => this.setState({title: e.target.value})} className="form-control">
                                    <option value=''>Escolha um tipo</option>
                                    {opcoesNaoExistentes.map((opcao, index) => (
                                        <option value={opcao} key={index}>{opcao}</option>
                                    ))}
                                </select>
                                {localStorage.getItem('access') === 'A' &&
                                    <div style={{marginTop:'15px'}}>
                                        <Input type="text" value={this.state.title} onChange={(e) => this.setState({title: e.target.value})}/>
                                    </div>
                                }
                            </Col>
                        </Row>
                        <Row style={{gap:'0px', justifyContent:'flex-end', marginTop:'20px' }}>
                            <Col className="col-auto">
                                <Button onClick={() => this.setState({ modal_center: false })} className="btn-dark">Cancelar</Button>
                            </Col>
                            <Col className="col-auto">
                                {this.state.title !== '' ?
                                <Button onClick={this.createTypeUnit}>Criar</Button>
                                :
                                <Button disabled>Criar</Button>
                                }
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>

                <Modal
                    isOpen={this.state.modal_logo}
                    toggle={this.tog_logo}
                    size="lg"
                    centered={true}
                >
                    <ModalHeader toggle={() => this.setState({ modal_logo: false })}>
                    Alterar logo do empreendimento
                    </ModalHeader>
                    <ModalBody>
                        <Row style={{minWidth:'400px'}}>
                            <Col xs={12}>
                                <AvForm className="cardBody needs-validation" ref={this.validateRef}>
                                    <div className="input-group" style={{marginTop:'10px'}}>
                                        <input
                                            type="file"
                                            className="form-control"
                                            id="customFile"
                                            onChange={(e) => this.setState({new_logo: e.target.files[0]})}
                                        />
                                    </div>
                                </AvForm>
                            </Col>
                        </Row>
                        <Row style={{gap:'0px', justifyContent:'flex-end', marginTop:'20px' }}>
                            <Col className="col-auto">
                                <Button onClick={() => this.setState({ modal_logo: false })} className="btn-dark">Cancelar</Button>
                            </Col>
                            <Col className="col-auto">
                                <Button onClick={() => {
                                    document.getElementById('load').classList.add('active')

                                    const data = new FormData();
                                    data.append('logo', this.state.new_logo)

                                    Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprises-update/'+ id, data, {
                                        headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
                                        }).then(response => {
                                             setTimeout(() => {
                                                 window.location.reload()
                                             }, 300);
                                        })
                                }}>Alterar</Button>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>

                <Modal
                    isOpen={this.state.modal_workflow}
                    toggle={this.tog_workflow}
                    centered={true}
                    >
                    <ModalHeader toggle={() => this.setState({ modal_workflow: false })}>
                        {enterprise?.workflow === 0 ? <h5>Pendente</h5>
                        : enterprise?.workflow === 1 ? <h5>Rep. Fotos</h5>
                        : enterprise?.workflow === 2 ? <h5>Rep. Aut. Venda</h5>
                        : enterprise?.workflow === 3 ? <h5>Rep. Prop.</h5>
                        : enterprise?.workflow === 4 ? <h5>Rep. Imóvel</h5>
                        : enterprise?.workflow === 11 ? <h5>Fora padrão FS</h5>
                        : enterprise?.workflow === 5 ? <h5>Repetido</h5>
                        : enterprise?.workflow === 6 ? <h5>Vendido (ext)</h5>
                        : enterprise?.workflow === 8 ? <h5>Vendido (fs)</h5>
                        : enterprise?.workflow === 9 ? <h5>Rep. Imóvel</h5>
                        : enterprise?.workflow === 10 ? <h5>Bloqueado</h5>
                        : <h5>Aprovado</h5>}
                    </ModalHeader>
                    <ModalBody>
                        <Col style={{padding:'0', marginBottom:'10px'}}><h5 style={{marginBottom:'10px'}}>Observações:</h5></Col>
                        <p>{enterprise?.workflow_observation === '' || !enterprise?.workflow_observation || enterprise?.workflow_observation === 'null' ? 'Nenhuma observação referente a este imóvel.' : enterprise?.workflow_observation}</p>
                        {localStorage.getItem('access') === 'A' &&
                        <Row>
                        <Col xs={12}>
                            <Label>Status</Label>
                            <select className="form-control" value={this.state.workflow} onChange={(e) => this.setState({workflow: e.target.value})}>
                            <option value={0}>Cadastro aguardando análise</option>
                            <option value={7}>Aprovado</option>
                            <option value={1}>Fotos reprovadas</option>
                            <option value={2}>Autorização de venda inválida</option>
                            <option value={3}>Informações do proprietário inválidas</option>
                            <option value={4}>Informações do imóvel inválidas</option>
                            <option value={5}>Imóvel já existe na plataforma</option>
                            <option value={11}>Imóvel fora do padrão Fast Sale</option>
                            <option value={8}>Imóvel vendido via Fast Sale</option>
                            <option value={6}>Imóvel vendido (externo)</option>
                            <option value={9}>Contrato cancelado</option>
                            <option value={10}>Bloqueado por falta de pagamento</option>
                            </select>
                        </Col>
                        <Col xs={12}>
                            <Label>Observação</Label>
                            <Input type="textarea" value={this.state.workflow_observation} onChange={(e) => this.setState({workflow_observation: e.target.value})} placeholder="Exibido no cadastro no imóvel e também enviado para o captador via e-mail"></Input>
                        </Col>
                        <Row style={{marginTop:'20px', justifyContent:'flex-end', alignItems:'flex-end'}}>
                            <Col className="col-auto">
                            <Button className="btn-light" color="#333" onClick={(e) => {
                                this.setState({ modal_workflow: false })
                            }}>Fechar</Button>
                            </Col>
                            <Col className="col-auto">
                            {localStorage.getItem('level') !== '2' &&
                            <Button className="btn-success" onClick={() => {
                                const data = new FormData();
                                // data.append('construction_id', (localStorage.getItem('access') === 'T' || localStorage.getItem('access') === 'S') ? localStorage.getItem('id') : this.state.selectedConstruction.value)
                                if(localStorage.getItem('access') === 'T'){
                                    data.append('construction_id', localStorage.getItem('id'))
                                }
                                if(localStorage.getItem('access') === 'S'){
                                    data.append('construction_id', localStorage.getItem('construction_id'))
                                }
                                if(localStorage.getItem('access') === 'A'){
                                    data.append('construction_id', this.state.selectedConstruction.value)
                                }
                                data.append('workflow_observation', this.state.workflow_observation)
                                data.append('workflow', this.state.workflow)
                        
                                Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprises-update/'+ id, data, {
                                headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
                                }).then(response => {
                                    setTimeout(() => {
                                        window.location.reload()
                                    }, 100);
                                })
                            }}>Salvar</Button>}
                            </Col>
                        </Row>
                        </Row>
                        }
                    </ModalBody>
                    </Modal>

                <div id="load" className="load">
                    <Spinner className="me-2" color="success" />
                </div>

                {this.state.erro_send_aprovation ? (
                    <SweetAlert
                        title="As informações obrigatórias devem estar preenchidas antes de enviar para aprovação."
                        timeout={3000}
                        showCloseButton={false}
                        showConfirm={false}
                        error
                        onConfirm={() => this.setState({ erro_send_aprovation: false })}
                    ></SweetAlert>
                ) : null}



            {this.state.confirm_both ? (
            <SweetAlert
                title="Atenção!"
                warning
                showCancel
                confirmBtnText="Tenho certeza"
                cancelBtnText="Cancelar"
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() =>{
                Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprises-types-delete/' +  this.state.delete_id,
                {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                .then(response => {
                    this.setState({
                    confirm_both: false,
                    success_dlg: true,
                    dynamic_title: "Deletado",
                    dynamic_description: "Tipo de unidade deletado."
                    })
                })
                }}
                onCancel={() =>
                this.setState({
                    confirm_both: false,
                    error_dlg: true,
                    dynamic_title: "Cancelado",
                    dynamic_description: "Tipo de unidade ainda está cadastrado."
                })
                }
            >
                <p className="alertText">Você tem certeza que deseja deletar este tipo de unidade?</p>
            </SweetAlert>
            ) : null}

            {this.state.success_dlg ? (
                <SweetAlert
                success
                title={this.state.dynamic_title}
                onConfirm={() => {
                    this.setState({ success_dlg: false })
                    window.location.reload()
                }}
                >
                {this.state.dynamic_description}
                </SweetAlert>
            ) : null}

            {this.state.error_dlg ? (
                <SweetAlert
                error
                title={this.state.dynamic_title}
                onConfirm={() => this.setState({ error_dlg: false })}
                >
                {this.state.dynamic_description}
                </SweetAlert>
            ) : null}

            {this.state.success_update ? (
                <SweetAlert
                    success
                    title='Empreendimento enviado para aprovação'
                    onConfirm={() => {
                        setTimeout(() => {
                            window.location.reload()
                        }, 100);
                    }}
                >
                </SweetAlert>
            ) : null}

            {this.state.error_update ? (
                <SweetAlert
                    error
                    title='O empreendimento não pode ser publicado'
                    onConfirm={() => this.setState({ error_update: false })}
                >
                    Por favor, entre em contato com o suporte.
                </SweetAlert>
            ) : null}

            {this.state.error_auth ? (
                <SweetAlert
                    error
                    title='Ops'
                    onConfirm={() => this.setState({ error_auth: false })}
                >
                    Você não tem permissão para esta ação.
                </SweetAlert>
            ) : null}

            </div>
        )
    }

}

export default EnterpriseProfile;