export const Property = () => {
    return (
        <svg 
            id="Layer_1" 
            enableBackground="new 0 0 60 60" 
            height="38" 
            viewBox="0 0 60 60" 
            width="38" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <g>
                <path d="m42.5 45c-4.1 0-7.5-3.4-7.5-7.5s3.4-7.5 7.5-7.5 7.5 3.4 7.5 7.5-3.4 7.5-7.5 7.5zm0-12.5c-2.8 0-5 2.2-5 5s2.2 5 5 5 5-2.2 5-5-2.2-5-5-5z" />
            </g>
            <g>
                <path d="m55 58.7h-2.5v-3.7c0-1.3-.5-2.6-1.5-3.5-.9-.9-2.2-1.5-3.5-1.5h-10c-1.3 0-2.6.5-3.5 1.5-.9.9-1.5 2.2-1.5 3.5v3.8h-2.5v-3.8c0-2 .8-3.9 2.2-5.3s3.3-2.2 5.3-2.2h10c2 0 3.9.8 5.3 2.2s2.2 3.3 2.2 5.3z" />
            </g>
            <g>
                <path d="m6.3 51.2h20v2.5h-20z" />
            </g>
            <g>
                <path d="m12.5 52.5h-2.5v-45h37.5v20h-2.5v-17.5h-32.5z" />
            </g>
            <g>
                <path d="m17.5 16.2h8.8v2.5h-8.8z" />
            </g>
            <g>
                <path d="m31.3 16.2h8.8v2.5h-8.8z" />
            </g>
            <g>
                <path d="m17.5 25h8.8v2.5h-8.8z" />
            </g>
            <g>
                <path d="m17.5 33.7h8.8v2.5h-8.8z" />
            </g>
            <g>
                <path d="m41.3 8.7h-2.6v-4.9h-19.9v4.9h-2.6v-7.5h25.1z" />
            </g>
            <g>
                <path d="m17.5 42.5h8.8v2.5h-8.8z" />
            </g>
            <g>
                <path d="m31.3 25h8.8v2.5h-8.8z" />
            </g>
        </svg>
    );
};
