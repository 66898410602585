import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Container, Label, Row, Spinner, Table } from "reactstrap";
import Axios from "axios";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from "react-select";
import ConsultantModal from "./ConsultantModal";
import Swal from "sweetalert2"; // Importando o SweetAlert2

export default function Consultants() {
  const [consultants, setConsultants] = useState([]);
  const [selectedConstruction, setSelectedConstruction] = useState(null);
  const [selectOptionsConstructions, setSelectOptionsConstructions] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentConsultant, setCurrentConsultant] = useState(null); // Consultor para edição
  const [loading, setLoading] = useState(false);

  const toggleModal = () => setModalOpen(!modalOpen);

  const breadcrumbItems = [
    { title: "Fast Sale", link: "/dashboard" },
    { title: "Consultores", link: "#" },
  ];


  const fetchConsultants = () => {
      setLoading(true);
      Axios.get(
        `${process.env.REACT_APP_API}/api/auth/consultants/list/${localStorage.getItem('access') === 'A' ? selectedConstruction?.value : localStorage.getItem('id')}`,
        { headers: { Authorization: "Bearer " + localStorage.getItem("token") } }
      )
        .then((response) => {
          setConsultants(response.data);
          setLoading(false);
        })
        .catch(() => {
          console.error("Erro ao carregar consultores");
          setLoading(false);
        });
  };

  useEffect(() => {
    if(localStorage.getItem('access') === 'A'){
        setLoading(true);
        Axios.get(process.env.REACT_APP_API + "/api/auth/enterprise/constructions", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
          .then((response) => {
            setSelectOptionsConstructions(
              response.data.map((item) => ({
                value: item.id,
                label: item.name,
              }))
            );
            setLoading(false);
          })
          .catch(() => {
            console.error("Erro ao carregar construtoras");
            setLoading(false);
          });
    }else{
        fetchConsultants();
    }
  }, [])

  useEffect(() => {
    if(localStorage.getItem('access') === 'A'){
        fetchConsultants();
    }else{
        fetchConsultants();
    }
  }, [selectedConstruction]);

  const handleDelete = (id) => {
    Swal.fire({
      title: "Tem certeza?",
      text: "Você não poderá reverter esta ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, deletar!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        Axios.delete(`${process.env.REACT_APP_API}/api/auth/consultants/${id}`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
          .then(() => {
            Swal.fire("Deletado!", "O consultor foi deletado com sucesso.", "success");
            fetchConsultants();
          })
          .catch(() => {
            Swal.fire("Erro!", "Não foi possível deletar o consultor.", "error");
          });
      }
    });
  };

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return "";

    const numericPhoneNumber = phoneNumber.replace(/\D/g, "");
    const areaCode = numericPhoneNumber.slice(0, 2);
    const firstDigits = numericPhoneNumber.slice(2, 7);
    const lastDigits = numericPhoneNumber.slice(7);

    if (lastDigits) {
      return `(${areaCode}) ${firstDigits}-${lastDigits}`;
    } else if (firstDigits) {
      return `(${areaCode}) ${firstDigits}`;
    } else if (areaCode) {
      return `(${areaCode})`;
    }

    return phoneNumber;
  };

  return (
    <div className="page-content" id="Consultants">
      <Container fluid={true}>
        <Breadcrumbs title="Consultores" breadcrumbItems={breadcrumbItems} />
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Container>
                  <Row>
                    <Col>
                      <Button
                        color="primary"
                        onClick={() => {
                          setCurrentConsultant(null);
                          toggleModal();
                        }}
                      >
                        Adicionar Consultor
                      </Button>
                    </Col>
                  </Row>
                  {localStorage.getItem('access') === 'A' &&
                    <Row>
                        <Col md={4}>
                            <Label className="form-label">Construtora</Label>
                            <Select
                                value={selectedConstruction}
                                placeholder="Selecione"
                                noOptionsMessage={() => `Sem resultados`}
                                onChange={(e) => setSelectedConstruction(e)}
                                options={selectOptionsConstructions}
                                classNamePrefix="select2-selection"
                            />
                        </Col>
                    </Row>
                  }
                  <Row style={{ marginTop: "20px" }}>
                    <Col xs={12}>
                      {loading ? (
                        <Spinner color="primary" />
                      ) : (
                        <Table bordered>
                          <thead>
                            <tr>
                              {/* <th>ID</th> */}
                              <th>Nome</th>
                              <th>Telefone</th>
                              <th>Foto</th>
                              <th>Ações</th>
                            </tr>
                          </thead>
                          <tbody>
                            {consultants.length > 0 ? (
                              consultants.map((consultant) => (
                                <tr key={consultant.id}>
                                  {/* <td>{consultant.id}</td> */}
                                  <td>{consultant.name}</td>
                                  <td>{formatPhoneNumber(consultant.phone || '')}</td>
                                  <td>
                                    {consultant.photo ? (
                                      <img
                                        src={consultant.photo}
                                        alt="Foto"
                                        style={{ width: "50px", height: "50px", borderRadius: "50%" }}
                                      />
                                    ) : (
                                      "Sem foto"
                                    )}
                                  </td>
                                  <td>
                                    <Button
                                      color="warning"
                                      size="sm"
                                      onClick={() => {
                                        setCurrentConsultant(consultant);
                                        toggleModal();
                                      }}
                                    >
                                      Editar
                                    </Button>{" "}
                                    <Button
                                      color="danger"
                                      size="sm"
                                      onClick={() => handleDelete(consultant.id)}
                                    >
                                      Deletar
                                    </Button>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="5" className="text-center">
                                  Nenhum consultor encontrado
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      )}
                    </Col>
                  </Row>
                </Container>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <ConsultantModal
        isOpen={modalOpen}
        toggle={toggleModal}
        consultant={currentConsultant}
        constructionId={localStorage.getItem('access') === 'A' ? selectedConstruction?.value : localStorage.getItem('id')}
        onSave={fetchConsultants}
      />
    </div>
  );
}
