import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col } from "reactstrap";
import Axios from "axios";

const ConsultantsModal = ({
  isOpen,
  toggle,
  consultants,
  title,
  constructionInfo,
  enterpriseId,
  constructionId, // Adicionado o constructionId como prop
}) => {
  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return "";

    const numericPhoneNumber = phoneNumber.replace(/\D/g, "");
    const areaCode = numericPhoneNumber.slice(0, 2);
    const firstDigits = numericPhoneNumber.slice(2, 7);
    const lastDigits = numericPhoneNumber.slice(7);

    if (lastDigits) {
      return `(${areaCode}) ${firstDigits}-${lastDigits}`;
    } else if (firstDigits) {
      return `(${areaCode}) ${firstDigits}`;
    } else if (areaCode) {
      return `(${areaCode})`;
    }

    return phoneNumber;
  };

  const handleContactClick = (phone, message, consultantId = null) => {
    // Registrar o acesso somente para consultores
    const contactData = {
      enterprise_id: enterpriseId,
      construction_id: constructionId, // Enviando o construction_id
      consultant_id: consultantId,
    };

    Axios.post(`${process.env.REACT_APP_API}/api/auth/consultant-contacts`, contactData, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then(() => {
        // Abrir WhatsApp após registrar
        window.open(
          `https://api.whatsapp.com/send?phone=55${phone}&text=${encodeURIComponent(message)}`,
          "_blank"
        );
      })
      .catch((error) => {
        console.error("Erro ao registrar contato:", error);
      });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered size="lg">
      <ModalHeader toggle={toggle} className="text-center">
        {constructionInfo?.name || "Informações da Construtora"}
      </ModalHeader>
      <ModalBody>
        {/* Informações da Construtora */}
        <div style={{ textAlign: "center", marginBottom: "20px" }}>
          <img
            src={constructionInfo?.logo || "https://via.placeholder.com/100"}
            alt="Logo Construtora"
            style={{
              width: "80px",
              height: "80px",
              borderRadius: "50%",
              marginBottom: "10px",
            }}
          />
          <p>
            <a
              href={`mailto:${constructionInfo?.email}`}
              style={{ color: "#3E5E3F", textDecoration: "none" }}
            >
              {constructionInfo?.email || "E-mail não disponível"}
            </a>
          </p>
          {constructionInfo?.phone && (
            <p
              onClick={() =>
                window.open(
                  `https://api.whatsapp.com/send?phone=55${constructionInfo?.phone}&text=${encodeURIComponent(
                    `Olá, gostaria de mais informações sobre o empreendimento ${title}.`
                  )}`,
                  "_blank"
                )
              }
              style={{ color: "#3E5E3F", cursor: "pointer", textDecoration: "underline" }}
            >
              {formatPhoneNumber(constructionInfo?.phone)}
            </p>
          )}
          <p>{constructionInfo?.address || "Endereço não disponível"}</p>
        </div>
        <hr />
        {/* Lista de Consultores */}
        <span style={{marginBottom:'20px', display:'block'}}><h5 className="text-center">Consultores</h5></span>
        {consultants.length > 0 ? (
          <Row>
            {consultants.map((consultant, index) => (
              <Col
                key={index}
                xs={12}
                md={6}
                style={{
                  marginBottom: "15px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    border: "1px solid #ddd",
                    borderRadius: "10px",
                    padding: "15px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <img
                    src={consultant.photo || "https://via.placeholder.com/50"}
                    alt={consultant.name}
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                      marginRight: "15px",
                    }}
                  />
                  <div>
                    <p style={{ margin: 0, fontWeight: "bold" }}>{consultant.name}</p>
                    <p style={{ margin: 0, fontSize: "14px" }}>
                      {formatPhoneNumber(consultant.phone)}
                    </p>
                  </div>
                  <Button
                    color="success"
                    size="sm"
                    style={{ marginLeft: "auto" }}
                    onClick={() =>
                      handleContactClick(
                        consultant.phone,
                        `Olá, vim através da Fast Sale, referente ao empreendimento ${title}.`,
                        consultant.id
                      )
                    }
                  >
                    Entrar em contato
                  </Button>
                </div>
              </Col>
            ))}
          </Row>
        ) : (
          <p className="text-center">Nenhum consultor encontrado.</p>
        )}
      </ModalBody>
      <ModalFooter style={{textAlign:'center'}}>
        <Button color="dark" onClick={toggle}>
          Fechar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConsultantsModal;
