import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Container, Label, Row, Spinner } from "reactstrap";
import Axios from 'axios'
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Select from "react-select";
import './../../assets/scss/custom/components/_integration.scss'
import { AvField, AvForm, AvGroup } from 'availity-reactstrap-validation';

export default function Campaigns(){
  const [campaign, setCampaign] = useState(null)
  const [construction, setConstruction] = useState([])
  const [campaignTitle, setCampaignTitle] = useState('')
  const [selectedConstruction, setSelectedConstruction] = useState([])
  const [selectOptionsConstructions, setSelectOptionsConstructions] = useState([])
  const breadcrumbItems = [
    { title: "Fast Sale", link: "/dashboard" },
    { title: "Campanhas", link: "#" },
  ]

  useEffect(() => {
    document.getElementById('load').classList.add('active')
    Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
      type: localStorage.getItem('access'),
      user_id: localStorage.getItem('id'),
      }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
      .then(resp => {
        if(localStorage.getItem('access') === 'A'){
          Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/constructions',
            {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(response => {
              setSelectOptionsConstructions(response.data?.map(item => ({
                value: item.id,
                label: item.name
              })))

              document.getElementById('load').classList.remove('active')
            })
            
        }else{
          Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/constructions/'
            + (localStorage.getItem('access') === 'S' ? localStorage.getItem('construction_id') : localStorage.getItem('id')),
          {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
          .then(response => {
              setConstruction(response.data)
              setCampaignTitle(response.data.campaign_title)
              setCampaign(response.data.campaign)

              document.getElementById('load').classList.remove('active')
          })
        } 
      })
      .catch(response =>{
        localStorage.removeItem("history_url")
        setTimeout(() => {
          window.open("/login", '_parent')
        }, 300);
      })
  }, [])

  const submitCampaign = () => {
    document.getElementById('load').classList.add('active')
    const data = new FormData();
    data.append('campaign_title', campaignTitle);
    if (campaign) {
        data.append('campaign', campaign);
    }

    Axios.post(
        process.env.REACT_APP_API + '/api/auth/enterprise/construction-update/' + (localStorage.getItem('access') === 'A' ? selectedConstruction.value : construction.id),
        data,
        {
            headers: { "Authorization": "Bearer " + localStorage.getItem('token') },
        }
    ).then(response => {
      document.getElementById('load').classList.remove('active')
    })
    .catch(() => {
        document.getElementById('load').classList.remove('active')
        console.error('Erro ao salvar a campanha.');
    });
}

useEffect(() => {
  if(selectedConstruction.value){
    Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/constructions/'
      + selectedConstruction.value,
    {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
    .then(response => {
        setConstruction(response.data)
        setCampaignTitle(response.data.campaign_title || '')
        setCampaign(response.data.campaign)

        document.getElementById('load').classList.remove('active')
    })
  }
}, [selectedConstruction])

  return(
    <div className="page-content" id="Campaigns">
        <Container fluid={true}>
          <Breadcrumbs title="Campanhas" breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Container>
                    <Row>
                      {localStorage.getItem('access') === 'A' &&
                        <Col md={4}>
                          <Label className="form-label">Construtora</Label>
                          <Select
                            value={selectedConstruction}
                            placeholder="Selecione"
                            noOptionsMessage={(inputValue) => `Sem resultados`}
                            onChange={(e) => setSelectedConstruction(e)}
                            options={selectOptionsConstructions}
                            classNamePrefix="select2-selection"
                          />
                        </Col>
                      }
                    </Row>
                    <Row>
                      <Col xs={12}>
                          <AvForm>
                              <AvGroup>
                                  <Label htmlFor="campaignTitle">Título da Campanha</Label>
                                  <AvField
                                      name="campaign_title"
                                      placeholder="Digite o título da campanha"
                                      type="text"
                                      maxLength="100"
                                      className="form-control"
                                      value={campaignTitle}
                                      onChange={(e) => setCampaignTitle(e.target.value)}
                                      validate={{ required: { value: true } }}
                                  />
                              </AvGroup>
                              <AvGroup style={{marginTop:'10px'}}>
                                  <Label htmlFor="campaignImage">Imagem da Campanha</Label>
                                  <input
                                      type="file"
                                      className="form-control"
                                      id="campaignImage"
                                      onChange={(e) => setCampaign(e.target.files[0])}
                                  />
                              </AvGroup>
                              {construction?.campaign && (
                                      <a href={construction.campaign} target="_blank">
                                          <div className="atual-campaign">
                                              <h3>Campanha atual</h3>
                                              <img
                                                  src={construction.campaign}
                                                  alt="Imagem da campanha existente"
                                                  className="atual-campaign-image"
                                              />
                                              <p>Ampliar</p>
                                          </div>
                                      </a>
                                  )}
                          </AvForm>
                      </Col>
                  </Row>
                  <Row style={{ justifyContent: 'flex-end', marginTop: '20px' }}>
                      <Col className="col-auto">
                          <Button onClick={submitCampaign}>Salvar</Button>
                      </Col>
                  </Row>
                  </Container>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <div id="load" className="load">
          <Spinner className="me-2" color="success" />
        </div>
      </div>
  )
}