import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Spinner, Input } from "reactstrap";
import Swal from "sweetalert2";
import Axios from "axios";
import PhoneInput from "../Authentication/Register/components/form/phoneInput";


export default function ConsultantModal({ isOpen, toggle, consultant, onSave, constructionId }) {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [photo, setPhoto] = useState(null);
  const [photoPreview, setPhotoPreview] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (consultant) {
      setName(consultant.name || "");
      setPhone(consultant.phone || "");
      setPhoto(null);
      setPhotoPreview(consultant.photo || null);
    } else {
      setName("");
      setPhone("");
      setPhoto(null);
      setPhotoPreview(null);
    }
  }, [consultant]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setPhoto(file);
    setPhotoPreview(URL.createObjectURL(file));
  };

  const handleSave = () => {
    if (!name || !phone || (!photo && !consultant)) {
      Swal.fire({
        icon: "error",
        title: "Erro",
        text: "Todos os campos são obrigatórios!",
      });
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append("name", name);
    formData.append("phone", phone);
    if (photo) {
      formData.append("photo", photo);
    }
    formData.append("construction_id", constructionId);

    const url = consultant
      ? `${process.env.REACT_APP_API}/api/auth/consultants/${consultant.id}`
      : `${process.env.REACT_APP_API}/api/auth/consultants`;

    const method = consultant ? "put" : "post";

    Axios({
      method,
      url,
      data: formData,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Sucesso",
          text: consultant ? "Consultor atualizado com sucesso!" : "Consultor adicionado com sucesso!",
        });
        onSave();
        toggle();
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "Erro",
          text: "Ocorreu um erro ao salvar o consultor. Por favor, tente novamente.",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {consultant ? "Editar Consultor" : "Adicionar Consultor"}
      </ModalHeader>
      <ModalBody>
        <Form>
        <span style={{ display: "block", marginBottom:'15px' }}>
          <FormGroup>
            <Label for="name">Nome</Label>
            <Input
              id="name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Digite o nome"
              required
            />
          </FormGroup>
          </span>
          <span style={{ display: "block", marginBottom:'15px' }}>
          <FormGroup>
            <Label for="phone">Telefone</Label>
            <PhoneInput
              phoneNumber={phone}
              onPhoneChange={setPhone}
            />
          </FormGroup>
          </span>
          <span style={{ display: "block", marginBottom:'15px' }}>
          <FormGroup>
            <span style={{ display: "flex", alignItems: "center", gap: "20px" }}>
              {photoPreview && (
                <div style={{ marginTop: "10px" }}>
                  <img
                    src={photoPreview}
                    alt="Foto do consultor"
                    style={{ width: "100px", height: "100px", borderRadius: "50%", marginLeft: "10px" }}
                  />
                </div>
              )}
              <span>
                <Label for="photo">Foto</Label>
                <div className="d-flex align-items-center">
                  <Button color="primary" onClick={() => document.getElementById("fileInput").click()}>
                    {photoPreview ? "Alterar Foto" : "Selecionar Foto"}
                  </Button>
                  <Input
                    id="fileInput"
                    type="file"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                </div>
              </span>
            </span>
          </FormGroup>
          </span>
        </Form>
      </ModalBody>
      <ModalFooter>
        <span style={{display:'flex', justifyContent:'flex-end', gap: '15px'}}>
            <Button color="dark" onClick={toggle}>
                Cancelar
            </Button>
            <Button color="primary" onClick={handleSave} disabled={loading}>
                {loading ? <Spinner size="sm" /> : "Salvar"}
            </Button>
        </span>
      </ModalFooter>
    </Modal>
  );
}
