import Axios from 'axios';
import React, { Component } from 'react';
import { Card, CardBody, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, CardImg, Row, CardTitle, CardText, Button, Badge } from "reactstrap";

//Simple bar
import SimpleBar from "simplebar-react";
import './../../assets/scss/custom/components/_horizontalCard.scss'
import HorizontalCard from './HorizontalCard';
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import "swiper/css/navigation";
import { Navigation } from "swiper"
import ImovelCard from '../../components/ImovelCard/ImovelCard';

class LastProperty extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            imoveis:[],
        }
    }


    // useEffect(() => {
    //     if(count === 0 && user?.id){
    //        if(user?.premium === 1){
    //         const data = {filter:'newest', workflow: '7', status: 1}
    //           api.post('public/property-filter-dash', data)
    //           .then(response => {
    //             setImoveis(response.data.data.slice(0,5))
    //           })
    //      }else{
    //          const data = {filter:'newest', workflow: '7', status: 1}
    //          api.post('public/property-filter-exclude-last30-days', data)
    //          .then(response => {
    //            setImoveis(response.data.data.slice(0,5))
    //          })
    //        }
    //       setCount(count => count + 1)
    //     }
    //   }, [user])

    componentDidMount(){
        // if(this.props.premium){
            Axios.post(process.env.REACT_APP_API + '/api/public/property-filter-dash', {filter:'newest', workflow: ['7'], status: 1},
            {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(response => {
                this.setState({imoveis: response.data.data.slice(0,8)})
            })
        // }else{
        //     Axios.post(process.env.REACT_APP_API + '/api/public/property-filter-exclude-last30-days', {filter:'newest', workflow: ['7'], status: 1},
        //     {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        //     .then(response => {
        //         this.setState({imoveis: response.data.data.slice(0,8)})
        //     })
        // }
    }

    render() {
        return (
            <React.Fragment>
                <Col>
                    <Card>
                        <CardBody>
                            <h4 className="card-title mb-4">Novidades</h4>

                            {/* <SimpleBar style={{ maxHeight: "350px", minHeight: "350px", overflow:'hidden auto'}}>
                                {this.state.imoveis?.map((imovel, index) => (
                                    <HorizontalCard key={index} imovel={imovel}/>
                                ))}
                            </SimpleBar> */}
                            <Swiper
                                slidesPerView={"auto"}
                                spaceBetween={0}
                                navigation={true}
                                modules={[Navigation]}
                                className="SwiperImoveis"
                            >
                                {/* {this.state.imoveis?.map((imovel, index) => (
                                    <SwiperSlide key={index}><ImovelCard  imovel={imovel} premium={this.props.verifyPremium ? 1 : 0}  favoritos={[]}/></SwiperSlide>
                                ))} */}

                                {this.state.imoveis.filter(imoveis => imoveis.id === 3956).map((imovel, index) => (
                                    <SwiperSlide key={index}><ImovelCard  imovel={imovel} premium={this.props.verifyPremium ? 1 : 0}  favoritos={[]}/></SwiperSlide>
                                ))}
                                {this.state.imoveis.filter(imoveis => imoveis.id !== 3956).map((imovel, index) => (
                                    <SwiperSlide key={index}><ImovelCard  imovel={imovel} premium={this.props.verifyPremium ? 1 : 0}  favoritos={[]}/></SwiperSlide>
                                ))}
                            </Swiper>
                        </CardBody>
                    </Card>
                </Col>
            </React.Fragment>
        );
    }
}

export default LastProperty;