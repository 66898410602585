import React, { Component } from "react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import './styles.css'

//i18n
import { withNamespaces } from 'react-i18next';

import { connect } from "react-redux";
import {
    changeLayout,
    changeLayoutWidth,
    changeSidebarTheme,
    changeSidebarType,
    changePreloader
} from "../../store/actions";
import { Badge, Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import SweetAlert from "react-bootstrap-sweetalert";
import NoPremium from "../noPremium/noPremium";
import Axios from 'axios';

class SidebarContent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            manutencao:false,
            modal_center: false,
            modal_site:false,
            pendentUser: '',
            pageActive: false,
        };
        this.tog_center = this.tog_center.bind(this);
        this.tog_site = this.tog_site.bind(this);
    }

    tog_center() {
        this.setState(prevState => ({
          modal_center: !prevState.modal_center
        }));
        this.removeBodyCss();
      }

      tog_site() {
        if(!this.state.pageActive){
            this.setState(prevState => ({
                modal_site: !prevState.modal_site
              }));
              this.removeBodyCss();
        }else{
            setTimeout(() => {
                window.open('https://seusite.fastsaleimoveis.com.br', '_blank')
            }, 100);
        }
      }

      removeBodyCss() {
        document.body.classList.add("no_padding");
      }

    componentDidMount() {
        this.initMenu();

        this.setState({pendentUser: localStorage.getItem('status')})

        Axios.post(process.env.REACT_APP_API + '/api/auth/check-user-page-active', {
            type:localStorage.getItem('access'),
            user_id:localStorage.getItem('id')
        }, {
            headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
          }).then(response => {
            if(response.data.message !== 'O usuário não está ativo'){
                this.setState({pageActive: true})
            }
          })

    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {

            if (this.props.type !== prevProps.type) {
                this.initMenu();
            }

        }
    }

    initMenu() {
        new MetisMenu("#side-menu");

        var matchingMenuItem = null;
        var ul = document.getElementById("side-menu");
        var items = ul.querySelectorAll("a");
        for (var i = 0; i < items.length; ++i) {
            if (this.props.location.pathname === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            this.activateParentDropdown(matchingMenuItem);
        }
    }

    activateParentDropdown = item => {
        item.classList.add("active");
        const parent = item.parentElement;

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show");

                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add("mm-active");
                    }
                }
            }
            return false;
        }
        return false;
    };

    render() {
        return (
            <React.Fragment>
                <div id="sidebar-menu">

                    <ul className="metismenu list-unstyled" id="side-menu">
                        <li className="menu-title">{this.props.t('Analytics')}</li>

                        <li>
                            <Link to="/dashboard" className="waves-effect">
                                <i className="ri-dashboard-line"></i>
                                <span className="ms-1">{this.props.t('Dashboard')}</span>
                            </Link>
                        </li>
                        {(this.state.pendentUser === '3' || this.state.pendentUser === '2') ?
                        <li>
                            <div className=" waves-effect" onClick={this.tog_center} 
                            style={{display:'flex', flexDirection:'row', gap:'8px',alignItems:'center', fontWeight:500,fontFamily: 'Inter, sans-serif', color:'#74788d', fontSize: '13.3px', padding: '0.625rem 1.5rem'}}>
                                <i className="ri-file-chart-line" style={{fontSize:'16px'}}></i>
                                <span className="ms-1">{this.props.t('Relatórios')}</span>
                                <i className="ri-lock-2-line"></i>
                            </div>
                        </li>
                        :
                        <li>
                            {(localStorage.getItem('access') !== 'T' || localStorage.getItem('access') === 'S') &&
                            <Link to="/relatorios" className=" waves-effect"  style={{alignItems:'center'}}>
                                <i className="ri-file-chart-line"></i>
                                <span className="ms-1">{this.props.t('Relatórios')}</span>
                            </Link>}
                        </li>
                        }
                        {(localStorage.getItem('access') === 'T' || localStorage.getItem('access') === 'S' || localStorage.getItem('access') === 'A') &&
                            <li>
                                <Link to="/campanhas" className=" waves-effect"  style={{alignItems:'center'}}>
                                    <i className="ri-advertisement-line"></i>
                                    <span className="ms-1">{this.props.t('Campanhas')}</span>
                                </Link>
                            </li>
                        }
                        {(localStorage.getItem('access') === 'T' || localStorage.getItem('access') === 'S' || localStorage.getItem('access') === 'A') &&
                            <li>
                                <Link to="/consultores" className=" waves-effect"  style={{alignItems:'center'}}>
                                    <i className="ri-team-line"></i>
                                    <span className="ms-1">{this.props.t('Consultores comerciais')}</span>
                                </Link>
                            </li>
                        }


                        <li className="menu-title">{this.props.t('Catálogo')}</li>

                        {(localStorage.getItem('access') !== 'P') &&
                        <li>
                            <Link 
                                to="" 
                                className="waves-effect"
                                onClick={() => {
                                    localStorage.removeItem('search-contract-type');
                                    localStorage.removeItem('filter-search');
                                    localStorage.removeItem('filtro-quartos');
                                    localStorage.removeItem('filtro-negocio');
                                    localStorage.removeItem('filtro-categoria');
                                    localStorage.removeItem('max_price_range_filter');
                                    localStorage.removeItem('filtro-bairros')
                                    localStorage.removeItem('min_price_range_filter');
                                    localStorage.removeItem('filtro-garagens');
                                    localStorage.removeItem('filtro-cidades');
                                    localStorage.removeItem('filtro-estados');
                                    localStorage.removeItem('min_area_range_filter');
                                    localStorage.removeItem('max_area_range_filter');
                                    localStorage.setItem('search-contract-type', 'Todos os imóveis');
                                    localStorage.setItem('paginaAtual', '1');
                                    setTimeout(() => {
                                        window.open("./../imoveis","_parent" )
                                    }, 300);
                                }}
                            >
                                {/* <button style={{
                                    backgroundColor:'#c2e2c3',
                                    borderRadius:'50px',
                                    border:'none',
                                    marginleft:'-5px'
                                    }}> */}
                                    <i className={(localStorage.getItem('access') === 'T' || localStorage.getItem('access') === 'S') ? "ri-user-search-line" : " ri-home-4-line"}></i>
                                    <span className="ms-1">{(localStorage.getItem('access') === 'T' || localStorage.getItem('access') === 'S') ? 'Veja como corretor' : 'Imóveis'}</span>
                                {/* </button> */}
                            </Link>
                        </li>
                        }

                        {/* {(localStorage.getItem('access') === 'T' || localStorage.getItem('access') === 'S') &&
                        <li>
                            <Link 
                                to="" 
                                className="waves-effect"
                                onClick={() => {
                                    localStorage.removeItem('search-contract-type');
                                    localStorage.removeItem('filter-search');
                                    localStorage.removeItem('filtro-quartos');
                                    localStorage.removeItem('filtro-negocio');
                                    localStorage.removeItem('filtro-categoria');
                                    localStorage.removeItem('max_price_range_filter');
                                    localStorage.removeItem('filtro-bairros')
                                    localStorage.removeItem('min_price_range_filter');
                                    localStorage.removeItem('filtro-garagens');
                                    localStorage.removeItem('filtro-cidades');
                                    localStorage.removeItem('filtro-estados');
                                    localStorage.removeItem('min_area_range_filter');
                                    localStorage.removeItem('max_area_range_filter');
                                    localStorage.setItem('search-contract-type', 'Todos os imóveis');
                                    localStorage.setItem('paginaAtual', '1');
                                    setTimeout(() => {
                                        window.open("./../seus-empreendimentos","_parent" )
                                    }, 300);
                                }}
                            >
                                <i className="ri-home-4-line"></i>
                                <span className="ms-1">{this.props.t('Seus imóveis')}</span>
                            </Link>
                        </li>
                        } */}
                        
                        {(localStorage.getItem('access') === 'C' || localStorage.getItem('access') === 'I' || localStorage.getItem('access') === 'P') &&
                        <li>
                            <Link 
                                to="" 
                                className="waves-effect"
                                onClick={() => {
                                    localStorage.removeItem('search-contract-type');
                                    localStorage.removeItem('filter-search');
                                    localStorage.removeItem('filtro-quartos');
                                    localStorage.removeItem('filtro-negocio');
                                    localStorage.removeItem('filtro-categoria');
                                    localStorage.removeItem('max_price_range_filter');
                                    localStorage.removeItem('filtro-bairros')
                                    localStorage.removeItem('min_price_range_filter');
                                    localStorage.removeItem('filtro-garagens');
                                    localStorage.removeItem('filtro-cidades');
                                    localStorage.removeItem('filtro-estados');
                                    localStorage.removeItem('min_area_range_filter');
                                    localStorage.removeItem('max_area_range_filter');
                                    localStorage.setItem('search-contract-type', 'Todos os imóveis');
                                    localStorage.setItem('paginaAtual', '1');
                                    setTimeout(() => {
                                        window.open("./../seus-imoveis","_parent" )
                                    }, 300);
                                }}
                            >
                                <i className="ri-home-smile-line"></i>
                                <span className="ms-1">{this.props.t('Seus Imóveis')}</span>
                            </Link>
                        </li>
                        }
                        {localStorage.getItem('access') === 'I' &&
                        <>
                            <li className="menu-title">{this.props.t('Gestão de corretores')}</li>
                            <li>
                            <Link to="/time-de-corretores" className=" waves-effect"  style={{alignItems:'center'}}>
                                <i className="ri-file-chart-line"></i>
                                <span className="ms-1">{this.props.t('Time de corretores')}</span>
                            </Link>
                        </li>
                        </>
                        }
                        {(localStorage.getItem('id') === '1367' || localStorage.getItem('id') === '1198' || localStorage.getItem('id') === '1544') &&
                        <li>
                            <Link 
                                to="" 
                                className="waves-effect"
                                onClick={() => {
                                    localStorage.removeItem('search-contract-type');
                                    localStorage.removeItem('filter-search');
                                    localStorage.removeItem('filtro-quartos');
                                    localStorage.removeItem('filtro-negocio');
                                    localStorage.removeItem('filtro-categoria');
                                    localStorage.removeItem('max_price_range_filter');
                                    localStorage.removeItem('min_price_range_filter');
                                    localStorage.removeItem('filtro-garagens');
                                    localStorage.removeItem('filtro-estados');
                                    localStorage.removeItem('filtro-cidades');
                                    localStorage.removeItem('min_area_range_filter');
                                    localStorage.removeItem('max_area_range_filter');
                                    localStorage.removeItem('filtro-bairros')
                                    localStorage.setItem('search-contract-type', 'Todos os imóveis');
                                    localStorage.setItem('paginaAtual', '1');
                                    setTimeout(() => {
                                        window.open("./../empreendimentos","_parent" )
                                    }, 300);
                                }}
                            >
                                <i className="ri-building-2-line"></i>
                                <span className="ms-1">{this.props.t('Empreendimentos')}</span>
                            </Link>
                        </li>
                        }
                        {localStorage.getItem('access') === 'A' &&
                        <>
                            <li className="menu-title">{this.props.t('Administrativo')}</li>
                            {localStorage.getItem('level') !== '2' &&
                            <li>
                                <Link to="/novo-imovel" className=" waves-effect">
                                    <i className="ri-add-circle-line"></i>
                                    <span className="ms-1">{this.props.t('Adicionar Imóvel')}</span>
                                </Link>
                            </li>
                           }
                            <li>
                                <Link to="/administrar-imoveis" className="waves-effect">
                                    <i className="ri-community-fill"></i>
                                    <span className="ms-1">{this.props.t('Editar imóveis')}</span>
                                </Link>
                            </li>

                            <li>
                                <Link to="/administrar-empreendimentos" className="waves-effect">
                                    <i className="ri-hotel-fill"></i>
                                    <span className="ms-1">{this.props.t('Editar empreendimentos')}</span>
                                </Link>
                            </li>
                            
                            <li>
                                <Link to="/administrar-usuarios" className="waves-effect">
                                    <i className="ri-user-fill"></i>
                                    <span className="ms-1">{this.props.t('Editar usuários')}</span>
                                </Link>
                            </li>

                            <li>
                                <Link to="/agenda" className=" waves-effect">
                                    <i className="ri-calendar-2-line"></i>
                                    <span className="ms-1">{this.props.t('Agenda')}</span>
                                </Link>
                            </li>

                            <li>
                                <Link to="/administrar-propostas" className=" waves-effect">
                                    <i className="ri-briefcase-4-line"></i>
                                    <span className="ms-1">{this.props.t('Propostas')}</span>
                                </Link>
                            </li>

                            <li>
                                <Link to="/administrar-parcerias" className=" waves-effect">
                                    <i className="ri-archive-line"></i>
                                    <span className="ms-1">{this.props.t('Parcerias')}</span>
                                </Link>
                            </li>
                            {localStorage.getItem('level') !== '2' &&
                            <>
                            <li>
                                <Link to="/administrar-outlet" className=" waves-effect">
                                    <i className="ri-fire-line"></i>
                                    <span className="ms-1">{this.props.t('Outlet')}</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/administrar-cupons" className=" waves-effect">
                                    <i className="ri-coupon-3-line"></i>
                                    <span className="ms-1">Cupons de desconto</span>
                                </Link>
                            </li>
                            </>
                            }
                            <li>
                                <Link to="/administrar-exclusoes" className=" waves-effect"  style={{alignItems:'center'}}>
                                    <i className="ri-chat-delete-line"></i>
                                    <span className="ms-1">{this.props.t('Exclusões')}</span>
                                </Link>
                            </li>

                            <li>
                                <Link to="/administrar-pedidos" className=" waves-effect"  style={{alignItems:'center'}}>
                                    <i className="ri-home-wifi-line"></i>
                                    <span className="ms-1">{this.props.t('Pedidos de imóveis')}</span>
                                </Link>
                            </li>

                            <li>
                                <Link to="/origem-usuarios" className=" waves-effect"  style={{alignItems:'center'}}>
                                    <i className="ri-file-user-line"></i>
                                    <span className="ms-1">{this.props.t('Origem de usuários')}</span>
                                </Link>
                            </li>

                            <li>
                                <Link to="/construtoras-indicadas" className=" waves-effect"  style={{alignItems:'center'}}>
                                    <i className="ri-file-chart-line"></i>
                                    <span className="ms-1">{this.props.t('Construtoras indicadas')}</span>
                                </Link>
                            </li>

                            <li>
                                <Link to="/administrar-indicacoes" className=" waves-effect"  style={{alignItems:'center'}}>
                                    <i className="ri-file-chart-line"></i>
                                    <span className="ms-1">{this.props.t('Indicações')}</span>
                                </Link>
                            </li>
                        </>
                        }

                        {(localStorage.getItem('fast_academy') === 'active' && (localStorage.getItem('access') === 'C' || localStorage.getItem('access') === 'I')) &&
                        <>
                            <li className="menu-title">{this.props.t('Bônus')}</li>

                            <li>
                                <div className="waves-effect" style={{padding:'10px 20px', display:'flex', alignItems:'center', color: '#8590a5', gap:'5px', fontWeight:'600'}} onClick={() => {
                                    setTimeout(() => {
                                        window.open("https://youtube.com/playlist?list=PLxxkLfBCHGrAyIOi1po5ixbZ5tZPsi5fD","_blank" )
                                    }, 300);
                                }}>
                                    <i className="ri-star-fill"></i>
                                    <span className="ms-1">{this.props.t('Fast Academy')}</span>
                                </div>
                            </li>
                        </>
                        }

                        
                        {(localStorage.getItem('access') === 'T' || localStorage.getItem('access') === 'S') &&
                            <>
                            <li className="menu-title">{this.props.t('Gestão de Empreendimetos')}</li>
                            {!(localStorage.getItem('construction_role') === 'view') &&
                                <li>
                                    <Link to="/adicionar-empreendimento" className=" waves-effect">
                                        <i className="ri-add-circle-line"></i>
                                        <span className="ms-1">{this.props.t('Adicionar empreendimento')}</span>
                                    </Link>
                                </li>
                            }
                            <li>
                                <Link to="/seus-empreendimentos" className=" waves-effect">
                                    <i className="ri-hotel-fill"></i>
                                    <span className="ms-1">{this.props.t('Seus empreendimentos')}</span>
                                </Link>
                            </li>

                            <li className="menu-title">{this.props.t('Imóveis de terceiros')}</li>

                            {(this.state.pendentUser === '3' || this.state.pendentUser === '2') ?
                            <li>
                                <div className=" waves-effect" onClick={this.tog_center} 
                                style={{display:'flex', flexDirection:'row', gap:'8px',alignItems:'center', fontWeight:500,fontFamily: 'Inter, sans-serif', color:'#74788d', fontSize: '13.3px', padding: '0.625rem 1.5rem'}}>
                                    <i className="ri-add-circle-line" style={{fontSize:'16px'}}></i>
                                    <span className="ms-1">{this.props.t('Adicionar Imóvel')}</span>
                                    <i className="ri-lock-2-line"></i>
                                </div>
                            </li>
                            :
                            !(localStorage.getItem('access') === 'S' && localStorage.getItem('construction_role') === 'view') &&
                                <li>
                                    <Link to="/novo-imovel" className=" waves-effect">
                                        <i className="ri-add-circle-line"></i>
                                        <span className="ms-1">{this.props.t('Adicionar Imóvel de Terceiro')}</span>
                                    </Link>
                                </li>   
                            }

                            {(this.state.pendentUser === '3' || this.state.pendentUser === '2') ?
                            <li>
                                <div className=" waves-effect" onClick={this.tog_center} 
                                style={{display:'flex', flexDirection:'row', gap:'8px',alignItems:'center', fontWeight:500,fontFamily: 'Inter, sans-serif', color:'#74788d', fontSize: '13.3px', padding: '0.625rem 1.5rem'}}>
                                    <i className="ri-home-gear-line" style={{fontSize:'16px'}}></i>
                                    <span className="ms-1">{this.props.t('Editar Imóveis')}</span>
                                    <i className="ri-lock-2-line"></i>
                                </div>
                            </li>
                            :
                            <li>
                                <Link to="/editar-imoveis" className=" waves-effect">
                                    <i className="ri-home-gear-line"></i>
                                    <span className="ms-1">{this.props.t('Editar Imóveis de Terceiros')}</span>
                                </Link>
                            </li>
                            }

                            </>
                        }

                        {(localStorage.getItem('access') === 'C' || localStorage.getItem('access') === 'I') &&
                        <>
                            <li className="menu-title">{this.props.t('Gestão de Imóveis')}</li>

                            {(this.state.pendentUser === '3' || this.state.pendentUser === '2') ?
                            <li>
                                <div className=" waves-effect" onClick={this.tog_center} 
                                style={{display:'flex', flexDirection:'row', gap:'8px',alignItems:'center', fontWeight:500,fontFamily: 'Inter, sans-serif', color:'#74788d', fontSize: '13.3px', padding: '0.625rem 1.5rem'}}>
                                    <i className="ri-add-circle-line" style={{fontSize:'16px'}}></i>
                                    <span className="ms-1">{this.props.t('Adicionar Imóvel')}</span>
                                    <i className="ri-lock-2-line"></i>
                                </div>
                            </li>
                            :
                            <li>
                                <Link to="/novo-imovel" className=" waves-effect">
                                    <i className="ri-add-circle-line"></i>
                                    <span className="ms-1">{this.props.t('Adicionar Imóvel')}</span>
                                </Link>
                            </li>
                            }

                            {(this.state.pendentUser === '3' || this.state.pendentUser === '2') ?
                            <li>
                                <div className=" waves-effect" onClick={this.tog_center} 
                                style={{display:'flex', flexDirection:'row', gap:'8px',alignItems:'center', fontWeight:500,fontFamily: 'Inter, sans-serif', color:'#74788d', fontSize: '13.3px', padding: '0.625rem 1.5rem'}}>
                                    <i className="ri-home-gear-line" style={{fontSize:'16px'}}></i>
                                    <span className="ms-1">{this.props.t('Editar Imóveis')}</span>
                                    <i className="ri-lock-2-line"></i>
                                </div>
                            </li>
                            :
                            <li>
                                <Link to="/editar-imoveis" className=" waves-effect">
                                    <i className="ri-home-gear-line"></i>
                                    <span className="ms-1">{this.props.t('Editar Imóveis')}</span>
                                </Link>
                            </li>
                            }

                            <li>
                                <div className=" waves-effect" onClick={this.tog_site} 
                                    style={{display:'flex', flexDirection:'row', gap:'8px',alignItems:'center', fontWeight:500,fontFamily: 'Inter, sans-serif', color:'#74788d', fontSize: '13.3px', padding: '0.625rem 1.5rem'}}>
                                        <i className="ri-pages-line" style={{fontSize:'16px'}}></i>
                                        <span className="ms-1">{this.props.t('Seu Site FS')}</span>
                                        {/* <i className="ri-lock-2-line"></i> */}
                                </div>
                            </li>

                            {(this.state.pendentUser === '3' || this.state.pendentUser === '2') ?
                            <li>
                                <div className=" waves-effect" onClick={this.tog_center} 
                                style={{display:'flex', flexDirection:'row', gap:'8px',alignItems:'center', fontWeight:500,fontFamily: 'Inter, sans-serif', color:'#74788d', fontSize: '13.3px', padding: '0.625rem 1.5rem'}}>
                                    <i className="ri-home-wifi-line" style={{fontSize:'16px'}}></i>
                                    <span className="ms-1">{this.props.t('Integrações')}</span>
                                    <i className="ri-lock-2-line"></i>
                                </div>
                            </li>
                            :
                            <li>
                                <Link to="/integracoes" className=" waves-effect">
                                    <i className="ri-home-wifi-line"></i>
                                    <span className="ms-1">{this.props.t('Integrações')}</span>
                                </Link>
                            </li>
                            }  

                            {(this.state.pendentUser === '3' || this.state.pendentUser === '2') ?
                            <li>
                                <div className=" waves-effect" onClick={this.tog_center} 
                                style={{display:'flex', flexDirection:'row', gap:'8px',alignItems:'center', fontWeight:500,fontFamily: 'Inter, sans-serif', color:'#74788d', fontSize: '13.3px', padding: '0.625rem 1.5rem'}}>
                                    <i className="ri-home-heart-line" style={{fontSize:'16px'}}></i>
                                    <span className="ms-1">{this.props.t('Favoritos')}</span>
                                    <i className="ri-lock-2-line"></i>
                                </div>
                            </li>
                            :
                            <li>
                                <Link to="/favoritos" className=" waves-effect">
                                    <i className="ri-home-heart-line"></i>
                                    <span className="ms-1">{this.props.t('Favoritos')}</span>
                                </Link>
                            </li>
                            }  

                            <li className="menu-title">{this.props.t('Ferramentas')}</li>

                            {(this.state.pendentUser === '3' || this.state.pendentUser === '2') ?
                            <li>
                                <div className=" waves-effect" onClick={this.tog_center} 
                                style={{display:'flex', flexDirection:'row', gap:'8px',alignItems:'center', fontWeight:500,fontFamily: 'Inter, sans-serif', color:'#74788d', fontSize: '13.3px', padding: '0.625rem 1.5rem'}}>
                                    <i className="ri-calendar-2-line" style={{fontSize:'16px'}}></i>
                                    <span className="ms-1">{this.props.t('Agenda')}</span>
                                    <i className="ri-lock-2-line"></i>
                                </div>
                            </li>
                            :
                            <li>
                                <Link to="/agenda" className=" waves-effect">
                                    <i className="ri-calendar-2-line"></i>
                                    <span className="ms-1">{this.props.t('Agenda')}</span>
                                </Link>
                            </li>
                            } 

                            <li className="menu-title">{this.props.t('Contratos')}</li>
                            
                            {(this.state.pendentUser === '3' || this.state.pendentUser === '2') ?
                            <li>
                                <div className=" waves-effect" onClick={this.tog_center} 
                                style={{display:'flex', flexDirection:'row', gap:'8px',alignItems:'center', fontWeight:500,fontFamily: 'Inter, sans-serif', color:'#74788d', fontSize: '13.3px', padding: '0.625rem 1.5rem'}}>
                                    <i className="ri-briefcase-4-line" style={{fontSize:'16px'}}></i>
                                    <span className="ms-1">{this.props.t('Propostas')}</span>
                                    <i className="ri-lock-2-line"></i>
                                </div>
                            </li>
                            :
                            <li>
                                <Link to="/propostas" className=" waves-effect">
                                    <i className="ri-briefcase-4-line"></i>
                                    <span className="ms-1">{this.props.t('Propostas')}</span>
                                </Link>
                            </li>
                            } 

                            {(this.state.pendentUser === '3' || this.state.pendentUser === '2') ?
                            <li>
                                <div className=" waves-effect" onClick={this.tog_center} 
                                style={{display:'flex', flexDirection:'row', gap:'8px',alignItems:'center', fontWeight:500,fontFamily: 'Inter, sans-serif', color:'#74788d', fontSize: '13.3px', padding: '0.625rem 1.5rem'}}>
                                    <i className="ri-archive-line" style={{fontSize:'16px'}}></i>
                                    <span className="ms-1">{this.props.t('Parcerias')}</span>
                                    <i className="ri-lock-2-line"></i>
                                </div>
                            </li>
                            :
                            <li>
                                <Link to="/parcerias" className=" waves-effect">
                                    <i className="ri-archive-line"></i>
                                    <span className="ms-1">{this.props.t('Parcerias')}</span>
                                </Link>
                            </li>
                            } 
                        </>
                        }
                        {localStorage.getItem('access') === 'T' &&
                            <>
                                <li className="menu-title">{this.props.t('Usuários')}</li>

                                <li>
                                    <Link to="/usuarios-construtoras" className=" waves-effect">
                                        <i className=" ri-group-line"></i>
                                        <span className="ms-1">{this.props.t('Editar usuários')}</span>
                                    </Link>
                                </li>
                            </>
                        }
{/* 
                        <li>
                            <Link to="/chat" className=" waves-effect">
                                <i className="ri-chat-1-line"></i>
                                <span className="ms-1">{this.props.t('Chat')}</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="/#" className="has-arrow waves-effect">
                                <i className="ri-store-2-line"></i>
                                <span className="ms-1">{this.props.t('Ecommerce')}</span>
                            </Link>
                            <ul className="sub-menu">
                                <li><Link to="/ecommerce-products">{this.props.t('Products')}</Link></li>
                                <li><Link to="/ecommerce-product-detail/1">{this.props.t('Product Detail')}</Link></li>
                                <li><Link to="/ecommerce-orders">{this.props.t('Orders')}</Link></li>
                                <li><Link to="/ecommerce-customers">{this.props.t('Customers')}</Link></li>
                                <li><Link to="/ecommerce-cart">{this.props.t('Cart')}</Link></li>
                                <li><Link to="/ecommerce-checkout">{this.props.t('Checkout')}</Link></li>
                                <li><Link to="/ecommerce-shops">{this.props.t('Shops')}</Link></li>
                                <li><Link to="/ecommerce-add-product">{this.props.t('Add Product')}</Link></li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/#" className="has-arrow waves-effect">
                                <i className="ri-mail-send-line"></i>
                                <span className="ms-1">{this.props.t('Email')}</span>
                            </Link>
                            <ul className="sub-menu">
                                <li><Link to="/email-inbox">{this.props.t('Inbox')}</Link></li>
                                <li><Link to="/email-read">{this.props.t('Read Email')}</Link></li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/kanban-board" className=" waves-effect">
                                <i className="ri-artboard-2-line"></i>
                                <span className="ms-1">{this.props.t('Kanban Board')}</span>
                            </Link>
                        </li>

                        <li className="menu-title">{this.props.t('Pages')}</li>

                        <li>
                            <Link to="/#" className="has-arrow waves-effect">
                                <i className="ri-account-circle-line"></i>
                                <span className="ms-1">{this.props.t('Authentication')}</span>
                            </Link>
                            <ul className="sub-menu">
                                <li><Link to="/login">{this.props.t('Login')}</Link></li>
                                <li><Link to="/register">{this.props.t('Register')}</Link></li>
                                <li><Link to="/forgot-password">{this.props.t('Recover Password')}</Link></li>
                                <li><Link to="/lock-screen">{this.props.t('Lock Screen')}</Link></li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/#" className="has-arrow waves-effect">
                                <i className="ri-profile-line"></i>
                                <span className="ms-1">{this.props.t('Utility')}</span>
                            </Link>
                            <ul className="sub-menu">
                                <li><Link to="/starter">{this.props.t('Starter Page')}</Link></li>
                                <li><Link to="/maintenance">{this.props.t('Maintenance')}</Link></li>
                                <li><Link to="/comingsoon">{this.props.t('Coming Soon')}</Link></li>
                                <li><Link to="/timeline">{this.props.t('Timeline')}</Link></li>
                                <li><Link to="/faqs">{this.props.t('FAQs')}</Link></li>
                                <li><Link to="/pricing">{this.props.t('Pricing')}</Link></li>
                                <li><Link to="/404">{this.props.t('Error 404')}</Link></li>
                                <li><Link to="/500">{this.props.t('Error 500')}</Link></li>
                            </ul>
                        </li>

                        <li className="menu-title">{this.props.t('Components')}</li>

                        <li>
                            <Link to="/#" className="has-arrow waves-effect">
                                <i className="ri-pencil-ruler-2-line"></i>
                                <span className="ms-1">{this.props.t('UI Elements')}</span>
                            </Link>
                            <ul className="sub-menu">
                                <li><Link to="/ui-alerts">{this.props.t('Alerts')}</Link></li>
                                <li><Link to="/ui-buttons">{this.props.t('Buttons')}</Link></li>
                                <li><Link to="/ui-cards">{this.props.t('Cards')}</Link></li>
                                <li><Link to="/ui-carousel">{this.props.t('Carousel')}</Link></li>
                                <li><Link to="/ui-dropdowns">{this.props.t('Dropdowns')}</Link></li>
                                <li><Link to="/ui-grid">{this.props.t('Grid')}</Link></li>
                                <li><Link to="/ui-images">{this.props.t('Images')}</Link></li>
                                <li><Link to="/ui-lightbox">{this.props.t('Lightbox')}</Link></li>
                                <li><Link to="/ui-modals">{this.props.t('Modals')}</Link></li>
                                <li><Link to="/ui-rangeslider">{this.props.t('Range Slider')}</Link></li>
                                <li><Link to="/ui-roundslider">{this.props.t('Round Slider')}</Link></li>
                                <li><Link to="/ui-session-timeout">{this.props.t('Session Timeout')}</Link></li>
                                <li><Link to="/ui-progressbars">{this.props.t('Progress Bars')}</Link></li>
                                <li><Link to="/ui-sweet-alert">{this.props.t('Sweet Alerts')}</Link></li>
                                <li><Link to="/ui-tabs-accordions">{this.props.t('Tabs & Accordions')}</Link></li>
                                <li><Link to="/ui-typography">{this.props.t('Typography')}</Link></li>
                                <li><Link to="/ui-video">{this.props.t('Video')}</Link></li>
                                <li><Link to="/ui-general">{this.props.t('General')}</Link></li>
                                <li><Link to="/ui-rating">{this.props.t('Rating')}</Link></li>
                                <li><Link to="/ui-notifications">{this.props.t('Notifications')}</Link></li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/#" className="waves-effect">
                                <i className="ri-eraser-fill"></i>
                                <span className="badge rounded-pill bg-danger float-end">6</span>
                                <span className="ms-1">{this.props.t('Forms')}</span>
                            </Link>
                            <ul className="sub-menu">
                                <li><Link to="/form-elements">{this.props.t('Form Elements')}</Link></li>
                                <li><Link to="/form-validation">{this.props.t('Form Validation')}</Link></li>
                                <li><Link to="/form-advanced">{this.props.t('Form Advanced Plugins')}</Link></li>
                                <li><Link to="/form-editors">{this.props.t('Form Editors')}</Link></li>
                                <li><Link to="/form-file-upload">{this.props.t('Form File Upload')}</Link></li>
                                <li><Link to="/form-xeditable">{this.props.t('Form X-editable')}</Link></li>
                                <li><Link to="/form-wizard">{this.props.t('Form Wizard')}</Link></li>
                                <li><Link to="/form-mask">{this.props.t('Form Mask')}</Link></li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/#" className="has-arrow waves-effect">
                                <i className="ri-table-2"></i>
                                <span className="ms-1">{this.props.t('Tables')}</span>
                            </Link>
                            <ul className="sub-menu">
                                <li><Link to="/basic-tables">{this.props.t('Basic Tables')}</Link></li>
                                <li><Link to="/datatable-table">{this.props.t('Data Tables')}</Link></li>
                                <li><Link to="/responsive-table">{this.props.t('Responsive Table')}</Link></li>
                                <li><Link to="/editable-table">{this.props.t('Editable Table')}</Link></li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/#" className="has-arrow waves-effect">
                                <i className="ri-bar-chart-line"></i>
                                <span className="ms-1">{this.props.t('Charts')}</span>
                            </Link>
                            <ul className="sub-menu">
                                <li><Link to="/apex-charts">{this.props.t("Apex Charts")}</Link></li>
                                <li><Link to="/chartjs">{this.props.t('Chartjs')}</Link></li>
                                <li><Link to="/charts-knob">{this.props.t('Jquery Knob')}</Link></li>
                                <li><Link to="/charts-sparkline">{this.props.t('Sparkline')}</Link></li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/#" className="has-arrow waves-effect">
                                <i className="ri-brush-line"></i>
                                <span className="ms-1">{this.props.t('Icons')}</span>
                            </Link>
                            <ul className="sub-menu">
                                <li><Link to="/icons-remix">{this.props.t('Remix Icons')}</Link></li>
                                <li><Link to="/material-design">{this.props.t('Material Design')}</Link></li>
                                <li><Link to="/dripicons">{this.props.t('Dripicons')}</Link></li>
                                <li><Link to="/font-awesome-5">{this.props.t('Font awesome 5')}</Link></li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/#" className="has-arrow waves-effect">
                                <i className="ri-map-pin-line"></i>
                                <span className="ms-1">{this.props.t('Maps')}</span>
                            </Link>
                            <ul className="sub-menu">
                                <li><Link to="/google-maps">{this.props.t('Google Maps')}</Link></li>
                                <li><Link to="/vector-maps">{this.props.t('Vector Maps')}</Link></li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/#" className="has-arrow waves-effect">
                                <i className="ri-share-line"></i>
                                <span className="ms-1">{this.props.t('Multi Level')}</span>
                            </Link>
                            <ul className="sub-menu">
                                <li><Link to="/#">{this.props.t('Level 1.1')}</Link></li>
                                <li><Link to="/#" className="has-arrow">{this.props.t('Level 1.2')}</Link>
                                    <ul className="sub-menu">
                                        <li><Link to="/#">{this.props.t('Level 2.1')}</Link></li>
                                        <li><Link to="/#">{this.props.t('Level 2.2')}</Link></li>
                                    </ul>
                                </li>
                            </ul>
                        </li> */}

                    </ul>
                    {this.state.manutencao ? (
                        <SweetAlert
                            title="Esta funcionalidade está em manutenção!"
                            warning
                            onConfirm={() => this.setState({ manutencao: false })}
                        >
                            Em breve estará disponível.
                        </SweetAlert>
                    ) : null}
                </div>
                <Modal
                    isOpen={this.state.modal_center}
                    toggle={this.tog_center}
                    centered={true}
                >
                    <ModalHeader toggle={() => this.setState({ modal_center: false })}
                    style={{backgroundColor:"#ed4c4b", minHeight:'120px'}}>
                    <h3 style={{color:"#fff"}}>Atenção!</h3>
                    </ModalHeader>
                    <ModalBody style={{textAlign:'center'}}>
                    {this.state.pendentUser === '3' &&
                        <>
                            <p>Valide seu cadastro para liberar as funcionalidades do painel de controle Fast Sale.</p>
                            <br></br>
                            <p style={{fontSize:'18px', fontWeight:'600'}}>Restam {localStorage.getItem('limit_date') ? localStorage.getItem('limit_date') : 'XX'} dias.</p>
                            <br></br>
                            <Button onClick={() => {
                                window.open('./editar-perfil', '_parent')
                            }}>Validar agora</Button>
                        </>
                    }
                    {this.state.pendentUser === '2' &&
                        <>
                            <p>Seus documentos estão aguardando análise, em até 24 horas você será notificado por e-mail.</p>
                        </>
                    }
                    </ModalBody>
                </Modal>



                <Modal
                    isOpen={this.state.modal_site}
                    toggle={this.tog_site}
                    centered={true}
                    id={'noPremiumSite'}
                >
                    <NoPremium/>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    return { ...state.Layout };
};

export default withRouter(connect(mapStatetoProps, {
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    changeLayoutWidth,
    changePreloader
})(withNamespaces()(SidebarContent)));
