import React, { Component, useRef } from 'react';
import { Col, Card, CardBody, Row, Container, Spinner, Label, Badge, Button, Modal, ModalHeader, ModalBody, Input, ModalFooter, CardTitle, CardText, FormGroup, InputGroup } from "reactstrap";
import Axios from 'axios'
import PasswordChecklist from "react-password-checklist";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import { saveAs } from 'file-saver';
import { AvField } from "availity-reactstrap-validation";

import Breadcrumbs from "../../components/Common/Breadcrumb"

import './../../assets/scss/custom/components/_editUser.scss'
import Editable from 'react-bootstrap-editable';

import moment from 'moment';
import SweetAlert from 'react-bootstrap-sweetalert';

import userDefault from './../../assets/images/users/default-user-image.png';
import ImovelCard from '../../components/ImovelCard/ImovelCard';
import { EditUserModal } from '../ConstructionUsers/components/editUserModal';
import _ from 'lodash';

require('moment/locale/pt.js');

let params = new URLSearchParams(window.location.search)
const access = params.get('type')
const userId = params.get('id')

class EditUser extends Component {
  constructor(props) {
    super(props);
    this.selectRef = React.createRef();
    this.state = {
      user:[],
      rgImage:'',
      cnpjImage:'',
      creciImage:'',
      rgImageVerso:'',
      creciImageVerso:'',
      userPhoto:'',
      userPhotoUrl:'',
      rgImageUrl:'',
      creciImageUrl:'',
      rgImageVersoUrl:'',
      modal_edit_sub_user:false,
      creciImageVersoUrl:'',
      cnpjImageUrl:'',
      creci: '',
      cpf: '',
      rg: '',
      creci_j: '',
      corporateName: null,
      cnpj: '',
      owner_cpf: '',
      owner_rg: '',
      fastAcademy:'',
      capta:null,
      saldoPremium:null,
      observacao:'',
      status:null,
      exempleText:'',
      modal_center: false,
      modal_center2: false,
      modal_config: false,
      modal_admin: false,
      userPhone:null,
      cep:null,
      street:null,
      number:null,
      complement:null,
      city:null,
      country:null,
      activeSite:0,
      state:null,
      name:null,
      about:null,
      update_success:false,
      update_error:false,
      proprietarioEmail:'',
      proprietarioNome:'',
      proprietarioEstadoCivil:'',
      proprietarioProfissao:'',
      proprietarioRg:'',
      proprietarioCpf:'',
      proprietarioEndereco:'',
      proprietarioImovel:'',
      modal_fullscreen:false,
      pass_success:false,
      pass_error:false,
      email: '',
      phone:'',
      senha:'',
      site:'',
      instagram:'',
      senhaAtual:'',
      senhaVerificada:false,
      senhaIncorreta:false,
      confirmacaoSenha:'',
      proprietarioImovelMatricula:'',
      proprietarioImovelValor:'',
      proprietarioImovelComissao:'',
      truePassword:false,
      premiumActive:null,
      auth_success:false,
      auth_error:false,
      new_sub_user:false,
      logradouro:'',
      time:'',
      statesAddress: [],
      bairro:'',
      stateSelected: '',
      citySelected:'',
      brokerTeam:[],
      constructionParent:'',
      cityAddress: [],
      newInvite:false,
      validateDocs:false,
      tog_update_docs:false,
      confirm_both: false,
      delete_id: null,
      custom_div1: false,
      alert_confirm:false,
      success_dlg: false,
      feedback:'',
      feedbackId:'',
      realEstateTeam:[],
      teamActive:false,
      constructionUsers:[],
      userProfileBg:null,
      ultimoAcesso:'',
      brokerEmail:'',
      profile_bg:null,
      launch:'',
      breadcrumbItems: [
        { title: "Fast Sale", link: "/dashboard" },
        { title: "Editar perfil", link: "#" },
    ],
    }
    this.tog_center = this.tog_center.bind(this);
    this.tog_center2 = this.tog_center2.bind(this);
    this.handleContractDownload = this.handleContractDownload.bind(this);
  }

  handlePasswordChange = _.debounce((isValid) => {
  this.setState({ truePassword: isValid });
}, 300);

  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  tog_center() {
    this.setState(prevState => ({
      modal_center: !prevState.modal_center
    }));
    this.removeBodyCss();
  }

  tog_center2() {
    this.setState(prevState => ({
      modal_center2: !prevState.modal_center2
    }));
    this.removeBodyCss();
  }

  tog_update_docs() {
    this.setState(prevState => ({
      tog_update_docs: !prevState.tog_update_docs
    }));
    this.removeBodyCss();
  }

  tog_fullscreen() {
    this.setState(prevState => ({
      modal_fullscreen: !prevState.modal_fullscreen
    }));
    this.removeBodyCss();
  }

  tog_admin() {
    this.setState(prevState => ({
      modal_admin: !prevState.modal_admin
    }));
    this.removeBodyCss();
  }

  tog_config() {
    this.setState(prevState => ({
      modal_config: !prevState.modal_config
    }));
    this.removeBodyCss();
  }

  handleSelectState = (value) => {
    if(value !== this.state.state){
      this.setState({city: null})
    }
    this.setState({ state: value });
    Axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados/' + value + '/municipios')
    .then(response => {
      const city = []
      for(var i = 0; response.data.length > i; i++){
        city.push({
          label: response.data[i].nome,
          value: response.data[i].nome
        })
      }
      this.setState({cityAddress: city});
    })
  };

  consultaCep = cepValue => {
    const cep = cepValue
    if (cep?.length !== 8) {
      this.setState({street: ''});
      this.setState({bairro: ''});
    } else {
      fetch('https://viacep.com.br/ws/' + cep + '/json/')
      .then((res) => res.json())
      .then((data) => {
        if (data.erro === true) {
          this.setState({street: ''});
          this.setState({bairro: ''});
        } else {
          this.setState({street: data.logradouro});
          this.setState({district: data.bairro});  
          }
      })
    }  
  };
  

  componentDidMount(){
    document.getElementById('load').classList.add('active')

    if(localStorage.getItem('access') === 'A'){
      Axios.post(process.env.REACT_APP_API + '/api/auth/feedback-by-user', {
        user_type: access,
        user_id: userId,
        }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {
          if(response.data.length > 0){
            this.setState({feedback: response?.data[0]?.response})
            this.setState({feedbackId: response?.data[0]?.id})
          }

        })
    }

    Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
      type: localStorage.getItem('access'),
      user_id: localStorage.getItem('id'),
      }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
      .then(response => {
        // if(localStorage.getItem('access') === 'A'){
          if(localStorage.getItem('access') === 'T' || access === 'T'){
            this.setState({exempleText : 'Somos uma construtora com mais de 15 anos de experiência no mercado, dedicada a desenvolver projetos inovadores e de alta qualidade. Nosso compromisso é construir empreendimentos que atendam às expectativas dos nossos clientes, oferecendo segurança, conforto e modernidade.\r\n\r\nNossa equipe é formada por engenheiros, arquitetos e profissionais qualificados que trabalham com dedicação e competência em cada etapa do processo construtivo, desde a concepção do projeto até a entrega das chaves. Valorizamos a sustentabilidade e a eficiência energética, implementando práticas que respeitam o meio ambiente em todas as nossas obras.\r\n\r\nOferecemos uma ampla gama de empreendimentos, incluindo apartamentos residenciais, condomínios fechados, complexos comerciais e obras corporativas. Estamos sempre atentos às novas tendências do mercado imobiliário e utilizamos tecnologias de ponta para garantir a qualidade e a durabilidade dos nossos projetos.'})
          }else{
            this.setState({exempleText : "Este é apenas um exemplo de uma descrição de um usuário que representa uma corretora ou imobiliária.\r\n\r\nSomos uma corretora de imóveis estabelecida há mais de 10 anos no mercado imobiliário. Oferecemos serviços de compra, venda e aluguel de imóveis residenciais e comerciais, além de assessoria jurídica e financeira aos nossos clientes.\r\n\r\nNossa equipe é composta por profissionais experientes e comprometidos em oferecer um atendimento personalizado e eficiente. Trabalhamos com transparência e ética, buscando sempre a satisfação dos nossos clientes.\r\n\r\nNosso portfólio de imóveis conta com uma grande variedade de opções, desde apartamentos compactos até casas de luxo, passando por salas comerciais e terrenos para construção. Estamos sempre atentos às novas tendências do mercado imobiliário e buscamos oferecer as melhores oportunidades de negócio aos nossos clientes.\r\n\r\nSe você está em busca de um imóvel para comprar, vender ou alugar, não hesite em nos contatar. Estamos à disposição para atender suas necessidades e ajudá-lo a encontrar a melhor opção para o seu perfil e orçamento."})
          }

          if(localStorage.getItem('access') === 'C' || access === 'C'){
            Axios.post(process.env.REACT_APP_API + '/api/auth/user-domain', {
              user_type: 'C',
              user_id: localStorage.getItem('access') === 'A' ? userId : localStorage.getItem('id'),
              }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
              .then(response => this.setState({site: response.data.data.domain}))
          }

          if(localStorage.getItem('access') === 'I' || access === 'I'){
            Axios.post(process.env.REACT_APP_API + '/api/auth/user-domain', {
              user_type: 'I',
              user_id: localStorage.getItem('access') === 'A' ? userId : localStorage.getItem('id'),
              }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
              .then(response => this.setState({site: response.data.data.domain}))
          }

          if(localStorage.getItem('access') === 'T' || access === 'T'){
            if(access === 'T'){
              Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/constructions/' + userId,
              {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
              .then(resp => {
                this.setState({user: resp.data})

                if(resp.data.sub_users.length > 0){
                  this.setState({constructionUsers: resp.data.sub_users})
                }
  
                this.setState({status: resp.data.status})
                this.setState({launch: resp.data.launch})
                this.setState({name: resp.data.name})
                this.setState({email: resp.data.email})
                this.setState({owner_cpf: resp.data.owner_cpf && resp.data.owner_cpf !== 'null' ? resp.data.owner_cpf : ''})
                this.setState({owner_rg: resp.data.owner_rg && resp.data.owner_rg !== 'null' ? resp.data.owner_rg : ''})
                this.setState({owner_name: resp.data.owner_name && resp.data.owner_name !== 'null' ? resp.data.owner_name : ''})
                this.setState({corporateName: resp.data.social_name && resp.data.social_name !== 'null' ? resp.data.social_name : ''})
                this.setState({cnpj: resp.data.cnpj})
                // this.setState({about: resp.data.about})
                this.setState({country: resp.data.country})
                this.setState({cep: resp.data.cep})
                this.setState({city: resp.data.city})
                this.setState({state: resp.data.state})
                this.setState({about: resp.data.about})
                this.setState({street: resp.data.street})
                this.setState({site: resp.data.site})
                this.setState({instagram: resp.data.instagram})
                this.setState({number: resp.data.number})
                this.setState({district: resp.data.district})
                this.setState({complement: resp.data.complement})
                this.setState({phone: resp.data.phone})
                // this.setState({fastAcademy: resp.data.fast_academy})
                this.setState({observacao: resp.data.workflow_observation})
                // this.setState({capta: resp.data.p_count})
                // this.setState({premiumActive: resp.data.premium})
                // this.setState({saldoPremium: resp.data.premium_dates?.date_end})
                this.setState({userPhotoUrl: resp.data?.profile_photo})
                this.setState({userProfileBg: resp.data?.profile_bg})
                this.setState({rgImageUrl: resp.data.photo_owner})
                // this.setState({creciImageUrl: resp.data.photo_crecij})
                // this.setState({rgImageVersoUrl: resp.data.photo_owner_verso})
                // this.setState({creciImageVersoUrl: resp.data.photo_crecij_verso})
                this.setState({cnpjImageUrl: resp.data.photo_cnpj})

                setTimeout(() => {
                  this.handleSelectState(resp.data.state)
                }, 300);
                document.getElementById('load').classList.remove('active')
              })
            }else{
              Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/constructions/' + localStorage.getItem('id'),
              {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
              .then(resp => {
                this.setState({user: resp.data})

                if(resp.data.sub_users.length > 0){
                  this.setState({constructionUsers: resp.data.sub_users})
                }
  
                this.setState({status: resp.data.status})
                this.setState({name: resp.data.name})
                this.setState({email: resp.data.email})
                this.setState({owner_cpf: resp.data.owner_cpf && resp.data.owner_cpf !== 'null' ? resp.data.owner_cpf : ''})
                this.setState({owner_rg: resp.data.owner_rg && resp.data.owner_rg !== 'null' ? resp.data.owner_rg : ''})
                this.setState({owner_name: resp.data.owner_name && resp.data.owner_name !== 'null' ? resp.data.owner_name : ''})
                this.setState({corporateName: resp.data.social_name && resp.data.social_name !== 'null' ? resp.data.social_name : ''})
                this.setState({cnpj: resp.data.cnpj})
                // this.setState({about: resp.data.about})
                this.setState({country: resp.data.country})
                this.setState({cep: resp.data.cep})
                this.setState({city: resp.data.city})
                this.setState({state: resp.data.state})
                this.setState({about: resp.data.about})
                this.setState({street: resp.data.street})
                this.setState({site: resp.data.site})
                this.setState({instagram: resp.data.instagram})
                this.setState({number: resp.data.number})
                this.setState({district: resp.data.district})
                this.setState({complement: resp.data.complement})
                this.setState({phone: resp.data.phone})
                // this.setState({fastAcademy: resp.data.fast_academy})
                this.setState({observacao: resp.data.workflow_observation})
                // this.setState({capta: resp.data.p_count})
                // this.setState({premiumActive: resp.data.premium})
                // this.setState({saldoPremium: resp.data.premium_dates?.date_end})
                this.setState({userPhotoUrl: resp.data?.profile_photo})
                this.setState({rgImageUrl: resp.data.photo_owner})
                this.setState({userProfileBg: resp.data?.profile_bg})
                this.setState({launch: resp.data?.launch})
                // this.setState({creciImageUrl: resp.data.photo_crecij})
                // this.setState({rgImageVersoUrl: resp.data.photo_owner_verso})
                // this.setState({creciImageVersoUrl: resp.data.photo_crecij_verso})
                this.setState({cnpjImageUrl: resp.data.photo_cnpj})

                setTimeout(() => {
                  this.handleSelectState(resp.data.state)
                }, 300);
                document.getElementById('load').classList.remove('active')
              })
            }
          }
          if(localStorage.getItem('access') === 'S' || access === 'S'){
            if(access === 'S'){
              Axios.get(process.env.REACT_APP_API + '/api/auth/subusers/' + userId,
                {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                .then(resp => {
                  this.setState({user: resp.data})
                  this.setState({name: resp.data.name})
                  this.setState({email: resp.data.email})
                  this.setState({constructionParent: resp.data.construction?.name})

                  document.getElementById('load').classList.remove('active')
                })
            }else{
              Axios.get(process.env.REACT_APP_API + '/api/auth/subusers/' + localStorage.getItem('id'),
                {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                .then(resp => {
                  this.setState({user: resp.data})
                  this.setState({name: resp.data.name})
                  this.setState({email: resp.data.email})
                  this.setState({constructionParent: resp.data.construction?.name})

                  document.getElementById('load').classList.remove('active')
                })
            }
          }
          if(localStorage.getItem('access') === 'C' || access === 'C'){
            if(access === 'C'){
              Axios.get(process.env.REACT_APP_API + '/api/auth/brokers/' + userId,
              {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
              .then(resp => {
                this.setState({user: resp.data})
  
                this.setState({ultimoAcesso: resp.data?.log_login_last?.created_at})
                this.setState({status: resp.data.status})
                this.setState({name: resp.data.name})
                this.setState({email: resp.data.email})
                this.setState({cpf: resp.data.cpf})
                this.setState({rg: resp.data.rg && resp.data.rg !== 'null' ? resp.data.rg : ''})
                this.setState({creci: resp.data.creci})
                this.setState({about: resp.data.about})
                this.setState({country: resp.data.country})
                this.setState({cep: resp.data.cep})
                this.setState({city: resp.data.city})
                this.setState({about: resp.data.about})
                this.setState({state: resp.data.state})
                this.setState({street: resp.data.street})
                this.setState({number: resp.data.number})
                this.setState({activeSite: resp.data.page_active})
                this.setState({district: resp.data.district})
                this.setState({complement: resp.data.complement})
                this.setState({phone: resp.data.phone})
                this.setState({fastAcademy: resp.data.fast_academy})
                this.setState({observacao: resp.data.workflow_observation})
                this.setState({capta: resp.data.p_count})
                this.setState({premiumActive: resp.data.premium})
                this.setState({saldoPremium: resp.data.premium_dates?.date_end})
                this.setState({userPhotoUrl: resp.data?.profile_photo})
                this.setState({rgImageUrl: resp.data.rg_photo})
                this.setState({rgImageVersoUrl: resp.data.rg_photo_verso})
                this.setState({creciImageUrl: resp.data.creci_photo})
                this.setState({creciImageVersoUrl: resp.data.creci_photo_verso})
                
                setTimeout(() => {
                  this.handleSelectState(resp.data.state)
                }, 300);
                
                Axios.get(process.env.REACT_APP_API + '/api/auth/real-estates-team/by-broker/' + userId,
                {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                .then(response => {
                  this.setState({realEstateTeam: response.data.realEstateTeam})
                  this.setState({teamActive: true})
                })

                document.getElementById('load').classList.remove('active')
            })
          }else{
            Axios.get(process.env.REACT_APP_API + '/api/auth/brokers/' + localStorage.getItem('id'),
            {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(resp => {
              this.setState({user: resp.data})

              if(resp.data.status === 3){
                this.setState({tog_update_docs: true})
              }
              this.setState({ultimoAcesso: resp.data?.log_login_last?.created_at})
              this.setState({status: resp.data.status})
              this.setState({name: resp.data.name})
              this.setState({email: resp.data.email})
              this.setState({cpf: resp.data.cpf})
              this.setState({rg: resp.data.rg && resp.data.rg !== 'null' ? resp.data.rg : ''})
              this.setState({creci: resp.data.creci})
              this.setState({about: resp.data.about})
              this.setState({about: resp.data.about})
              this.setState({country: resp.data.country})
              this.setState({cep: resp.data.cep})
              this.setState({city: resp.data.city})
              this.setState({state: resp.data.state})
              this.setState({street: resp.data.street})
              this.setState({number: resp.data.number})
              this.setState({activeSite: resp.data.page_active})
              this.setState({district: resp.data.district})
              this.setState({complement: resp.data.complement})
              this.setState({phone: resp.data.phone})
              this.setState({fastAcademy: resp.data.fast_academy})
              this.setState({observacao: resp.data.workflow_observation})
              this.setState({capta: resp.data.p_count})
              this.setState({premiumActive: resp.data.premium})
              this.setState({saldoPremium: resp.data.premium_dates?.date_end})
              this.setState({userPhotoUrl: resp.data?.profile_photo})
              this.setState({rgImageUrl: resp.data.rg_photo})
              this.setState({rgImageVersoUrl: resp.data.rg_photo_verso})
              this.setState({creciImageUrl: resp.data.creci_photo})
              this.setState({creciImageVersoUrl: resp.data.creci_photo_verso})
              setTimeout(() => {
                this.handleSelectState(resp.data.state)
              }, 300);

              Axios.get(process.env.REACT_APP_API + '/api/auth/real-estates-team/by-broker/' + localStorage.getItem('id'),
              {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
              .then(response => {
                this.setState({realEstateTeam: response.data.realEstateTeam})
                this.setState({teamActive: true})
              })
              document.getElementById('load').classList.remove('active')
          })
        }
      }
      if(localStorage.getItem('access') === 'I' || access === 'I'){
        if(access === 'I'){
          Axios.get(process.env.REACT_APP_API + '/api/auth/real-estates/'  + userId,
          {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
          .then(resp => {
            this.setState({user: resp.data})

            this.setState({ultimoAcesso: resp.data?.log_login_last?.created_at})
            this.setState({status: resp.data.status})
            this.setState({name: resp.data.name})
            this.setState({email: resp.data.email})
            this.setState({owner_cpf: resp.data.owner_cpf && resp.data.owner_cpf !== 'null' ? resp.data.owner_cpf : ''})
            this.setState({owner_rg: resp.data.owner_rg && resp.data.owner_rg !== 'null' ? resp.data.owner_rg : ''})
            this.setState({creci_j: resp.data.creci_j && resp.data.creci_j !== 'null' ? resp.data.creci_j : ''})
            this.setState({corporateName: resp.data.social_name && resp.data.social_name !== 'null' ? resp.data.social_name : ''})
            this.setState({cnpj: resp.data.cnpj})
            this.setState({about: resp.data.about})
            this.setState({country: resp.data.country})
            this.setState({cep: resp.data.cep})
            this.setState({city: resp.data.city})
            this.setState({state: resp.data.state})
            this.setState({street: resp.data.street})
            this.setState({number: resp.data.number})
            this.setState({time: resp.data.brokers_count})
            this.setState({district: resp.data.district})
            this.setState({activeSite: resp.data.page_active})
            this.setState({complement: resp.data.complement})
            this.setState({phone: resp.data.phone})
            this.setState({fastAcademy: resp.data.fast_academy})
            this.setState({observacao: resp.data.workflow_observation})
            this.setState({capta: resp.data.p_count})
            this.setState({premiumActive: resp.data.premium})
            this.setState({saldoPremium: resp.data.premium_dates?.date_end})
            this.setState({userPhotoUrl: resp.data?.profile_photo})
            this.setState({rgImageUrl: resp.data.photo_owner})
            this.setState({creciImageUrl: resp.data.photo_crecij})
            this.setState({rgImageVersoUrl: resp.data.photo_owner_verso})
            this.setState({creciImageVersoUrl: resp.data.photo_crecij_verso})
            this.setState({cnpjImageUrl: resp.data.photo_cnpj})


            Axios.get(process.env.REACT_APP_API + '/api/auth/real_estate_teams/by_real_estate/' + userId,
            {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
              .then(response => {
                this.setState({brokerTeam: response.data.realEstateTeams})
                  if(response.data?.realEstateTeams){
                    if(response.data?.realEstateTeams?.length < response.data?.realEstateTeams[0]?.real_estate?.brokers_count){
                        this.setState({newInvite: true})
                    }else{
                      if(0 < response.data?.realEstate?.brokers_count){
                        this.setState({newInvite: true})
                      }
                    }
                  }else{
                    this.setState({newInvite: true})
                  }

              })

            setTimeout(() => {
              this.handleSelectState(resp.data.state)
            }, 300);
            document.getElementById('load').classList.remove('active')
          }).catch(response => {
            
          })
        }else{
          Axios.get(process.env.REACT_APP_API + '/api/auth/real-estates/' + localStorage.getItem('id'),
          {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
          .then(resp => {
            this.setState({user: resp.data})

            if(resp.data.status === 3){
                this.setState({tog_update_docs: true})
              }

            this.setState({ultimoAcesso: resp.data?.log_login_last?.created_at})
            this.setState({status: resp.data.status})
            this.setState({name: resp.data.name})
            this.setState({email: resp.data.email})
            this.setState({owner_cpf: resp.data.owner_cpf && resp.data.owner_cpf !== 'null' ? resp.data.owner_cpf : ''})
            this.setState({owner_rg: resp.data.owner_rg && resp.data.owner_rg !== 'null' ? resp.data.owner_rg : ''})
            this.setState({creci_j: resp.data.creci_j && resp.data.creci_j !== 'null' ? resp.data.creci_j : ''})
            this.setState({corporateName: resp.data.social_name && resp.data.social_name !== 'null' ? resp.data.social_name : ''})
            this.setState({cnpj: resp.data.cnpj})
            this.setState({about: resp.data.about})
            this.setState({country: resp.data.country})
            this.setState({cep: resp.data.cep})
            this.setState({city: resp.data.city})
            this.setState({state: resp.data.state})
            this.setState({street: resp.data.street})
            this.setState({number: resp.data.number})
            this.setState({activeSite: resp.data.page_active})
            this.setState({district: resp.data.district})
            this.setState({complement: resp.data.complement})
            this.setState({phone: resp.data.phone})
            this.setState({fastAcademy: resp.data.fast_academy})
            this.setState({about: resp.data.about})
            this.setState({observacao: resp.data.workflow_observation})
            this.setState({capta: resp.data.p_count})
            this.setState({premiumActive: resp.data.premium})
            this.setState({saldoPremium: resp.data.premium_dates?.date_end})
            this.setState({userPhotoUrl: resp.data?.profile_photo})
            this.setState({rgImageUrl: resp.data.photo_owner})
            this.setState({creciImageUrl: resp.data.photo_crecij})
            this.setState({rgImageVersoUrl: resp.data.photo_owner_verso})
            this.setState({creciImageVersoUrl: resp.data.photo_crecij_verso})
            this.setState({cnpjImageUrl: resp.data.photo_cnpj})
            setTimeout(() => {
              this.handleSelectState(resp.data.state)
            }, 300);
            document.getElementById('load').classList.remove('active')
          })
        }
      }
      if(localStorage.getItem('access') === 'P' || access === 'P'){
        if(access === 'P'){
          Axios.get(process.env.REACT_APP_API + '/api/auth/owners/'  + userId,
          {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
          .then(resp => {
            this.setState({user: resp.data})

            this.setState({status: resp.data.status})
            this.setState({name: resp.data.name})
            this.setState({email: resp.data.email})
            this.setState({cpf: resp.data.cpf})
            this.setState({rg: resp.data.rg && resp.data.rg !== 'null' ? resp.data.rg : ''})
            this.setState({country: resp.data.country})
            this.setState({cep: resp.data.cep})
            this.setState({city: resp.data.city})
            this.setState({state: resp.data.state})
            this.setState({street: resp.data.street})
            this.setState({number: resp.data.number})
            this.setState({district: resp.data.district})
            this.setState({complement: resp.data.complement})
            this.setState({phone: resp.data.phone})
            this.setState({userPhotoUrl: resp.data?.profile_photo})
            setTimeout(() => {
              this.handleSelectState(resp.data.state)
            }, 300);
            document.getElementById('load').classList.remove('active')
          })
        }else{
          Axios.get(process.env.REACT_APP_API + '/api/auth/owners/' + localStorage.getItem('id'),
          {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
          .then(resp => {
            this.setState({user: resp.data})

            if(resp.data.status === 3){
                this.setState({tog_update_docs: true})
              }

            this.setState({status: resp.data.status})
            this.setState({name: resp.data.name})
            this.setState({email: resp.data.email})
            this.setState({cpf: resp.data.cpf})
            this.setState({rg: resp.data.rg && resp.data.rg !== 'null' ? resp.data.rg : ''})
            this.setState({country: resp.data.country})
            this.setState({cep: resp.data.cep})
            this.setState({city: resp.data.city})
            this.setState({state: resp.data.state})
            this.setState({about: resp.data.about})
            this.setState({street: resp.data.street})
            this.setState({number: resp.data.number})
            this.setState({district: resp.data.district})
            this.setState({complement: resp.data.complement})
            this.setState({phone: resp.data.phone})
            this.setState({userPhotoUrl: resp.data?.profile_photo})
            setTimeout(() => {
              this.handleSelectState(resp.data.state)
            }, 300);
            document.getElementById('load').classList.remove('active')
          })
        }
      }
      if(access === 'A'){
          Axios.get(process.env.REACT_APP_API + '/api/auth/users/'  + userId,
          {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
          .then(resp => {
            this.setState({user: resp.data})

            this.setState({status: resp.data.status})
            this.setState({name: resp.data.name})
            this.setState({email: resp.data.email})
            this.setState({userPhotoUrl: resp.data?.profile_photo})
            setTimeout(() => {
              this.handleSelectState(resp.data.state)
            }, 300);
            document.getElementById('load').classList.remove('active')
          })
      }
        
      })
      .catch(response =>{
        localStorage.removeItem("history_url")
        setTimeout(() => {
          window.open("/login", '_parent')
        }, 300);
      })

      Axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
      .then(response => {
        const states = []
        for(var i = 0; response.data.length > i; i++){
          states.push({
            value: response.data[i].sigla,
            label: response.data[i].nome
          })
        }
        this.setState({statesAddress: states})
      })
  }

  sendResponse = (type) =>{
    if(this.state.feedbackId && this.state.feedbackId !== ''){
      Axios.post(
        process.env.REACT_APP_API + '/api/auth/update-feedback/' + this.state.feedbackId,
        {response: this.state.feedback},
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } }
      )
    }else{
      const data2 = new FormData()
      data2.append('user_id', userId)
      data2.append('user_type', type)
      data2.append('response', this.state.feedback)

      Axios.post(
        process.env.REACT_APP_API + '/api/register/new-feedback',
        data2,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } }
      )
    }
  }

  sendUpdate(){
    document.getElementById('load').classList.add('active')

    if(localStorage.getItem('access') === 'C' || access === 'C'){
      const data = new FormData()
      data.append('name', this.state.name)
      data.append('email', this.state.email)
      data.append('cpf', this.state.cpf)
      data.append('rg', this.state.rg)
      data.append('creci', this.state.creci)
      data.append('about', this.state.about)
      data.append('country', this.state.country)
      data.append('cep', this.state.cep)
      data.append('city', this.state.city)
      data.append('state', this.state.state)
      data.append('page_active', this.state.activeSite ? this.state.activeSite : 0)
      data.append('street', this.state.street)
      data.append('number', this.state.number)
      data.append('district', this.state.district)
      data.append('complement', this.state.complement)
      data.append('phone', this.state.phone)
      data.append('fast_academy', this.state.fastAcademy)
      data.append('workflow_observation', this.state.observacao)
      data.append('p_count', this.state.capta)
      if(this.state.validateDocs){
        data.append('status', 2)
      }else{
        data.append('status', this.state.status)
      }
      if(this.state.userPhoto){
        data.append('profile_photo', this.state.userPhoto)
      }
      if(this.state.creciImage){
        data.append('creci_photo', this.state.creciImage)
      }
      if(this.state.rgImage){
        data.append('rg_photo', this.state.rgImage)
      }
      if(this.state.creciImageVerso){
        data.append('creci_photo_verso', this.state.creciImageVerso)
      }
      if(this.state.rgImageVerso){
        data.append('rg_photo_verso', this.state.rgImageVerso)
      }

      if(localStorage.getItem('access') === 'A'){
        data.append('user_id', localStorage.getItem('id'))
        data.append('premium', this.state.premiumActive)
        data.append('date_end', this.state.saldoPremium)
      }

      this.sendResponse('C')

      Axios.post(process.env.REACT_APP_API + '/api/auth/brokers-update/' + (localStorage.getItem('access') === 'A' ? userId : localStorage.getItem('id')), data,
         {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
           .then(response => {
             document.getElementById('load').classList.remove('active')
             this.setState({ update_success: true })
             if(localStorage.getItem('access') !== 'A'){
              setTimeout(() => {
                window.open('/dashboard', '_parent')
              },300);
            }else{
              setTimeout(() => {
                window.location.reload()
              },300);
            }

           })
           .catch(response => {
             document.getElementById('load').classList.remove('active')
             this.setState({ update_error: true })
              setTimeout(() => {
                window.location.reload()
              },300);
           })
    }
    if(localStorage.getItem('access') === 'T' || access === 'T'){
      const data = new FormData()
      data.append('name', this.state.name)
      data.append('email', this.state.email)
      data.append('owner_cpf', this.state.owner_cpf)
      data.append('owner_rg', this.state.owner_rg)
      data.append('owner_name', this.state.owner_name)
      // data.append('creci_j', this.state.creci_j)
      data.append('cnpj', this.state.cnpj)
      data.append('social_name', this.state.corporateName)
      data.append('about', this.state.about)
      data.append('country', this.state.country)
      data.append('cep', this.state.cep)
      data.append('city', this.state.city)
      data.append('site', this.state.site)
      data.append('instagram', this.state.instagram)
      data.append('state', this.state.state)
      data.append('street', this.state.street)
      data.append('number', this.state.number)
      data.append('district', this.state.district)
      data.append('complement', this.state.complement)
      data.append('phone', this.state.phone)
      if(this.state.launch && this.state.launch !== 'null'){
        data.append('launch', this.state.launch)
      }
      // data.append('fast_academy', this.state.fastAcademy)
      data.append('workflow_observation', this.state.observacao)
      // data.append('p_count', this.state.capta)
      data.append('status', this.state.status)
      if(this.state.userPhoto){
        data.append('profile_photo', this.state.userPhoto)
      }
      if(this.state.profile_bg){
        data.append('profile_bg', this.state.profile_bg)
      }
      // if(this.state.creciImage){
      //   data.append('photo_crecij', this.state.creciImage)
      // }
      if(this.state.rgImage){
        data.append('photo_owner', this.state.rgImage)
      }
      // if(this.state.creciImageVerso){
      //   data.append('photo_crecij_verso', this.state.creciImageVerso)
      // }
      // if(this.state.rgImageVerso){
      //   data.append('photo_owner_verso', this.state.rgImageVerso)
      // }
      if(this.state.cnpjImage){
        data.append('photo_cnpj', this.state.cnpjImage)
      }

      if(localStorage.getItem('access') === 'A'){
        data.append('user_id', localStorage.getItem('id'))
        // data.append('premium', this.state.premiumActive)
        // data.append('date_end', this.state.saldoPremium)
      }

      Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/construction-update/' + (localStorage.getItem('access') === 'A' ? userId : localStorage.getItem('id')), data,
         {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
           .then(response => {
             document.getElementById('load').classList.remove('active')
             this.setState({ update_success: true })
             if(localStorage.getItem('access') !== 'A'){
                setTimeout(() => {
                  window.open('/dashboard', '_parent')
                },300);
              }else{
                setTimeout(() => {
                  window.location.reload()
                },300);
              }
           })
           .catch(response => {
             document.getElementById('load').classList.remove('active')
             this.setState({ update_error: true })
              // setTimeout(() => {
              //   window.location.reload()
              // },300);
           })
  
    }
    if(localStorage.getItem('access') === 'I' || access === 'I'){
      const data = new FormData()
      data.append('name', this.state.name)
      data.append('email', this.state.email)
      data.append('owner_cpf', this.state.owner_cpf)
      data.append('owner_rg', this.state.owner_rg)
      data.append('creci_j', this.state.creci_j)
      data.append('cnpj', this.state.cnpj)
      data.append('social_name', this.state.corporateName)
      data.append('about', this.state.about)
      data.append('country', this.state.country)
      data.append('page_active', this.state.activeSite ? this.state.activeSite : 0)
      data.append('cep', this.state.cep)
      data.append('city', this.state.city)
      data.append('state', this.state.state)
      data.append('brokers_count', this.state.time ? this.state.time : 0)
      data.append('street', this.state.street)
      data.append('number', this.state.number)
      data.append('district', this.state.district)
      data.append('complement', this.state.complement)
      data.append('phone', this.state.phone)
      data.append('fast_academy', this.state.fastAcademy)
      data.append('workflow_observation', this.state.observacao)
      data.append('p_count', this.state.capta)
      if(this.state.validateDocs){
        data.append('status', 2)
      }else{
        data.append('status', this.state.status)
      }
      if(this.state.userPhoto){
        data.append('profile_photo', this.state.userPhoto)
      }
      if(this.state.creciImage){
        data.append('photo_crecij', this.state.creciImage)
      }
      if(this.state.rgImage){
        data.append('photo_owner', this.state.rgImage)
      }
      if(this.state.creciImageVerso){
        data.append('photo_crecij_verso', this.state.creciImageVerso)
      }
      if(this.state.rgImageVerso){
        data.append('photo_owner_verso', this.state.rgImageVerso)
      }
      if(this.state.cnpjImage){
        data.append('photo_cnpj', this.state.cnpjImage)
      }

      if(localStorage.getItem('access') === 'A'){
        data.append('user_id', localStorage.getItem('id'))
        data.append('premium', this.state.premiumActive)
        data.append('date_end', this.state.saldoPremium)
      }

      this.sendResponse('I')

      Axios.post(process.env.REACT_APP_API + '/api/auth/real-estates-update/' + (localStorage.getItem('access') === 'A' ? userId : localStorage.getItem('id')), data,
         {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
           .then(response => {
             document.getElementById('load').classList.remove('active')
             this.setState({ update_success: true })
             if(localStorage.getItem('access') !== 'A'){
              setTimeout(() => {
                window.open('/dashboard', '_parent')
              },300);
            }else{
              setTimeout(() => {
                window.location.reload()
              },300);
            }
           })
           .catch(response => {
             document.getElementById('load').classList.remove('active')
             this.setState({ update_error: true })
              setTimeout(() => {
                window.location.reload()
              },300);
           })
  
    }
    if(localStorage.getItem('access') === 'P' || access === 'P'){
      const data = new FormData()
      data.append('name', this.state.name)
      data.append('email', this.state.email)
      data.append('cpf', this.state.cpf)
      data.append('rg', this.state.rg)
      data.append('country', this.state.country)
      data.append('cep', this.state.cep)
      data.append('city', this.state.city)
      data.append('state', this.state.state)
      data.append('street', this.state.street)
      data.append('number', this.state.number)
      data.append('district', this.state.district)
      data.append('complement', this.state.complement)
      data.append('phone', this.state.phone)
      data.append('status', this.state.status)
      if(this.state.userPhoto){
        data.append('profile_photo', this.state.userPhoto)
      }

      Axios.post(process.env.REACT_APP_API + '/api/auth/owners-update/' + (localStorage.getItem('access') === 'A' ? userId : localStorage.getItem('id')), data,
         {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
           .then(response => {
             document.getElementById('load').classList.remove('active')
             this.setState({ update_success: true })
             if(localStorage.getItem('access') !== 'A'){
              setTimeout(() => {
                window.open('/dashboard', '_parent')
              },300);
            }else{
              setTimeout(() => {
                window.location.reload()
              },300);
            }
           })
           .catch(response => {
             document.getElementById('load').classList.remove('active')
             this.setState({ update_error: true })
              setTimeout(() => {
                window.location.reload()
              },300);
           })
    }

    if(localStorage.getItem('access') === 'A' && access === 'A'){
      const data = new FormData()
      data.append('status', this.state.status)
      if(this.state.userPhoto){
        data.append('profile_photo', this.state.userPhoto)
      }

      Axios.post(process.env.REACT_APP_API + '/api/auth/users-update/' + (localStorage.getItem('access') === 'A' ? userId : localStorage.getItem('id')), data,
         {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
           .then(response => {
             document.getElementById('load').classList.remove('active')
             this.setState({ update_success: true })
              setTimeout(() => {
                window.location.reload()
              },300);
           })
           .catch(response => {
             document.getElementById('load').classList.remove('active')
             this.setState({ update_error: true })
              setTimeout(() => {
                window.location.reload()
              },300);
           })
    }
  }

  sumPrices(properties){
    if (!properties) {
      return 0;
    }
  
    const totalValue = properties.reduce((total, property) => total + property.sale_value, 0);
  
    return totalValue;
  }

  handleContractDownload(){
    Axios.get(process.env.REACT_APP_API + '/api/auth/buy-plan-term?user_id=' + userId + '&user_type=' + access,
      {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
      .then(response =>{
          Axios.get(process.env.REACT_APP_API + '/api/auth/buy-plan-term/' + response.data.data.id + '/pdf', {
            headers: {"Authorization": "Bearer " + localStorage.getItem('token')},
            responseType: 'blob'
        })
        .then((pdfResponse) => {
            const fileURL = window.URL.createObjectURL(new Blob([pdfResponse.data]));
            
            const link = document.createElement('a');
            link.href = fileURL;
            link.setAttribute('download', 'contrato_' + response.data.data.id + '.pdf');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
      })
  }


  render() {

    const { statesAddress } = this.state

    const confirmElement = (
      <button type="submit" className="btn btn-success editable-submit btn-sm me-1"><i className="mdi mdi-check"></i></button>
    );
    /** Cancel button */
    const cancelElement = (
      <button type="button" className="btn btn-danger editable-cancel btn-sm"><i className="mdi mdi-close"></i></button>
    );

    const {user} = this.state;

    const locale = {
      weekdays: {
        shorthand: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
        longhand: [
          'Domingo',
          'Segunda-feira',
          'Terça-feira',
          'Quarta-feira',
          'Quinta-feira',
          'Sexta-feira',
          'Sábado'
        ]
      },
      months: {
        shorthand: [
          'Jan',
          'Fev',
          'Mar',
          'Abr',
          'Mai',
          'Jun',
          'Jul',
          'Ago',
          'Set',
          'Out',
          'Nov',
          'Dez'
        ],
        longhand: [
          'Janeiro',
          'Fevereiro',
          'Março',
          'Abril',
          'Maio',
          'Junho',
          'Julho',
          'Agosto',
          'Setembro',
          'Outubro',
          'Novembro',
          'Dezembro'
        ]
      },
      firstDayOfWeek: 0,
      rangeSeparator: ' até ',
      weekAbbreviation: 'Sem',
      scrollTitle: 'Rolagem para aumentar',
      toggleTitle: 'Clique para alternar',
      amPM: ['AM', 'PM'],
      yearAriaLabel: 'Ano',
      time_24hr: true
    };

    return (


    //   {(localStorage.getItem('user_type') === 'P' || localStorage.getItem('access') === 'P') &&
    //   <div className="col info-2">
    //     <div className="input-container">
    //       <label>Estado Civil</label>
    //       <input id="civil" type="text" value={maritalStatus} onChange={(e) => setMaritalStatus(e.target.value)}></input>
    //     </div>
    //     <div className="input-container">
    //       <label>Profissão</label>
    //       <input id="profissao" type="text" value={profession} onChange={(e) => setProfession(e.target.value)}></input>
    //     </div>
    //   </div>
    // }

        <div className="page-content" id="editUser">
        <Container fluid={true}>
          <Breadcrumbs title="Editar perfil" breadcrumbItems={this.state.breadcrumbItems} />
          <Row>
            <Col>
              <Card>
                <CardBody>
                <Row
                  className={`bannerProfile ${
                    localStorage.getItem('access') === 'T' ||
                    access === 'T' ||
                    localStorage.getItem('access') === 'S' ||
                    access === 'S'
                      ? 'construtoras'
                      : user?.premium === 0
                      ? 'free'
                      : user?.premium === 1
                      ? 'premium'
                      : user?.premium === 2
                      ? 'start'
                      : ''
                  }`}
                  style={{
                    ...(localStorage.getItem('access') === 'T' || access === 'T'
                      ? {
                          backgroundImage: `url('${
                            this.state.profile_bg
                              ? URL.createObjectURL(this.state.profile_bg)
                              : this.state.userProfileBg || ''
                          }')`,
                        }
                      : {}),
                    ...(localStorage.getItem('access') === 'P' || access === 'P'
                      ? { backgroundImage: 'url()' }
                      : {}),
                  }}
                >
                    <Col className="containerUserPhoto">
                      <img className="photo" src={(this.state.userPhotoUrl || this.state.userPhoto) ? (this.state.userPhoto ? URL.createObjectURL(this.state.userPhoto) : this.state.userPhotoUrl) : userDefault}></img>
                      

                      {(localStorage.getItem('access') !== 'S' && access !== 'S') && <label for="photo" className="userPhoto"><i className="ri-camera-fill"></i></label>}
                      {(localStorage.getItem('access') !== 'S' && access !== 'S') && <input id="photo" name="photo" type="file" style={{display:'none'}} onChange={(e) => this.setState({userPhoto: e.target.files[0]})} />}
                    </Col>
                    {localStorage.getItem('access') === 'A' && access !== 'S' && <i className="ri-settings-5-fill" style={{color:'#fff', fontSize:'24px', cursor:'pointer', marginTop:'20px'}} onClick={() => this.setState({modal_admin: true})}></i> }
                    {localStorage.getItem('level') !== '2' && (localStorage.getItem('access') !== 'S' && access !== 'S') &&
                    <Button className="saveButton" onClick={() => {
                      this.sendUpdate()
                    }}>Salvar alterações</Button>}
                    {(localStorage.getItem('access') === 'T' || (localStorage.getItem('access') === 'A' && access === 'T')) &&
                      <>
                        <label className="profile-bg" htmlFor="profile_bg">Alterar banner</label>
                        <input id="profile_bg" name="profile_bg" type="file" style={{display:'none'}} onChange={(e) => this.setState({profile_bg: e.target.files[0]})} />
                      </>
                    }
                  </Row>
                  <Row className="infoProfile">
                    <Col md={8} xs={12}>
                    <Row>
                      <Col>
                        <h3>{this.state.name}</h3>
                        {(localStorage.getItem('access') === 'S' || access === 'S') && <p>Construtora: {this.state.constructionParent}</p>}
                      </Col>
                    </Row>
                    <Row>
                      {(localStorage.getItem('access') === 'I' || access === 'I') &&
                      <>
                        <Col>
                          <p><i className="ri-secure-payment-line"></i>CRECI J: {this.state.creci_j}</p>
                          <p><i className="ri-map-pin-2-fill"></i>{this.state.city}/{this.state.state}</p>
                        </Col>
                        <Col>
                          <p><i className="ri-secure-payment-line"></i>Razão Social: {this.state.corporateName}</p>
                          <p><i className="ri-map-pin-2-fill"></i>CNPJ: {this.state.cnpj}</p>
                        </Col>
                        <Col>
                          <p><i className="ri-contacts-book-2-line"></i>CPF: {this.state.owner_cpf}</p>
                          <p><i className="ri-contacts-book-2-fill"></i>RG: {this.state.owner_rg}</p>
                        </Col>
                        </>
                      }
                      {(localStorage.getItem('access') === 'T' || access === 'T') &&
                      <>
                        <Col>
                          <p><i className="ri-secure-payment-line"></i>Razão Social: {this.state.corporateName}</p>
                          <p><i className="ri-map-pin-2-fill"></i>CNPJ: {this.state.cnpj}</p>
                        </Col>
                        {/* <Col>
                          <p><i className="ri-contacts-book-2-line"></i>CPF (proprietário): {this.state.owner_cpf}</p>
                          <p><i className="ri-contacts-book-2-fill"></i>RG (proprietário): {this.state.owner_rg}</p>
                        </Col> */}
                        </>
                      }
                      {(localStorage.getItem('access') === 'C' || access === 'C') &&
                      <>
                        <Col>
                          <p><i className="ri-secure-payment-line"></i>CRECI: {this.state.creci}</p>
                          <p><i className="ri-map-pin-2-fill"></i>{this.state.city}/{this.state.state}</p>
                        </Col>
                        <Col>
                          <p><i className="ri-contacts-book-2-line"></i>CPF: {this.state.cpf}</p>
                          <p><i className="ri-contacts-book-2-fill"></i>RG: {this.state.rg}</p>
                        </Col>
                      </>
                      }
                      {(localStorage.getItem('access') === 'P' || access === 'P') &&
                      <>
                        <Col>
                          <p><i className="ri-map-pin-2-fill"></i>{this.state.city}/{this.state.state}</p>
                        </Col>
                        <Col>
                          <p><i className="ri-contacts-book-2-line"></i>CPF: {this.state.cpf}</p>
                          <p><i className="ri-contacts-book-2-fill"></i>RG: {this.state.rg}</p>
                        </Col>
                      </>
                      }
                    </Row>
                    </Col>
                    <Col md={4} xs={12}>
                      <div style={{height:'calc(100% - 60px)', display:'flex', alignItems:'flex-end', justifyContent:'flex-end'}}>

                        {user.status === 3 &&
                          <Button style={{backgroundColor:"#ed4c4b", border:'none'}} onClick={() => {
                            this.setState({tog_update_docs: true})
                          }}>Validar Cadastro</Button>
                        }
                        {/* // user?.workflow_observation && user?.workflow_observation !== 'null' ?
                        // <Badge className="bg-dark me-1 rounded-pill" style={{fontSize:'16px'}}>{user?.workflow_observation}</Badge>
                      // : */}
                       {user.status === 2 &&
                         <div className="bg-warning me-1" style={{fontSize:'14px', color:'#333', borderRadius:'8px', padding:'5px 10px'}}>Recebemos seus documentos, eles serão analisados em até 24 horas.</div>
                        }
                      </div>
                      <div className="userStatus">
                        {user.premium === 0 ? <Badge className="bg-dark me-1 rounded-pill" style={{fontSize:'16px'}}>Usuário Free</Badge>
                        : user.premium === 2 ? <Badge className="bg-info me-1 rounded-pill" style={{fontSize:'16px'}}>Usuário Start</Badge>
                        : user.premium === 1 && <Badge className="bg-success me-1 rounded-pill" style={{fontSize:'16px'}}>Usuário Premium</Badge>}

                        {(user.status === 2 || user.status === 3) ? <Badge className="bg-warning me-1 rounded-pill" style={{fontSize:'16px'}}>Pendente</Badge>
                        : user.status === 1 ? <Badge className="bg-success me-1 rounded-pill" style={{fontSize:'16px'}}>Ativo</Badge>
                        : user.status === 0 && <Badge className="bg-dark me-1 rounded-pill" style={{fontSize:'16px'}}>Inativo</Badge>}
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {this.state.constructionUsers.length > 0 &&
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <Row style={{marginBottom:'15px'}}>
                    <Col xs={12}>
                      <h3 style={{fontSize:'16px'}}>Usuários da construtora</h3>
                    </Col>
                  </Row>
                  {this.state.constructionUsers.map((item, index) => (
                    <Row key={index} style={{marginBottom:'10px'}}>
                      <Col md={4} lg={2}>{item.name}</Col>
                      <Col md={4} lg={2}>{item.email}</Col>
                      {item.role === 'admin' && <Col md={2} lg={2}><div style={{maxWidth: '180px', backgroundColor: '#050505', color: '#fff', borderRadius:'5px', display:'flex', alignItem:"center", justifyContent:'center'}}>Criar e editar</div></Col>}
                      {item.role === 'view' && <Col md={2} lg={2}><div style={{maxWidth: '180px', backgroundColor: '#050505', color: '#fff', borderRadius:'5px', display:'flex', alignItem:"center", justifyContent:'center'}}>Visualizar</div></Col>}
                    </Row>
                  ))}
                </CardBody>
              </Card>
            </Col>
          </Row>
          }
          {((localStorage.getItem('access') === 'A' || localStorage.getItem('access') === 'C') && this.state.teamActive) &&
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  {localStorage.getItem('access') === 'A' ?
                  <Row style={{justifyContent:'space-between'}}>
                    <Col className="col-auto">
                      <h3 style={{fontSize:'16px'}}>Este corretor é membro do time da imobiliária {this.state?.realEstateTeam?.real_estate?.name}</h3>
                    </Col>
                  </Row>
                  :
                  <Row style={{justifyContent:'space-between'}}>
                    <Col className="col-auto">
                      <h3 style={{fontSize:'16px'}}>Você é membro do time da imobiliária {this.state?.realEstateTeam?.real_estate?.name}</h3>
                    </Col>
                    <Col className="col-auto">
                      <Button onClick={() => {
                        Axios.post(process.env.REACT_APP_API + '/api/auth/real-estates-team-delete/' +  this.state?.realEstateTeam.id, {},
                        {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                        .then(response => {
                          setTimeout(() => {
                            window.location.reload()
                          }, 300);
                        })
                      }}>Sair do time</Button>
                    </Col>
                  </Row>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
          }
          {access === 'I' &&
            <Row>
              <Col cs={12}>
                <Card>
                  <CardBody>
                      <Row>
                        <Col>
                          <h3 style={{fontSize:'16px', display:'flex', alignItems:'center', gap:'8px'}}>Time de corretores</h3>
                        </Col>
                      </Row>
                      <Row style={{marginTop:'20px'}}>
                      {this.state.brokerTeam?.map((member, index) => (
                        <Col key={index} className="col-auto" style={{position:'relative'}}>
                          <div className="status-add-broker">
                            {member.approved === 0 && <div className="pendente">Corretor pendente</div>}
                            {member.approved === 1 && <div className="ativo">Corretor ativo</div>}
                            {member.approved === 2 && <div className="negado">Convite negado</div>}
                          </div>
                          <div className="add-borker-card-member">
                            <div className="top-add-broker-card">
                              <div className="cover-add-broker-card" style={{backgroundImage:'url(' + member.broker?.profile_photo + ')'}}></div>
                              <div className="name-add-broker-card">
                                <h3>{member.broker?.name}</h3>
                                {member.approved === 1 ?
                                  <Button size="sm" onClick={() => {
                                    setTimeout(() => {
                                      window.open('./../../editar-perfil?id=' + member.broker?.id + '&type=C', '_parent')
                                    }, 100);
                                  }}><i className="ri-user-line align-middle me-1" style={{marginRight:'3px'}}></i>Ver perfil</Button>
                                  :
                                  <Button size="sm" disabled><i className="ri-user-line align-middle me-1" style={{marginRight:'3px'}}></i>Ver perfil</Button>
                                }
                              </div>
                            </div>
                            <div className="body-add-broker-card">
                              <div className="box">
                                <div className="col-box">
                                  <p>Captações</p>
                                  <h3>{member.approved === 1 ? member.broker?.properties?.length : '***'}</h3>
                                </div>
                                <div className="col-box">
                                  <p>Valor Captações</p>
                                  <h3><span>R$</span>{member.approved === 1 ? (this.sumPrices(member.broker?.properties) / 100)?.toLocaleString('pt-br', {minimumFractionDigits: 2}) : '***'}</h3>
                                </div>
                              </div>
                              <div className="box">
                                <div className="col-box">
                                  <p>Download de material</p>
                                  <h3>{member.approved === 1 ? member.broker?.recent_downloaded?.length : '***'}</h3>
                                </div>
                                <div className="col-box">
                                  <p>Contratos de parceria</p>
                                  <h3>{member.approved === 1 ? member.broker?.accepted_contracts?.length : '***'}</h3>
                                </div>
                              </div>
                            </div>
                            <div className="footer-add-broker-card">
                                <span onClick={() => this.setState({confirm_both: true, delete_id: member.id})}><i className="ri-delete-bin-line"></i>Remover corretor</span>
                            </div>
                          </div>
                        </Col>
                      ))}

                  {this.state.newInvite ?
                    <Col className="col-auto">
                      <div className="add-borker-card" onClick={this.tog_center2}>
                        <div className="add-broker-button">
                          <i className="ri-user-add-line"></i>
                          <p>Adicionar Corretor</p>
                        </div>
                      </div>
                    </Col>
                  :
                  <Col className="col-auto">
                    <div className="add-borker-card" onClick={() => {
                      setTimeout(() => {
                        window.open('https://wa.me/554741081865', '_blank')
                      }, 100);
                    }}>
                      <div className="add-broker-button">
                        <i className="ri-user-add-line"></i>
                        <p>Seu time está cheio</p>
                        <p style={{textAlign:'center'}}>Faça um upgrade e aumente seu time</p>
                      </div>
                    </div>
                  </Col>
                  }

                      </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>  
          }
          {localStorage.getItem('access') === 'A' && (access === 'C' || access === 'I') &&
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col xs={12}>
                      <h3 style={{fontSize:'16px'}}>Captações</h3>
                    </Col>
                  </Row>
                  <Row>
                    {user.properties?.length > 0 ? user.properties.map((item, index) => (
                      <Col key={index} className="col-auto">
                        <ImovelCard imovel={item} premium={true} favoritos={[]}/>
                      </Col>
                    )) : 
                    <Col xs={12}>
                      <p>Usuário sem captações</p>
                    </Col>
                    }
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          }
          {access !== 'A' &&
          <Row className="reverse">
            <Col lg={7} md={6} xl={8}>
              <Card>
                {(localStorage.getItem('access') !== 'S' && access !== 'S') &&
                <CardBody>
                    <Row>
                      <Col>
                        <h3 style={{fontSize:'16px', display:'flex', alignItems:'center', gap:'8px'}}>Sobre<i id="editUserInfo" className="ri-information-line" style={{cursor:'pointer'}}></i></h3>
                      </Col>
                    </Row>
                    <Row style={{marginTop:'20px'}}>
                      <Col>
                      {access !== 'P' &&
                          <Input
                              type="textarea"
                              className="userAbout"
                              placeholder={this.state.exempleText}
                              defaultValue={(this.state.about && this.state.about !== 'null') ? this.state.about : ''}
                              onChange={(e) => this.setState({about: e.target.value})}
                          />
                      }
                      </Col>
                    </Row>
                </CardBody>
                }
              </Card>
            </Col>
            <Col lg={5} md={6} xl={4}>
              <Card>
                <CardBody>
                  <Row className="moreInfoProfile">
                    {((localStorage.getItem('access') !== 'S' && access !== 'S') && localStorage.getItem('access') !== 'A' && localStorage.getItem('access') !== 'P' && localStorage.getItem('access') !== 'T') &&
                      <Col xs={12}>
                        <p style={{fontSize:'18px', maxWidth:'400px'}}>Você pode receber até <span style={{fontSize:'22px', fontWeight:'600'}}>{(parseInt(localStorage.getItem('user_comission') / 100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}))}</span> por suas captações ativas</p>
                      </Col>
                    }
                    <Col xs={12}>
                      <i className="ri-mail-fill user-icon"></i><p>{this.state.email}</p>
                    </Col>
                    {(localStorage.getItem('access') !== 'S' && access !== 'S') &&
                    <Col xs={12}>
                      <i className="ri-map-pin-2-fill user-icon"></i>
                      <p>
                        {this.state.street} nº{this.state.number}, {this.state.complement}, {this.state.district}, {this.state.city} / {this.state.state} | cep: {this.state.cep}
                      </p>
                      <Modal
                        isOpen={this.state.modal_config}
                        toggle={() => this.setState({ modal_config: false })}
                        centered={true}
                        size="xl"
                        scrollable={true}
                      >
                        <ModalHeader className="title">
                          <div style={{display:'flex', width:'100%', gap:'80px', justifyContent:'space-between'}}>
                            Informações do perfil
                            <i className='ri-close-line' onClick={() => this.setState({ modal_config: false })}></i>
                          </div>
                        </ModalHeader>
                        <ModalBody>
                          <Container>
                            <Row style={{margin:'10px 0'}}>
                              <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Endereço e telefone</h5></Col>
                            </Row>
                            <Row>
                              <Col md={4}>
                                <Label htmlFor="cep" className="col-md-12 col-form-label">Cep</Label>
                                <Input
                                  id="cep"
                                  name="cep"
                                  placeholder="Cep"
                                  type="text"
                                  className="form-control"
                                  value={this.state.cep}
                                  onChange={(e) => {
                                    this.consultaCep(e.target.value.replace('-', '').replace('.', ''))
                                    this.setState({cep: e.target.value})
                                  }}
                                  required
                                />
                              </Col>
                              <Col md={6}>
                                <Label htmlFor="rua" className="col-md-12 col-form-label">Rua</Label>
                                <Input
                                  id="rua"
                                  name="rua"
                                  placeholder="Rua, Avenida..."
                                  type="text"
                                  className="form-control"
                                  value={this.state.street}
                                  onChange={(e) => this.setState({street: e.target.value})}
                                  required
                                />
                              </Col>
                              <Col md={2}>
                                <Label htmlFor="numero" className="col-md-12 col-form-label">Número</Label>
                                <Input
                                  id="numero"
                                  name="numero"
                                  placeholder="Número"
                                  type="text"
                                  className="form-control"
                                  value={this.state.number}
                                  onChange={(e) => this.setState({number: e.target.value})}
                                  required
                                />
                              </Col>
                              <Col md={6}>
                                <Label htmlFor="bairro" className="col-md-12 col-form-label">Bairro</Label>
                                <Input
                                  id="bairro"
                                  name="bairro"
                                  placeholder="Bairro"
                                  type="text"
                                  className="form-control"
                                  value={this.state.district}
                                  onChange={(e) => this.setState({district: e.target.value})}
                                  required
                                />
                              </Col>
                              <Col md={6}>
                                <Label htmlFor="complemento" className="col-md-12 col-form-label">Complemento</Label>
                                <Input
                                  id="complemento"
                                  name="complemento"
                                  placeholder="Complemento"
                                  type="text"
                                  value={this.state.complement}
                                  onChange={(e) => this.setState({complement: e.target.value})}
                                />
                              </Col>
                              <Col md={4}>
                                <Label htmlFor="estado" className="col-md-12 col-form-label">Estado</Label>
                                <Select
                                  placeholder="Selecione"
                                  ref={this.selectRef}
                                  noOptionsMessage={() => `Sem resultados`}
                                  onChange={(e) => this.handleSelectState(e.value)}
                                  options={statesAddress}
                                  classNamePrefix="select2-selection"
                                  value={this.state.statesAddress.find(option => option.value === this.state.state)}
                                />
                              </Col>
                              <Col md={4}>
                                <Label htmlFor="cidade" className="col-md-12 col-form-label">Cidade</Label>
                                <Select
                                  placeholder="Selecione"
                                  noOptionsMessage={() => `Sem resultados`}
                                  onChange={(e) => this.setState({ city: e.value })}
                                  options={this.state.cityAddress}
                                  classNamePrefix="select2-selection"
                                  value={this.state.cityAddress.find(option => option.value === this.state.city)}
                                />
                              </Col>
                              <Col md={4}>
                                <Label htmlFor="telefone" className="col-md-12 col-form-label">Telefone</Label>
                                <Input
                                  id="telefone"
                                  name="telefone"
                                  placeholder="telefone"
                                  type="tel"
                                  className="userPhone"
                                  value={this.state.phone}
                                  onChange={(e) => this.setState({phone: e.target.value})}
                                  required
                                />
                              </Col>
                              {(localStorage.getItem('access') === 'T' || access === 'T') &&
                                <>
                                <Col md={6}>
                                  <Label htmlFor="site" className="col-md-12 col-form-label">Site</Label>
                                  <Input
                                    id="site"
                                    name="site"
                                    placeholder="Site"
                                    type="text"
                                    className="form-control"
                                    value={this.state.site}
                                    onChange={(e) => this.setState({site: e.target.value})}
                                  />
                                </Col>
                                <Col md={6}>
                                  <Label htmlFor="instagram3" className="col-md-12 col-form-label">Instagram</Label>
                                  <Input
                                    id="instagram3"
                                    name="instagram3"
                                    placeholder="Instagram"
                                    type="text"
                                    value={this.state.instagram}
                                    onChange={(e) => this.setState({instagram: e.target.value})}
                                  />
                                </Col>
                                </>
                              }
                              <Container>
                                <Row style={{margin:'30px 0 10px'}}>
                                  <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Documentos</h5></Col>
                                </Row>
                                <Row>
                                {localStorage.getItem('access') === 'I' &&
                                  <>
                                    <Col xs={8} style={{marginTop: '.8rem'}}>
                                      <Label htmlFor="corporate_name">Razão Social:</Label>
                                      <Input 
                                        id="corporate_name"
                                        name="corporate_name"
                                        type="text"
                                        disabled={user?.social_name && user?.social_name !== ''}
                                        value={this.state.corporateName}
                                        onChange={(e) => this.setState({corporateName: e.target.value})}
                                      />
                                    </Col>
                                    <Col xs={4} style={{marginTop: '.8rem'}}>
                                      <Label htmlFor="cnpj">CNPJ:</Label>
                                      <Input 
                                        id="cnpj"
                                        name="cnpj"
                                        type="text"
                                        disabled={user?.cnpj && user?.cnpj !== ''}
                                        value={this.state.cnpj}
                                        onChange={(e) => this.setState({cnpj: e.target.value})}
                                      />
                                    </Col>
                                    <Col xs={6} style={{marginTop: '.8rem'}}>
                                      <Label htmlFor="rg">RG:</Label>
                                      <Input 
                                        id="rg"
                                        name="rg"
                                        type="text"
                                        disabled={user?.owner_rg && user?.owner_rg !== ''}
                                        value={this.state.owner_rg}
                                        onChange={(e) => this.setState({owner_rg: e.target.value})}
                                      />
                                    </Col>
                                    <Col xs={6} style={{marginTop: '.8rem'}}>
                                      <Label htmlFor="cpf">CPF:</Label>
                                      <Input 
                                        id="cpf"
                                        name="cpf"
                                        type="number"
                                        disabled={user?.owner_cpf && user?.owner_cpf !== ''}
                                        value={this.state.owner_cpf}
                                        onChange={(e) => this.setState({owner_cpf: e.target.value})}
                                      />
                                    </Col>
                                  </>
                                }
                                {localStorage.getItem('access') === 'T' &&
                                  <>
                                    <Col xs={8} style={{marginTop: '.8rem'}}>
                                      <Label htmlFor="corporate_name">Razão Social:</Label>
                                      <Input 
                                        id="corporate_name"
                                        name="corporate_name"
                                        type="text"
                                        disabled={user?.social_name && user?.social_name !== ''}
                                        value={this.state.corporateName}
                                        onChange={(e) => this.setState({corporateName: e.target.value})}
                                      />
                                    </Col>
                                    <Col xs={4} style={{marginTop: '.8rem'}}>
                                      <Label htmlFor="cnpj">CNPJ:</Label>
                                      <Input 
                                        id="cnpj"
                                        name="cnpj"
                                        type="text"
                                        disabled={user?.cnpj && user?.cnpj !== ''}
                                        value={this.state.cnpj}
                                        onChange={(e) => this.setState({cnpj: e.target.value})}
                                      />
                                    </Col>
                                    <Col xs={6} style={{marginTop: '.8rem'}}>
                                      <Label htmlFor="rg">RG:</Label>
                                      <Input 
                                        id="rg"
                                        name="rg"
                                        type="text"
                                        disabled={user?.owner_rg && user?.owner_rg !== ''}
                                        value={this.state.owner_rg}
                                        onChange={(e) => this.setState({owner_rg: e.target.value})}
                                      />
                                    </Col>
                                    <Col xs={6} style={{marginTop: '.8rem'}}>
                                      <Label htmlFor="cpf">CPF:</Label>
                                      <Input 
                                        id="cpf"
                                        name="cpf"
                                        type="number"
                                        disabled={user?.owner_cpf && user?.owner_cpf !== ''}
                                        value={this.state.owner_cpf}
                                        onChange={(e) => this.setState({owner_cpf: e.target.value})}
                                      />
                                    </Col>
                                  </>
                                }
                                {localStorage.getItem('access') === 'C' &&
                                  <>
                                    <Col md={6}>
                                      <Label htmlFor="rg" className="col-md-12 col-form-label">Nº RG</Label>
                                      <Input
                                        id="rg"
                                        name="rg"
                                        placeholder="RG"
                                        type="text"
                                        className="form-control"
                                        value={this.state.rg}
                                        disabled={user?.rg && user?.rg !== ''}
                                        onChange={(e) => this.setState({rg: e.target.value})}
                                        required
                                      />
                                    </Col>
                                    <Col md={6}>
                                      <Label htmlFor="cpf">CPF:</Label>
                                      <Input 
                                        id="cpf"
                                        name="cpf"
                                        type="text"
                                        disabled={user?.cpf && user?.cpf !== ''}
                                        value={this.state.cpf}
                                        onChange={(e) => this.setState({cpf: e.target.value})}
                                      />
                                    </Col>
                                  </>
                                }
                                </Row>
                              </Container>
                            </Row>
                          </Container>
                          <Container>
                            <Row style={{margin:'30px 0 10px'}}>
                              <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Alterar senha de acesso</h5></Col>
                            </Row>
                            {!this.state.senhaVerificada ?
                              <>
                              <Row>
                                <Col md={6}>
                                  <Label>Sua senha atual</Label>
                                  <Input type="password" value={this.state.senhaAtual} onChange={(e) => this.setState({ senhaAtual : e.target.value })}></Input>
                                </Col>
                                <Col md={6} style={{marginTop:'20px'}}>
                                  <Button onClick={() => {
                                      document.getElementById('load').classList.add('active')
                                      const data = {
                                        type: localStorage.getItem('access'),
                                        user_id: localStorage.getItem('id'),
                                        senha_atual: this.state.senhaAtual
                                      }
                                      Axios.post(process.env.REACT_APP_API + '/api/auth/password-verify', data,
                                      {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                                      .then(response => {
                                        this.setState({senhaVerificada: true})
                                        document.getElementById('load').classList.remove('active')
                                      })
                                      .catch(response => {
                                        this.setState({senhaIncorreta: true})
                                        document.getElementById('load').classList.remove('active')
                                      })
                                  }}>Verificar</Button>
                                                              
                                </Col>
                              </Row>
                              </>
                              :
                              <>
                              <Row style={{gap:'10px 0', paddingBottom:'15px', paddingTop:'15px', alignItems:'flex-end'}}>
                                <Col md={5}>
                                    <Label>Nova senha</Label>
                                    <Input type="password" value={this.state.senha} onChange={(e) => this.setState({ senha : e.target.value })}></Input>
                                </Col>
                                <Col md={5}>
                                    <Label>Confirmar nova senha</Label>
                                    <Input type="password" value={this.state.confirmacaoSenha} onChange={(e) => this.setState({ confirmacaoSenha : e.target.value })}></Input>
                                </Col>
                                <Col md={2} style={{textAlign:'center'}}>
                                    {this.state.truePassword ?
                                        <Button onClick={() => {
                                            document.getElementById('load').classList.add('active')

                                            const data = {
                                              type: access ? access : localStorage.getItem('access'),
                                              user_id: userId ? userId : localStorage.getItem('id'),
                                              new_password: this.state.senha
                                            }
                                            Axios.post(process.env.REACT_APP_API + '/api/auth/password-update', data,
                                            {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                                            .then(response => {
                                              this.setState({pass_success: true})
                                              document.getElementById('load').classList.remove('active')
                                              this.setState({confirmacaoSenha: '', senha: ''})
                                            })
                                            .catch(response => {
                                              this.setState({pass_error: true})
                                              document.getElementById('load').classList.remove('active')
                                            })
                                        }}>Alterar senha</Button>
                                    :
                                        <Button disabled>Alterar senha</Button>
                                    }
                                </Col>
                                <Col xs={12}>
                                    <PasswordChecklist
                                        rules={["minLength","specialChar","number","capital","match"]}
                                        minLength={8}
                                        iconSize={16}
                                        value={this.state.senha}
                                        valueAgain={this.state.confirmacaoSenha}
                                        onChange={this.handlePasswordChange}
                                        messages={{
                                            minLength: "Ao menos 8 caracteres.",
                                            specialChar: "Ao menos um caractere especial.",
                                            number: "Ao menos um número.",
                                            capital: "Ao menos uma letra maiúscula.",
                                            match: "Suas senhas coincidem.",
                                        }}
                                        />
                                </Col>
                              </Row>
                              </>
                            }


                          </Container>
                        </ModalBody>
                        <ModalFooter style={{textAlign:'center'}}>
                        {localStorage.getItem('level') !== '2' &&
                        <Button className="saveButton" onClick={() => {
                          this.sendUpdate()
                          }}>Salvar alterações
                        </Button>
                        }
                        </ModalFooter>
                      </Modal>
                    </Col>
                    }
                    {(localStorage.getItem('access') !== 'S' && access !== 'S') &&
                    <Col xs={12}>
                      <i className="ri-phone-fill user-icon"></i>
                      <p>
                        {this.state.phone}
                      </p>
                    </Col>
                    }
                    {localStorage.getItem('access') !== 'T' && (localStorage.getItem('access') !== 'S' && access !== 'S') &&
                    <Col xs={12}>
                      <i className="ri-global-line user-icon"></i>
                      {(localStorage.getItem('access') === 'I' || access === 'I') && (this.state.site !== '' ? <a href={this.state.site} target="_blank" rel="noreferrer">{this.state.site}</a> : <p>Você ainda não tem um site.</p>)}
                      {(localStorage.getItem('access') === 'C' || access === 'C') && (this.state.site !== '' ? <a href={this.state.site} target="_blank" rel="noreferrer">{this.state.site}</a> : <p>Você ainda não tem um site.</p>)}
                      {(localStorage.getItem('access') === 'T' || access === 'T') && (this.state.site !== '' ? this.state.site : <p>Você ainda não tem um site.</p>)}
                    </Col>
                    }
                    {localStorage.getItem('level') !== '2' &&
                    <Col xs={12} style={{borderTop:'solid 1px #cdcdcd', padding:'15px 0 0', justifyContent:'center'}}>
                      <Button onClick={() => {
                        if(localStorage.getItem('access') !== 'S' && access !== 'S'){
                          this.setState({modal_config: true})
                        }else{
                          this.setState({modal_edit_sub_user: true})
                        }
                        }}>
                        Editar informações e senha
                      </Button>
                    </Col>
                    }
                    {localStorage.getItem('access') !== 'T' && (localStorage.getItem('access') !== 'S' && access !== 'S') &&
                    <Col xs={12} style={{justifyContent:'center'}}>
                      <Button className="btn-dark" onClick={() => this.setState({modal_center: true})}>Modelo de autorização de venda</Button>
                    </Col>
                    }
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          }
        </Container>
        
        {/* Atualização de docs */}

        <Modal
          isOpen={this.state.tog_update_docs}
          toggle={this.tog_update_docs}
          size="lg"
          scrollable={true}
          centered={true}
        >
          <ModalHeader toggle={() => this.setState({ tog_update_docs: false })}>
            Complete seus dados para validar seu cadastro.
          </ModalHeader>
          <ModalBody>
            <Container>
              <Row style={{ gap: '20px' }}>
                {localStorage.getItem('access') === 'C' &&
                  <Col md={12}>
                    <Label htmlFor="rg" className="col-md-12 col-form-label">Nº RG</Label>
                    <Input
                      id="rg"
                      name="rg"
                      placeholder="RG"
                      type="text"
                      className="form-control"
                      value={this.state.rg}
                      onChange={(e) => this.setState({rg: e.target.value})}
                      required
                    />
                  </Col>
                }
                {localStorage.getItem('access') === 'I' &&
                <>
                  <Col md={12}>
                    <Label htmlFor="rg" className="col-md-12 col-form-label">Razão Social</Label>
                    <Input
                      id="social"
                      name="social"
                      placeholder="Razão Social"
                      type="text"
                      className="form-control"
                      value={this.state.corporateName}
                      onChange={(e) => this.setState({corporateName: e.target.value})}
                      required
                    />
                  </Col>
                  <Col md={5}>
                    <Label htmlFor="rg" className="col-md-12 col-form-label">CPF do proprietário</Label>
                    <Input
                      id="ownerCpf"
                      name="ownerCpf"
                      placeholder="Cpf do proprietário"
                      type="text"
                      className="form-control"
                      value={this.state.owner_cpf}
                      onChange={(e) => this.setState({owner_cpf: e.target.value})}
                      required
                    />
                  </Col>
                  <Col md={5}>
                    <Label htmlFor="rg" className="col-md-12 col-form-label">RG do proprietário</Label>
                    <Input
                      id="ownerRg"
                      name="ownerRg"
                      placeholder="Rg do proprietário"
                      type="text"
                      className="form-control"
                      value={this.state.owner_rg}
                      onChange={(e) => this.setState({owner_rg: e.target.value})}
                      required
                    />
                  </Col>
                  <Col
                  className="col-auto"
                  style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
                >
                  <div
                    style={{
                      boxShadow: '0 0 10px rgba(0,0,0,0.3)',
                      minWidth: '320px',
                      maxWidth: '480px',
                      borderRadius: '5px',
                      overflow: 'hidden',
                      cursor: 'pointer',
                    }}
                  >
                  </div>
                  <h4 className="card-title">Cartão CNPJ</h4>
                  <div className="input-group">
                    <input
                      type="file"
                      className="form-control"
                      id="cnpjImage"
                      onChange={(e) => this.setState({ cnpjImage: e.target.files[0] })}
                    />
                  </div>
                </Col>
                </>
                }
                <Col
                  className="col-auto"
                  style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
                >
                  <div
                    style={{
                      boxShadow: '0 0 10px rgba(0,0,0,0.3)',
                      minWidth: '320px',
                      maxWidth: '480px',
                      borderRadius: '5px',
                      overflow: 'hidden',
                      cursor: 'pointer',
                    }}
                  >
                  </div>
                  {localStorage.getItem('access') === 'C' &&
                  <h4 className="card-title">Imagem do CRECI (frente)</h4>
                  }
                  {localStorage.getItem('access') === 'I' &&
                  <h4 className="card-title">Imagem do CRECI J (frente)</h4>
                  }
                  <div className="input-group">
                    <input
                      type="file"
                      className="form-control"
                      id="creciImage"
                      onChange={(e) => this.setState({ creciImage: e.target.files[0] })}
                    />
                  </div>
                </Col>
                <Col
                  className="col-auto"
                  style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
                >
                  <div
                    style={{
                      boxShadow: '0 0 10px rgba(0,0,0,0.3)',
                      minWidth: '320px',
                      maxWidth: '480px',
                      borderRadius: '5px',
                      overflow: 'hidden',
                      cursor: 'pointer',
                    }}
                  >
                  </div>
                  {localStorage.getItem('access') === 'C' &&
                  <h4 className="card-title">Imagem do CRECI (verso)</h4>
                  }
                  {localStorage.getItem('access') === 'I' &&
                  <h4 className="card-title">Imagem do CRECI J (verso)</h4>
                  }
                  <div className="input-group">
                    <input
                      type="file"
                      className="form-control"
                      id="creciImageVerso"
                      onChange={(e) => this.setState({ creciImageVerso: e.target.files[0] })}
                    />
                  </div>
                </Col>
                <Col
                  className="col-auto"
                  style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
                >
                  <div
                    style={{
                      boxShadow: '0 0 10px rgba(0,0,0,0.3)',
                      minWidth: '320px',
                      maxWidth: '480px',
                      borderRadius: '5px',
                      overflow: 'hidden',
                      cursor: 'pointer',
                    }}
                  >
                  </div>
                  {localStorage.getItem('access') === 'C' &&
                  <h4 className="card-title">Foto do documento (frente do rg, cnh, ...)</h4>
                  }
                  {localStorage.getItem('access') === 'I' &&
                  <h4 className="card-title">Foto do documento do proprietário (frente do rg, cnh, ...)</h4>
                  }
                  <div className="input-group">
                    <input
                      type="file"
                      className="form-control"
                      id="rgImage"
                      onChange={(e) => this.setState({ rgImage: e.target.files[0] })}
                    />
                  </div>
                </Col>
                <Col
                  className="col-auto"
                  style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
                >
                  <div
                    style={{
                      boxShadow: '0 0 10px rgba(0,0,0,0.3)',
                      minWidth: '320px',
                      maxWidth: '480px',
                      borderRadius: '5px',
                      overflow: 'hidden',
                      cursor: 'pointer',
                    }}
                  >
                  </div>
                  {localStorage.getItem('access') === 'C' &&
                  <h4 className="card-title">Foto do documento (verso do rg, cnh, ...)</h4>
                  }
                  {localStorage.getItem('access') === 'I' &&
                  <h4 className="card-title">Foto do documento do proprietário (verso do rg, cnh, ...)</h4>
                  }
                  <div className="input-group">
                    <input
                      type="file"
                      className="form-control"
                      id="rgImage"
                      onChange={(e) => this.setState({ rgImageVerso: e.target.files[0] })}
                    />
                  </div>
                </Col>
              </Row>   
            </Container>
          </ModalBody>
          <ModalFooter style={{textAlign:'center'}}>
          {localStorage.getItem('access') === 'C' ?
              ((this.state.rgImageVerso !== '' &&
              this.state.rgImage !== '' &&
              this.state.creciImageVerso !== '' &&
              this.state.creciImage !== '') ?
              <Button onClick={() => {
                this.setState({validateDocs: true})
                setTimeout(() => {
                  this.sendUpdate()
                }, 500);
                    }}>Enviar documentos</Button>
              :
              <Button style={{
                backgroundColor:'#cdcdcd',
                color:'#333',
                cursor:'disabled'
              }} disabled><span style={{color:"#333"}}>Todos os documentos são obrigatórios</span></Button>
              )
            :
              ((this.state.rgImageVerso !== '' &&
              this.state.rgImage !== '' &&
              this.state.creciImageVerso !== '' &&
              this.state.creciImage !== '' &&
              this.state.cnpjImage !== '') ?
              <Button onClick={() => {
                this.setState({validateDocs: true})
                setTimeout(() => {
                  this.sendUpdate()
                }, 500);
                    }}>Enviar documentos</Button>
              :
              <Button style={{
                backgroundColor:'#cdcdcd',
                color:'#333',
                cursor:'disabled'
              }} disabled><span style={{color:"#333"}}>Todos os documentos são obrigatórios</span></Button>
              )
          }
          </ModalFooter>
        </Modal>

        {/* Contrato de autorização de venda */}

        <Modal
          isOpen={this.state.modal_center}
          toggle={this.tog_center}
          centered={true}
        >
          <ModalHeader toggle={() => this.setState({ modal_center: false })}>
            Gerar contrato de autorização de venda
          </ModalHeader>
          <ModalBody>
            <Row style={{marginBottom:'10px'}}>
              <Col>
                <h3 style={{fontSize:'14px', fontWeight:'600'}}>Como funciona</h3>
              </Col>
            </Row>
            <Row className="infoAuthorizationModal">
              <Col>
                <ul>
                  <li>1º Preencha as informações abaixo</li>
                  <li>2º Clique no botão "Gerar autorização"</li>
                  <li>3º Será iniciado o download da autorização</li>
                </ul>
              </Col>
            </Row>
            <Row style={{marginTop:'10px'}}>
              <Col>
                <h3 style={{fontSize:'14px', fontWeight:'600'}}>Sobre o proprietário</h3>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Label style={{fontSize:'12px', fontWeight:'600'}}>E-mail</Label>
                <Input type="email" value={this.state.proprietarioEmail} onChange={(e) => this.setState({proprietarioEmail: e.target.value})}></Input>
              </Col>
              <Col xs={12}>
                <Label style={{fontSize:'12px', fontWeight:'600'}}>Nome completo</Label>
                <Input type="text" value={this.state.proprietarioNome} onChange={(e) => this.setState({proprietarioNome: e.target.value})}></Input>
              </Col>
              <Col xs={6}>
                <Label style={{fontSize:'12px', fontWeight:'600'}}>Rg</Label>
                <Input type="number" value={this.state.proprietarioRg} onChange={(e) => this.setState({proprietarioRg: e.target.value})}></Input>
              </Col>
              <Col xs={6}>
                <Label style={{fontSize:'12px', fontWeight:'600'}}>Cpf</Label>
                <Input type="number" value={this.state.proprietarioCpf} onChange={(e) => this.setState({proprietarioCpf: e.target.value})}></Input>
              </Col>
              <Col xs={12}>
                <Label style={{fontSize:'12px', fontWeight:'600'}}>Endereço proprietário</Label>
                <Input type="text" value={this.state.proprietarioEndereco} onChange={(e) => this.setState({proprietarioEndereco: e.target.value})}></Input>
              </Col>
              <Col xs={12}>
                <Label style={{fontSize:'12px', fontWeight:'600'}}>Descrição e endereço do imóvel</Label>
                <Input type="text" value={this.state.proprietarioImovel} onChange={(e) => this.setState({proprietarioImovel: e.target.value})}></Input>
              </Col>
              <Col xs={12}>
                <Label style={{fontSize:'12px', fontWeight:'600'}}>Valor do imóvel</Label>
                <Input type="text" value={this.state.proprietarioImovelValor} onChange={(e) => this.setState({proprietarioImovelValor: e.target.value})}></Input>
              </Col>
              <Col xs={6}>
                <Label style={{fontSize:'12px', fontWeight:'600'}}>Matrícula do imóvel</Label>
                <Input type="text" value={this.state.proprietarioImovelMatricula} onChange={(e) => this.setState({proprietarioImovelMatricula: e.target.value})}></Input>
              </Col>
              <Col xs={6}>
                <Label style={{fontSize:'12px', fontWeight:'600'}}>Porcentagem de comissão</Label>
                <Input type="text" value={this.state.proprietarioImovelComissao} onChange={(e) => this.setState({proprietarioImovelComissao: e.target.value})}></Input>
              </Col>
            </Row>
            <Row style={{backgroundColor:'#f1ce41', margin:'10px 0 0', borderRadius:'5px', alignItems:'center'}}>
              <Col xs={1}><i className="ri-information-line" style={{fontSize:'20px'}}></i></Col>
              <Col xs={11}><p style={{fontSize:'12px', fontWeight:'600', lineHeight:'1'}}>Estas informações são utilizadas apenas para criação do contrato, e não serão armazenadas em nosso banco de dados</p></Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <button type="button" className="btn btn-light me-2" onClick={() => this.setState({ modal_center: false })}>Fechar</button>
            <button type="submit" className="btn btn-success save-event" onClick={() => {
              document.getElementById('load').classList.add('active')
              const data = new FormData();
              data.append('ownerName', this.state.proprietarioNome)
              data.append('ownerCpfCnpj', this.state.proprietarioCpf)
              data.append('ownerAddress', this.state.proprietarioEndereco)
              data.append('ownerEmail', this.state.proprietarioEmail)
              data.append('userName', user?.name)
              data.append('userCpfCnpj', localStorage.getItem('access') === 'C' ? user.cpf : user.cnpj)
              data.append('userCity', user?.city)
              data.append('userState', user?.state)
              data.append('userStreet', user?.street)
              data.append('userNumber', user?.number)
              data.append('userDistrict', user?.district)
              data.append('userComplement', user?.complement)
              data.append('userCountry', user?.country)
              data.append('userCreci', localStorage.getItem('access') === 'C' ? user.creci : user.creci_j)
              data.append('userEmail', user?.email)
              data.append('propertyDescription', this.state.proprietarioImovel)
              data.append('propertyRegistry', this.state.proprietarioImovelMatricula)
              data.append('propertyValue', this.state.proprietarioImovelValor)
              data.append('percentage', this.state.proprietarioImovelComissao)
              
              const axiosOptions = {
                responseType: 'arraybuffer',
                headers: {
                  'Content-Type': 'application/json',
                  "Authorization": "Bearer " + localStorage.getItem('token')
                }
              }


              Axios.post(process.env.REACT_APP_API + '/api/auth/gerar-pdf-autorizacao', data, axiosOptions,
              {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                .then(response => {
                  const blob = new Blob([response.data], {
                    type: 'application/octet-stream'
                })
                const filename = 'autorizacao_venda_imovel.pdf'
                saveAs(blob, filename)
                setTimeout(() => {
                  this.setState({ modal_center: false })
                  document.getElementById('load').classList.remove('active')
                }, 2000);
              })
              .catch(response => {
                this.setState({auth_error: true})
                document.getElementById('load').classList.remove('active')
              })
            }}>Gerar autorização</button>
          </ModalFooter>
        </Modal>

        {/* Funções administrativas */}

        <Modal
          className='modal-edit-user'
          // style={{zoom: '0.78'}}
          isOpen={this.state.modal_admin}
          toggle={() => this.setState({ modal_admin: false })}
          size="lg"
          centered={true}
        >
          <ModalHeader toggle={() => this.setState({ modal_admin: false })}>
            <h5>Funções administrativas</h5>
          </ModalHeader>
          <ModalBody>
            <Card color="dark" className="text-white-50" style={{margin:'0', padding:'0'}}>
              <CardBody style={{padding: '0.75rem 0.75rem'}}>
                <CardTitle className="text-white" style={{marginBottom:'0'}}><i className="mdi mdi-alert-circle-outline me-3"></i>Atenção</CardTitle>
                <CardText>Todas as ações realizadas aqui, ficam registradas e relacionadas ao usuário administrativo que as realiza.</CardText>
              </CardBody>
            </Card>
            <Row>
              <Col xs={7} style={{margin:'20px 0 10px'}}>
                <Label>Status:</Label>
                <select className="form-control" value={this.state.status} onChange={(e) => this.setState({status : e.target.value})}>
                  <option value={5}>Lead</option>
                  <option value={3}>Pendente (sem docs)</option>
                  <option value={2}>Pendente</option>
                  <option value={1}>Ativo</option>
                  <option value={0}>Inativo</option>
                </select>
              </Col>
              {(access === 'C' || access === 'I' || access === 'T')  &&
                <>
                  <Col xs={5} style={{margin:'20px 0 10px', display:'flex', flexDirection:'column'}}>
                    <Label>Docs:</Label>
                    <Button className="btn-dark" onClick={() => {
                      this.setState({modal_fullscreen: true})
                    }} style={{fontSize:'12px', display:'flex', alignItems:'center', justifyContent:'center', gap:'5px'}}><i className="ri-article-fill"></i>Documentos</Button>
                  </Col>
                  <Col xs={12}>
                    <Label>Observações:</Label>
                    <Input 
                      type="textarea"
                      value={this.state.observacao}
                      onChange={(e) => this.setState({observacao : e.target.value})}
                    />
                  </Col>
                </>
              }
              {((access === 'C' || access === 'I') && localStorage.getItem('access') === 'A') &&
              <Col xs={12} style={{marginTop:'20px', backgroundColor:'#f2f2f2', paddingTop:'8px', paddingBottom:'8px'}}>
                <Label>Origem do cadastro:</Label>
                <Input
                  type="text"
                  value={this.state.feedback}
                  onChange={(e) => this.setState({feedback : e.target.value})}
                ></Input>
              </Col>
              }
              {((access === 'C' || access === 'I' || access === 'P') && localStorage.getItem('access') === 'A') &&
              <Col xs={12} style={{marginTop:'20px', backgroundColor:'#f2f2f2', paddingTop:'8px', paddingBottom:'8px'}}>
                <p><b>Último acesso:</b> {moment(this.state.ultimoAcesso).format('DD/MM/YYYY')}</p>
              </Col>
              }

              {access === 'P' &&
                <>
                  <Col xs={4} style={{marginTop: '.8rem'}}>
                    <Label htmlFor="email">Email:</Label>
                    <Input 
                      id="email"
                      name="email"
                      type="text"
                      value={this.state.email}
                      onChange={(e) => this.setState({email: e.target.value})}
                    />
                  </Col>
                  <Col xs={8} style={{marginTop: '.8rem'}}>
                    <Label htmlFor="name">Nome:</Label>
                    <Input 
                      id="name"
                      name="name"
                      type="text"
                      value={this.state.name}
                      onChange={(e) => this.setState({name: e.target.value})}
                    />
                  </Col>
                  <Col xs={4} style={{marginTop: '.8rem'}}>
                    <Label htmlFor="cpf">CPF:</Label>
                    <Input 
                      id="cpf"
                      name="cpf"
                      type="text"
                      value={this.state.cpf}
                      onChange={(e) => this.setState({cpf: e.target.value})}
                    />
                  </Col>
                  <Col xs={4} style={{marginTop: '.8rem'}}>
                    <Label htmlFor="rg">RG:</Label>
                    <Input 
                      id="rg"
                      name="rg"
                      type="text"
                      value={this.state.rg}
                      onChange={(e) => this.setState({rg: e.target.value})}
                    />
                  </Col>
                </>
              }


              {(access === 'C') && 
                  <>
                  <Col xs={4} style={{marginTop: '.8rem'}}>
                    <Label htmlFor="creci">CRECI:</Label>
                    <Input 
                      id="creci"
                      name="creci"
                      type="text"
                      value={this.state.creci}
                      onChange={(e) => this.setState({creci: e.target.value})}
                    />
                  </Col>
                  <Col xs={4} style={{marginTop: '.8rem'}}>
                    <Label htmlFor="cpf">CPF:</Label>
                    <Input 
                      id="cpf"
                      name="cpf"
                      type="text"
                      value={this.state.cpf}
                      onChange={(e) => this.setState({cpf: e.target.value})}
                    />
                  </Col>
                  <Col xs={4} style={{marginTop: '.8rem'}}>
                    <Label htmlFor="rg">RG:</Label>
                    <Input 
                      id="rg"
                      name="rg"
                      type="text"
                      value={this.state.rg}
                      onChange={(e) => this.setState({rg: e.target.value})}
                    />
                  </Col>
                  <Col xs={4} style={{marginTop: '.8rem'}}>
                    <Label htmlFor="email">Email:</Label>
                    <Input 
                      id="email"
                      name="email"
                      type="text"
                      value={this.state.email}
                      onChange={(e) => this.setState({email: e.target.value})}
                    />
                  </Col>
                  <Col xs={8} style={{marginTop: '.8rem'}}>
                    <Label htmlFor="name">Nome:</Label>
                    <Input 
                      id="name"
                      name="name"
                      type="text"
                      value={this.state.name}
                      onChange={(e) => this.setState({name: e.target.value})}
                    />
                  </Col>
                  </>
              }
              {(access === 'I') && 
                <>
                  <Col xs={6} style={{marginTop: '.8rem'}}>
                    <Label htmlFor="creci_j">CRECI_J:</Label>
                    <Input 
                      id="creci_j"
                      name="creci_j"
                      type="text"
                      value={this.state.creci_j}
                      onChange={(e) => this.setState({creci_j: e.target.value})}
                    />
                  </Col>
                  <Col xs={4} style={{marginTop: '.8rem'}}>
                    <Label htmlFor="email">Email:</Label>
                    <Input 
                      id="email"
                      name="email"
                      type="text"
                      value={this.state.email}
                      onChange={(e) => this.setState({email: e.target.value})}
                    />
                  </Col>
                  <Col xs={4} style={{marginTop: '.8rem'}}>
                    <Label htmlFor="name">Nome:</Label>
                    <Input 
                      id="name"
                      name="name"
                      type="text"
                      value={this.state.name}
                      onChange={(e) => this.setState({name: e.target.value})}
                    />
                  </Col>
                  <Col xs={6} style={{marginTop: '.8rem'}}>
                    <Label htmlFor="corporate_name">Razão Social:</Label>
                    <Input 
                      id="corporate_name"
                      name="corporate_name"
                      type="text"
                      value={this.state.corporateName}
                      onChange={(e) => this.setState({corporateName: e.target.value})}
                    />
                  </Col>
                  <Col xs={4} style={{marginTop: '.8rem'}}>
                    <Label htmlFor="cnpj">CNPJ:</Label>
                    <Input 
                      id="cnpj"
                      name="cnpj"
                      type="text"
                      value={this.state.cnpj}
                      onChange={(e) => this.setState({cnpj: e.target.value})}
                    />
                  </Col>
                  <Col xs={4} style={{marginTop: '.8rem'}}>
                    <Label htmlFor="rg">RG:</Label>
                    <Input 
                      id="rg"
                      name="rg"
                      type="text"
                      value={this.state.owner_rg}
                      onChange={(e) => this.setState({owner_rg: e.target.value})}
                    />
                  </Col>
                  <Col xs={4} style={{marginTop: '.8rem'}}>
                    <Label htmlFor="cpf">CPF:</Label>
                    <Input 
                      id="cpf"
                      name="cpf"
                      type="text"
                      value={this.state.owner_cpf}
                      onChange={(e) => this.setState({owner_cpf: e.target.value})}
                    />
                  </Col>
                </>
              }
              {(access === 'T') && 
                <>
                  <Col xs={4} style={{marginTop: '.8rem'}}>
                    <Label htmlFor="email">Email:</Label>
                    <Input 
                      id="email"
                      name="email"
                      type="text"
                      value={this.state.email}
                      onChange={(e) => this.setState({email: e.target.value})}
                    />
                  </Col>
                  <Col xs={4} style={{marginTop: '.8rem'}}>
                    <Label htmlFor="name">Nome:</Label>
                    <Input 
                      id="name"
                      name="name"
                      type="text"
                      value={this.state.name}
                      onChange={(e) => this.setState({name: e.target.value})}
                    />
                  </Col>
                  <Col xs={4} style={{marginTop: '.8rem'}}>
                    <Label htmlFor="instagram2">Instagram:</Label>
                    <Input 
                      id="instagram2"
                      name="instagram2"
                      type="text"
                      value={this.state.instagram}
                      onChange={(e) => this.setState({instagram: e.target.value})}
                    />
                  </Col>
                  <Col xs={6} style={{marginTop: '.8rem'}}>
                    <Label htmlFor="site">Site:</Label>
                    <Input 
                      id="site"
                      name="site"
                      type="text"
                      value={this.state.site}
                      onChange={(e) => this.setState({site: e.target.value})}
                    />
                  </Col>
                  <Col xs={6} style={{marginTop: '.8rem'}}>
                    <Label htmlFor="corporate_name">Razão Social:</Label>
                    <Input 
                      id="corporate_name"
                      name="corporate_name"
                      type="text"
                      value={this.state.corporateName}
                      onChange={(e) => this.setState({corporateName: e.target.value})}
                    />
                  </Col>
                  <Col xs={4} style={{marginTop: '.8rem'}}>
                    <Label htmlFor="cnpj">CNPJ:</Label>
                    <Input 
                      id="cnpj"
                      name="cnpj"
                      type="text"
                      value={this.state.cnpj}
                      onChange={(e) => this.setState({cnpj: e.target.value})}
                    />
                  </Col>
                  <Col xs={4} style={{marginTop: '.8rem'}}>
                    <Label htmlFor="rg">RG:</Label>
                    <Input 
                      id="rg"
                      name="rg"
                      type="text"
                      value={this.state.owner_rg}
                      onChange={(e) => this.setState({owner_rg: e.target.value})}
                    />
                  </Col>
                  <Col xs={4} style={{marginTop: '.8rem'}}>
                    <Label htmlFor="cpf">CPF:</Label>
                    <Input 
                      id="cpf"
                      name="cpf"
                      type="text"
                      value={this.state.owner_cpf}
                      onChange={(e) => this.setState({owner_cpf: e.target.value})}
                    />
                  </Col>
                </>
              }
              </Row>
              {(access === 'C' || access === 'I') &&
            <>
            <Row>
              <Col lg={3} style={{margin:'20px 0 10px'}}>
                  
                  {/* <Input type="number" value={this.state.saldoPremium} onChange={(e) => this.setState({saldoPremium : e.target.value})}></Input>
                  <small className="text-muted">Atualmente o saldo é XX dias.</small> */}
                  <FormGroup>
                    <Label>Tipo do plano</Label>
                    <select className="form-control" value={this.state.premiumActive} onChange={(e) => this.setState({premiumActive : e.target.value})}>
                      <option value={0}>Free</option>
                      <option value={2}>Start</option>
                      <option value={1}>Premium</option>
                    </select>
                  </FormGroup>
                  <small className="text-muted">Atualmente o saldo é {Math.ceil((new Date(this.state.saldoPremium) - new Date()) / 86400000)} dias.</small>
                  {(this.state.premiumActive === 1 || this.state.premiumActive === 2) && <div onClick={this.handleContractDownload} style={{cursor:'pointer'}}>Download contrato</div>}
              </Col>
              <Col lg={3} style={{margin:'20px 0 10px'}}>
                  
                  {/* <Input type="number" value={this.state.saldoPremium} onChange={(e) => this.setState({saldoPremium : e.target.value})}></Input>
                  <small className="text-muted">Atualmente o saldo é XX dias.</small> */}
                  <FormGroup>
                    <Label>Vencimento do plano</Label>
                    <InputGroup>
                    <Flatpickr
                        className="form-control d-block"
                        placeholder="Selecione um dia"
                        options={{
                        altInput: true,
                        altFormat: "j, F, Y",
                        dateFormat: "Y-m-d",
                        }}
                        locale= {locale}
                        value={this.state.saldoPremium}
                        onChange={(e) => {
                          this.setState({saldoPremium : moment(e.toString()).format('YYYY-MM-DD')})
                        }}
                    />
                    </InputGroup>
                  </FormGroup>
                  <small className="text-muted">Atualmente o saldo é {Math.ceil((new Date(this.state.saldoPremium) - new Date()) / 86400000)} dias.</small>
              </Col>
              <Col lg={3} style={{margin:'20px 0 0'}}>
                  <Label>Quantidade captações</Label>
                  <Input type="number" value={this.state.capta} onChange={(e) => this.setState({capta : e.target.value})}></Input>
                  <small className="text-muted">Atualmente o usuário tem {user?.properties?.length} captações de um total de {user?.p_count}.</small>
              </Col>
              <Col lg={3} style={{margin:'20px 0 10px'}}>
                  
                  {/* <Input type="number" value={this.state.saldoPremium} onChange={(e) => this.setState({saldoPremium : e.target.value})}></Input>
                  <small className="text-muted">Atualmente o saldo é XX dias.</small> */}
                  <FormGroup>
                    <Label>Site</Label>
                    <select className="form-control" value={this.state.activeSite} onChange={(e) => this.setState({activeSite : e.target.value})}>
                      <option value={1}>Ativo</option>
                      <option value={0}>Inativo</option>
                    </select>
                  </FormGroup>
              </Col>
              {access === 'I' &&
                <Col lg={4} style={{margin:'0 0 10px'}}>
                  <Label>Time de corretores</Label>
                  <Input type="number" value={this.state.time} onChange={(e) => this.setState({time : e.target.value})}></Input>
                </Col>
              }
            </Row>
            <Row>
              <Col>
                <Label>Bônus ativos:</Label>
                <div className={"btn-bonus" + " " + this.state.fastAcademy} id="fastAcademy" onClick={() => {
                  if(this.state.fastAcademy === 'inactive'){
                    this.setState({fastAcademy : 'active'})
                  }else{
                    this.setState({fastAcademy : 'inactive'})
                  }
                }}><i className="ri-git-repository-fill"></i>Fast Academy</div>
              </Col>
            </Row>
            </>
            }
            <Row style={{gap:'10px 0', paddingBottom:'15px', paddingTop:'15px', alignItems:'flex-end'}}>
                <Col xs={5}>
                    <Label>Alterar senha</Label>
                    <Input type="password" value={this.state.senha} onChange={(e) => this.setState({ senha : e.target.value })}></Input>
                </Col>
                <Col xs={5}>
                    <Label>Confirmar senha</Label>
                    <Input type="password" value={this.state.confirmacaoSenha} onChange={(e) => this.setState({ confirmacaoSenha : e.target.value })}></Input>
                </Col>
                <Col xs={2} style={{textAlign:'center'}}>
                    {this.state.truePassword ?
                        <Button onClick={() => {
                            document.getElementById('load').classList.add('active')

                            const data = {
                              type: access,
                              user_id: userId,
                              new_password: this.state.senha
                            }
                            Axios.post(process.env.REACT_APP_API + '/api/auth/password-update', data,
                            {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                            .then(response => {
                              this.setState({pass_success: true})
                              document.getElementById('load').classList.remove('active')
                              this.setState({confirmacaoSenha: '', senha: ''})
                            })
                            .catch(response => {
                              this.setState({pass_error: true})
                              document.getElementById('load').classList.remove('active')
                            })
                        }}>Enviar</Button>
                    :
                        <Button disabled>Enviar</Button>
                    }
                </Col>
                <Col xs={12}>
                    <PasswordChecklist
                        rules={["minLength","specialChar","number","capital","match"]}
                        minLength={8}
                        iconSize={16}
                        value={this.state.senha}
                        valueAgain={this.state.confirmacaoSenha}
                        onChange={this.handlePasswordChange}
                        messages={{
                            minLength: "Ao menos 8 caracteres.",
                            specialChar: "Ao menos um caractere especial.",
                            number: "Ao menos um número.",
                            capital: "Ao menos uma letra maiúscula.",
                            match: "Suas senhas coincidem.",
                        }}
                        />
                </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button className="btn btn-light" onClick={() => this.setState({ modal_admin: false })} color="#333">Fechar</Button>
            {localStorage.getItem('level') !== '2' &&
            <Button onClick={() => {
              this.sendUpdate()
            }}>Salvar</Button>}
          </ModalFooter>
        </Modal>

        {/* documentos */}

      <Modal
        size="xl"
        isOpen={this.state.modal_fullscreen}
        toggle={this.tog_fullscreen}
        className="modal-fullscreen"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="exampleModalFullscreenLabel">
            Documentos do usuário
          </h5>
          <button
            onClick={() => this.setState({ modal_fullscreen: false })}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row style={{ gap: '20px' }}>
            <Col
              className="col-auto"
              style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
            >
              <div
                style={{
                  boxShadow: '0 0 10px rgba(0,0,0,0.3)',
                  minWidth: '320px',
                  maxWidth: '480px',
                  borderRadius: '5px',
                  overflow: 'hidden',
                  cursor: 'pointer',
                }}
              >
                {this.state.creciImageUrl?.endsWith('.pdf') ? 
                    <a href={this.state.creciImageUrl} target="_blank" rel="noopener noreferrer">
                      <div
                        style={{
                          width: '100%',
                          height: 'auto',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          fontSize: '20px',
                          color: '#333',
                          padding: '50px',
                          boxSizing: 'border-box',
                          backgroundColor: '#f5f5f5',
                        }}
                      >
                        <i className="ri-file-search-fill" style={{fontSize:'60px', color:'#333'}}></i>
                        <p>Visualizar</p>
                      </div>
                    </a>
                   : 
                    <img
                    src={this.state.creciImageUrl}
                    alt="Creci"
                    style={{
                      width: '100%',
                      height: 'auto',
                      objectFit: 'cover',
                    }}
                  />
                }
              </div>
              <h4 className="card-title">Atualizar documento (Creci Frente)</h4>
              <div className="input-group">
                <input
                  type="file"
                  className="form-control"
                  id="creciImage"
                  onChange={(e) => this.setState({ creciImage: e.target.files[0] })}
                />
              </div>
            </Col>
            <Col
              className="col-auto"
              style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
            >
              <div
                style={{
                  boxShadow: '0 0 10px rgba(0,0,0,0.3)',
                  minWidth: '320px',
                  maxWidth: '480px',
                  borderRadius: '5px',
                  overflow: 'hidden',
                  cursor: 'pointer',
                }}
              >
                {this.state.creciImageVersoUrl?.endsWith('.pdf') ? 
                    <a href={this.state.creciImageVersoUrl} target="_blank" rel="noopener noreferrer">
                      <div
                        style={{
                          width: '100%',
                          height: 'auto',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          fontSize: '20px',
                          color: '#333',
                          padding: '50px',
                          boxSizing: 'border-box',
                          backgroundColor: '#f5f5f5',
                        }}
                      >
                        <i className="ri-file-search-fill" style={{fontSize:'60px', color:'#333'}}></i>
                        <p>Visualizar</p>
                      </div>
                    </a>
                   : 
                    <img
                    src={this.state.creciImageVersoUrl}
                    alt="Creci"
                    style={{
                      width: '100%',
                      height: 'auto',
                      objectFit: 'cover',
                    }}
                  />
                }
              </div>
              <h4 className="card-title">Atualizar documento (Creci Verso)</h4>
              <div className="input-group">
                <input
                  type="file"
                  className="form-control"
                  id="creciImageVerso"
                  onChange={(e) => this.setState({ creciImageVerso: e.target.files[0] })}
                />
              </div>
            </Col>
            <Col
              className="col-auto"
              style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
            >
              <div
                style={{
                  boxShadow: '0 0 10px rgba(0,0,0,0.3)',
                  minWidth: '320px',
                  maxWidth: '480px',
                  borderRadius: '5px',
                  overflow: 'hidden',
                  cursor: 'pointer',
                }}
              >
                  {this.state.rgImageUrl?.endsWith('.pdf') ? 
                    <a href={this.state.rgImageUrl} target="_blank" rel="noopener noreferrer">
                      <div
                        style={{
                          width: '100%',
                          height: 'auto',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          fontSize: '20px',
                          color: '#333',
                          padding: '50px',
                          boxSizing: 'border-box',
                          backgroundColor: '#f5f5f5',
                        }}
                      >
                        <i className="ri-file-search-fill" style={{fontSize:'60px', color:'#333'}}></i>
                        <p>Visualizar</p>
                      </div>
                    </a>
                   : 
                    <img
                    src={this.state.rgImageUrl}
                    alt="Rg"
                    style={{
                      width: '100%',
                      height: 'auto',
                      objectFit: 'cover',
                    }}
                  />
                }
              </div>
              <h4 className="card-title">Atualizar documento (RG Frente)</h4>
              <div className="input-group">
                <input
                  type="file"
                  className="form-control"
                  id="rgImage"
                  onChange={(e) => this.setState({ rgImage: e.target.files[0] })}
                />
              </div>
            </Col>
            <Col
              className="col-auto"
              style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
            >
              <div
                style={{
                  boxShadow: '0 0 10px rgba(0,0,0,0.3)',
                  minWidth: '320px',
                  maxWidth: '480px',
                  borderRadius: '5px',
                  overflow: 'hidden',
                  cursor: 'pointer',
                }}
              >
                  {this.state.rgImageVersoUrl?.endsWith('.pdf') ? 
                    <a href={this.state.rgImageVersoUrl} target="_blank" rel="noopener noreferrer">
                      <div
                        style={{
                          width: '100%',
                          height: 'auto',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          fontSize: '20px',
                          color: '#333',
                          padding: '50px',
                          boxSizing: 'border-box',
                          backgroundColor: '#f5f5f5',
                        }}
                      >
                        <i className="ri-file-search-fill" style={{fontSize:'60px', color:'#333'}}></i>
                        <p>Visualizar</p>
                      </div>
                    </a>
                   : 
                    <img
                    src={this.state.rgImageVersoUrl}
                    alt="Rg"
                    style={{
                      width: '100%',
                      height: 'auto',
                      objectFit: 'cover',
                    }}
                  />
                }
              </div>
              <h4 className="card-title">Atualizar documento (RG Verso)</h4>
              <div className="input-group">
                <input
                  type="file"
                  className="form-control"
                  id="rgImageVerso"
                  onChange={(e) => this.setState({ rgImageVerso: e.target.files[0] })}
                />
              </div>
            </Col>
            {(access === 'I' || access === 'T') &&
            <Col
              className="col-auto"
              style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
            >
              <div
                style={{
                  boxShadow: '0 0 10px rgba(0,0,0,0.3)',
                  minWidth: '320px',
                  maxWidth: '480px',
                  borderRadius: '5px',
                  overflow: 'hidden',
                  cursor: 'pointer',
                }}
              >
                  {this.state.cnpjImageUrl?.endsWith('.pdf') ? 
                    <a href={this.state.cnpjImageUrl} target="_blank" rel="noopener noreferrer">
                      <div
                        style={{
                          width: '100%',
                          height: 'auto',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          fontSize: '20px',
                          color: '#333',
                          padding: '50px',
                          boxSizing: 'border-box',
                          backgroundColor: '#f5f5f5',
                        }}
                      >
                        <i className="ri-file-search-fill" style={{fontSize:'60px', color:'#333'}}></i>
                        <p>Visualizar</p>
                      </div>
                    </a>
                   : 
                    <img
                    src={this.state.cnpjImageUrl}
                    alt="Cartão CNPJ"
                    style={{
                      width: '100%',
                      height: 'auto',
                      objectFit: 'cover',
                    }}
                  />
                }
              </div>
              <h4 className="card-title">Atualizar documento (Cartão CNPJ)</h4>
              <div className="input-group">
                <input
                  type="file"
                  className="form-control"
                  id="cnpjImage"
                  onChange={(e) => this.setState({ cnpjImage: e.target.files[0] })}
                />
              </div>
            </Col>
            }
          </Row>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => this.setState({ modal_fullscreen: false })}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Fechar
          </button>
        </div>
      </Modal>

      {/* Editar SubUser */}

      <EditUserModal
        handleUpdate={() => {
          setTimeout(() => {
            window.location.reload()
          }, 0);
        }}
        noRole={true}
        selected={this.state.user.id}
        onClose={() => this.setState({modal_edit_sub_user: false})}
        open={this.state.modal_edit_sub_user}
      />

      {/* Sucesso na geração do contrato */}

      {this.state.auth_success ? (
          <SweetAlert
          title="Contrato gerado com sucesso, verifique seu e-mail."
          timeout={4000}
          showCloseButton={false}
          showConfirm={false}
          success
          onConfirm={() => this.setState({ auth_success: false })}
          ></SweetAlert>
          ) : null}

      {/* Falha na geração do contrato */}

      {this.state.auth_error ? (
          <SweetAlert
          title="O contrato não pode ser criado, tente novamente."
          timeout={4000}
          showCloseButton={false}
          showConfirm={false}
          error
          onConfirm={() => this.setState({ auth_error: false })}
          ></SweetAlert>
          ) : null}

      {/* Sucesso no agendamento */}

      {this.state.pass_success ? (
          <SweetAlert
          title="Senha alterada com sucesso!"
          timeout={4000}
          showCloseButton={false}
          showConfirm={false}
          success
          onConfirm={() => this.setState({ pass_success: false })}
          ></SweetAlert>
          ) : null}

      {/* Falha no agendamento */}

      {this.state.pass_error ? (
          <SweetAlert
          title="A alteração falhou, tente novamente."
          timeout={4000}
          showCloseButton={false}
          showConfirm={false}
          error
          onConfirm={() => this.setState({ pass_error: false })}
          ></SweetAlert>
          ) : null}

         {/* Sucesso na edição do usuário */}

        {this.state.update_success ? (
            <SweetAlert
              title="Usuário editado com sucesso"
              timeout={2000}
              showCloseButton={false}
              showConfirm={false}
              success
              onConfirm={() => this.setState({ update_success: false })}
            ></SweetAlert>
            ) : null}

          {/* Falha na edição do usuário */}

          {this.state.update_error ? (
            <SweetAlert
              title="O usuário não pode ser editado"
              timeout={2000}
              showCloseButton={false}
              showConfirm={false}
              error
              onConfirm={() => this.setState({ update_error: false })}
            ></SweetAlert>
            ) : null}


          {/* Falha na edição do usuário */}

          {this.state.senhaIncorreta ? (
            <SweetAlert
              title="Senha incorreta"
              timeout={2000}
              showCloseButton={false}
              showConfirm={false}
              error
              onConfirm={() => this.setState({ senhaIncorreta: false })}
            ></SweetAlert>
            ) : null}





          <Modal
            isOpen={this.state.modal_center2}
            toggle={this.tog_center2}
            centered={true}
          >
            <ModalHeader toggle={() => this.setState({ modal_center2: false })}>
              Adicionar Corretor
            </ModalHeader>
            <ModalBody className="modal-add-broker">
              <Label>E-mail do corretor na Fast Sale</Label>
              <Input
                placeholder="E-mail do corretor"
                value={this.state.brokerEmail}
                onChange={(e) => this.setState({brokerEmail: e.target.value})}
              ></Input>
              <ModalFooter className="modal-add-broker-footer">
                <Button onClick={() => {
                  this.setState({ modal_center2: false, brokerEmail: '' })
                  }} className="btn-dark">Cancelar</Button>
                {(this.state.brokerEmail !== '' && this.state.brokerEmail?.includes('@')) ?
                  <Button onClick={() => {
                    Axios.post(process.env.REACT_APP_API + '/api/auth/real-estates-team-find-broker', {
                      email: this.state.brokerEmail,
                      },{headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                      .then(response => {
                        this.setState({addBroker: response.data.corretor, alert_confirm: true})
                      })
                      .catch(response => {
                        this.setState({with_title: true})
                      })
                  }}>Enviar convite</Button>
                :
                <Button disabled>Enviar convite</Button>
                }
              </ModalFooter>
            </ModalBody>
          </Modal>

          {this.state.with_title ? (
              <SweetAlert
                title="Corretor não encontrado"
                danger
                onConfirm={() => this.setState({ with_title: false })}
              >
                Verifique o e-mail e tente novamente.
              </SweetAlert>
            ) : null}

            {this.state.alert_confirm ? (
              <SweetAlert
              title={`Enviar convite para ${this.state.addBroker.name}`}
              warning
              showCancel
              confirmBtnText="Enviar convite"
              cancelBtnText="Cancelar"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="dark"
              onConfirm={() =>
                Axios.post(process.env.REACT_APP_API + '/api/auth/real-estates-team-create', {
                  broker_id: this.state.addBroker.id,
                  real_estate_id: userId,
                  approved: 0
                  },{headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                  .then(response => {
                    this.setState({
                      custom_div1: true,
                      modal_center: false,
                      alert_confirm: false,
                      brokerEmail: ''
                    })
                  })
              }
              onCancel={() =>
                  this.setState({
                      alert_confirm: false,
                      modal_center: false,
                      brokerEmail: ''
                  })
              }
              >
              O corretor precisa aceitar o convite.
              </SweetAlert>
            ) : null}

            {this.state.custom_div1 ? (
              <SweetAlert
                  title="Convite enviado com sucesso."
                  timeout={2000}
                  showCloseButton={false}
                  showConfirm={false}
                  success
                  onConfirm={() => this.setState({ custom_div1: false })}
                ></SweetAlert>
            ) : null}


        {this.state.confirm_both ? (
          <SweetAlert
            title="Atenção!"
            warning
            showCancel
            confirmBtnText="Tenho certeza"
            cancelBtnText="Cancelar"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() =>{
              Axios.post(process.env.REACT_APP_API + '/api/auth/real-estates-team-delete/' +  this.state.delete_id, {},
              {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
              .then(response => {
                this.setState({
                  confirm_both: false,
                  success_dlg: true,
                  dynamic_title: "Deletado",
                  dynamic_description: "Corretor desvinculado do time."
                })
              })
            }}
            onCancel={() =>
              this.setState({
                confirm_both: false,
                error_dlg: true,
                dynamic_title: "Cancelado",
                dynamic_description: "Este corretor ainda faz parte de seu time."
              })
            }
          >
            <p className="alertText">Você tem certeza que deseja desvincular este corretor?</p>
          </SweetAlert>
        ) : null}
          {this.state.success_dlg ? (
            <SweetAlert
              success
              title={this.state.dynamic_title}
              onConfirm={() => {
                this.setState({ success_dlg: false })
                window.location.reload()
              }}
            >
              {this.state.dynamic_description}
            </SweetAlert>
          ) : null}

          {this.state.error_dlg ? (
            <SweetAlert
              error
              title={this.state.dynamic_title}
              onConfirm={() => this.setState({ error_dlg: false })}
            >
              {this.state.dynamic_description}
            </SweetAlert>
          ) : null}

        <div id="load" className="load">
          <Spinner className="me-2" color="success" />
        </div>
      </div>
    );
  }
}


export default EditUser;