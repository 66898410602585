export const Enterprise = () => {
    return (
        <svg
            id="Bahan_copy"
            viewBox="0 0 64 64"
            xmlns="http://www.w3.org/2000/svg"
            data-name="Bahan copy"
            width="38px"
        >
            <g fill="rgb(0,0,0)">
                <path d="m59 58h-2.86v-50a4 4 0 0 0 -4-4h-13.28a4 4 0 0 0 -4 4v2.72l-22.86-5.72a4 4 0 0 0 -5 3.85v49.15h-2a1 1 0 0 0 0 2h54a1 1 0 0 0 0-2zm-23.35-7.79v7.79h-7.3v-7.79zm2 0h1.91a1 1 0 0 0 0-2h-15.12a1 1 0 0 0 0 2h1.91v7.79h-4.92v-6s0-32.42 0-32.43a2 2 0 0 1 2-2h17.14a2 2 0 0 1 2 2v38.43h-4.92zm6.92 2.79h9.57v5h-9.57zm-5.71-47h13.28a2 2 0 0 1 2 2v43h-9.57v-31.43a4 4 0 0 0 -4-4h-3.71v-7.57a2 2 0 0 1 2-2zm-29.09 1.27a2 2 0 0 1 1.72-.36l23.37 5.87v2.79h-11.43a4 4 0 0 0 -4 4v31.43h-10.43v-42.15a2 2 0 0 1 .77-1.58zm-.77 45.73h10.43v5h-10.43z" />
                <path d="m38.42 42.32h-12.84a1 1 0 0 0 0 2h12.84a1 1 0 0 0 0-2z" />
                <path d="m38.42 37.1h-12.84a1 1 0 0 0 0 2h12.84a1 1 0 0 0 0-2z" />
                <path d="m38.42 31.89h-12.84a1 1 0 0 0 0 2h12.84a1 1 0 0 0 0-2z" />
                <path d="m38.42 26.68h-12.84a1 1 0 0 0 0 2h12.84a1 1 0 0 0 0-2z" />
                <path d="m38.42 21.47h-12.84a1 1 0 0 0 0 2h12.84a1 1 0 0 0 0-2z" />
                <path d="m48.15 44.32h2.41a1 1 0 0 0 0-2h-2.41a1 1 0 0 0 0 2z" />
                <path d="m48.15 39.1h2.41a1 1 0 0 0 0-2h-2.41a1 1 0 0 0 0 2z" />
                <path d="m48.15 33.89h2.41a1 1 0 0 0 0-2h-2.41a1 1 0 0 0 0 2z" />
                <path d="m48.15 28.68h2.41a1 1 0 0 0 0-2h-2.41a1 1 0 0 0 0 2z" />
                <path d="m48.15 23.47h2.41a1 1 0 0 0 0-2h-2.41a1 1 0 0 0 0 2z" />
                <path d="m48.15 18.25h2.41a1 1 0 0 0 0-2h-2.41a1 1 0 0 0 0 2z" />
                <path d="m48.15 13h2.41a1 1 0 0 0 0-2h-2.41a1 1 0 0 0 0 2z" />
                <path d="m13 44.32h2.41a1 1 0 1 0 0-2h-2.41a1 1 0 0 0 0 2z" />
                <path d="m13 39.1h2.41a1 1 0 1 0 0-2h-2.41a1 1 0 0 0 0 2z" />
                <path d="m13 33.89h2.41a1 1 0 1 0 0-2h-2.41a1 1 0 0 0 0 2z" />
                <path d="m13 28.68h2.41a1 1 0 1 0 0-2h-2.41a1 1 0 0 0 0 2z" />
                <path d="m13 23.47h2.41a1 1 0 1 0 0-2h-2.41a1 1 0 0 0 0 2z" />
                <path d="m13 18.25h2.41a1 1 0 1 0 0-2h-2.41a1 1 0 0 0 0 2z" />
                <path d="m13 13h2.41a1 1 0 1 0 0-2h-2.41a1 1 0 0 0 0 2z" />
                <path d="m41.15 13h2.42a1 1 0 0 0 0-2h-2.42a1 1 0 0 0 0 2z" />
            </g>
        </svg>
    );
};
