import { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button, Input } from 'reactstrap';

import './../../assets/scss/custom/components/_mktPopUp.scss';
import Axios from 'axios';

import ConstrutorBg from './../../assets/images/popups/construtor_bg.png';
import ConstrutorPc from './../../assets/images/popups/construtor_pc.png';
import ConstrutorTitle from './../../assets/images/popups/construtor_title.png';

import tinder from './../../assets/images/popups/tinder.png';
import tinderBg from './../../assets/images/popups/fundo.png';
import tinderLogo from './../../assets/images/popups/logotinder.png';

import ConstructionBg from './../../assets/images/fundo_construtoras_popup.png';
import ConstructionLogo from './../../assets/images/logo_construtoras.png';

import Countdown from 'react-countdown-simple';

import ParceriaAceita from "./../../assets/images/parceria-aceita.png";
import SweetAlert from 'react-bootstrap-sweetalert';
import UnlockAnimation from './UnlockAnimation';

import BlackFriday from './../../assets/images/black_friday.png'
import Tauff from './../../assets/images/tauf.png'
import BlackFridayMobile from './../../assets/images/black_friday_mobile.png'

const targetDate = new Date('2023-11-21T21:00:00').toISOString();

const renderer = ({ days, hours, minutes, seconds }) =>(
  <div className="serie_countdown">
    <div className="serie_countdown_item">
      <div>{days}</div>
      <p>Dias</p>
    </div>
    <span>:</span>
    <div className="serie_countdown_item">
      <div>{hours}</div>
      <p>Horas</p>
    </div>
    <span>:</span>
    <div className="serie_countdown_item">
      <div>{minutes}</div>
      <p>Minutos</p>
    </div>
  </div>)

class PopUps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_center: false,
      modal_welcome:false,
      modalLevel:0,
      name:'',
      city:'',
      show:false,
      black_modal:false,
    };
    this.tog_center = this.tog_center.bind(this);
    this.tog_black_modal = this.tog_black_modal.bind(this);
  }

  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  tog_center() {
    this.setState(prevState => ({
      modal_center: !prevState.modal_center
    }));
    this.removeBodyCss();
  }
  tog_black_modal() {
    this.setState(prevState => ({
      black_modal: !prevState.black_modal
    }));
    this.removeBodyCss();
  }
  componentDidMount() {
    // if (prevProps.verifyPremium !== this.props.verifyPremium) {
    //   if (this.props.verifyPremium === false) {

        // this.tog_black_modal() 
        Axios.get(
          process.env.REACT_APP_API +
            "/api/auth/get-popup-level/" +
            localStorage.getItem("id") +
            "/" +
            localStorage.getItem("access"),
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        ).then((resp) => {
          this.setState({ modalLevel: resp.data.popup_level });
  
          // if (resp.data.popup_level === null || resp.data.popup_level < 1) {
          //   this.setState({ black_modal: true });
          // }
  
          if (resp.data.popup_level === null || resp.data.popup_level < 1) {
            this.setState({ black_modal: true });
  
            Axios.post(
              process.env.REACT_APP_API + "/api/auth/update-popup-level",
              {
                type_user: localStorage.getItem("access"),
                id: localStorage.getItem("id"),
                popup_level: resp.data.popup_level
                  ? resp.data.popup_level + 1
                  : 1,
              },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              }
            );
          }
        });
    //   }
    // }
  }

  // componentDidMount(){

  //   if(localStorage.getItem('access') === 'C' || localStorage.getItem('access') === 'I'){
  //     const newData = new FormData()
  //     if(localStorage.getItem('access') === 'C'){
  //       newData.append('broker_id', localStorage.getItem('id'))
  //     }
  //     if(localStorage.getItem('access') === 'I'){
  //       newData.append('real_estate_id', localStorage.getItem('id'))
  //     }
  
  //     Axios.post(process.env.REACT_APP_API + '/api/auth/order-new-construction-exist', newData,
  //     {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
  //     .then(response => this.setState({show: false}))
  //     .catch(response => this.setState({show: true}))
  //   }

  // }

  handleSendConstruction = () => {
    const data = new FormData()
    data.append('name', this.state.name)
    data.append('city', this.state.city)
    if(localStorage.getItem('access') === 'C'){
      data.append('broker_id', localStorage.getItem('id'))
    }
    if(localStorage.getItem('access') === 'I'){
      data.append('real_estate_id', localStorage.getItem('id'))
    }
    Axios.post(process.env.REACT_APP_API + '/api/auth/order-new-construction', data,
      {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
      .then(response => {
        this.setState({show: false})
      }).catch(response => {
        this.setState({show: false})
      })
  }

  render(){
    const {modalLevel} = this.state;

    return (
      <>
       <Modal 
          isOpen={this.state.black_modal}
          toggle={this.tog_black_modal}
          centered={true}
        >
          <span className="tauf-popup-close" onClick={this.tog_black_modal}><i className="ri-close-line"></i></span>
          <a className="tauf-popup" href="/empreendimento/momentum-residence" target="_target"><img style={{
            borderRadius:'5px',
            cursor:'pointer',
            width:'100%',
            maxWidth:'640px'
          }} src={Tauff}/></a>
        </Modal>

        {this.state.modal_center ? (
          <SweetAlert
              confirmBtnText="OK"
              confirmBtnBsStyle="success"
              onConfirm={() =>
                this.setState({modal_center : false})
              }
              >
              <span style={{
                textAlign:'center',
                display:'block',
                maxWidth:'90px',
                margin:'10px auto 30px'
              }}><UnlockAnimation/></span>
              <h3 style={{
                fontWeight:600,
                fontSize:'18px'
              }}>Desbloqueado: parcerias ilimitadas!</h3>
              <p className="modal-sweet-text">Removemos o limite de 3 parcerias no plano gratuito, acesse TODAS AS FOTOS e informações de <b>quantos imóveis quiser</b>.</p>
          </SweetAlert>
      ) : null}

      {this.state.modal_welcome ? (
          <SweetAlert
              confirmBtnText="OK"
              confirmBtnBsStyle="success"
              onConfirm={() =>
                this.setState({modal_welcome : false})
              }
              >
              <h3 style={{
                fontWeight:600,
                fontSize:'18px'
              }}>Seja bem-vindo!<br></br>Pronto para decolar com a Fast Sale?</h3>
              <ul className="welcome-modal-list">
                <li>Todos os imóveis possuem autorização de venda, baixe o material e anuncie onde quiser</li>
                <li>A Fast Sale não fica com nenhuma parte da negociação em imóveis parceria, a comissão é dividida 50/50 entres os corretores</li>
                <li>Você possui <b>3 contatos com captador</b> por mês, para contatos ilimitados <a href={`/carrinho?product=${localStorage.getItem('access') === 'C' ? '7' : '9'}&recurrence=year`} target="_blank">assine o Premium</a></li>
                <li>Adicionamos novos imóveis TODOS OS DIAS, inclua a Fast Sale na sua rotina e decole nas vendas</li>
                <li>⁠Quer colocar seus imóveis na plataforma para milhares de corretores venderem? <a href={`/carrinho?product=${localStorage.getItem('access') === 'C' ? '7' : '9'}&recurrence=year`} target="_blank">Seja Premium!</a></li>
              </ul>
          </SweetAlert>
      ) : null}

      </>
    );
  }
}

export default PopUps;