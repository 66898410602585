import React, { Component } from 'react';
import { Col, Dropdown, DropdownMenu, DropdownToggle, DropdownItem, Card, CardBody, Row, Container, Input, Label, Badge, Spinner, CardTitle, CardText, Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { Link } from "react-router-dom";
import Axios from 'axios'

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Breadcrumbs from "../../components/Common/Breadcrumb"

import './../../assets/scss/custom/components/_integration.scss'

import moment from 'moment';
import ReactSelect from 'react-select';
import Select from "react-select";
import SweetAlert from 'react-bootstrap-sweetalert';
import NoPremium from './../../components/noPremium/noPremium';
require('moment/locale/pt.js');


class PropertyIntegration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canalPro: [],
      allCanalPro: [],
      chavesMao: [],
      allChavesMao: [],
      imoveis:[],
      imovel:'',
      addPropertyId:'',
      deletePropertyId:null,
      user:[],
      viewport:[
        {
          dataField: "mobile",
          text: "Imóveis"
        },
      ],
      search:'',
      confirm_both: false,
      success_dlg: false,
      error_dlg:false,
      dynamic_title: "",
      dynamic_description: "",
      delete_id:"",
      delete_sku:"",
      modal_small: false,
      selected_channel:null,
      deleted_channel:null,
      verifyPremium:null,
      selected_type:0,
      breadcrumbItems: [
        { title: "Fast Sale", link: "/dashboard" },
        { title: "Integrações", link: "#" },
    ],
    }
    this.tog_small = this.tog_small.bind(this);
  }


  componentDidMount(){
      document.getElementById('load').classList.add('active')
      Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
        type: localStorage.getItem('access'),
        user_id: localStorage.getItem('id'),
        }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {

          Axios.post(process.env.REACT_APP_API + '/api/verify-premium', {
            type:localStorage.getItem('access'),
            user_id:localStorage.getItem('id')
            }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
          .then(response => {
            if(response.data.premium === 1 || response.data.premium === 2) {
              this.setState({verifyPremium: true})
            } else {
              this.setState({verifyPremium: false})
            }
          })
          .catch(response => {
            this.setState({verifyPremium: false})
          })

          if(window.screen.width > 768){
            this.setState({viewport: [
              {
                dataField: "status",
                text: "Status"
              },
              {
                dataField: 'sku',
                text: 'Código'
              },
              {
                dataField: "image",
                text: "Foto"
              },
              {
                dataField: "title",
                text: "Nome"
              },
              {
                dataField: "created_at",
                classes:"formatDate",
                text: "Cadastro"
              },
              {
                dataField: "sale_value",
                text: "Valor"
              },
              {
                dataField: "workflow",
                text: "WorkFlow"
              },
              {
                dataField: "edit",
                events: {
                  onClick: (e, column, columnIndex, row, rowIndex) => { 
                    // localStorage.setItem('edit_property_sku', row.sku)
                    setTimeout(() => {
                       window.open( row.slug , '_parent')
                    }, 300);
                   },
                },
                text: "Ver"
              },
              {
                dataField: "delete",
                events: {
                  onClick: (e, column, columnIndex, row, rowIndex) => { 
                    this.setState({ confirm_both: true })
                    this.setState({ delete_id: row.integration_id })
                    this.setState({ delete_sku: row.sku })
                    this.setState({ deleted_channel: row.channel })
                    this.setState({ deletePropertyId: row.id })
                  },
                },
                text: "Excluir"
              },
            ],
            })}

          if(localStorage.getItem('access') === 'C'){
            Axios.get(process.env.REACT_APP_API + '/api/auth/brokers/' + localStorage.getItem('id'),
            {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(response => {
              this.setState({user: response.data})
              setTimeout(() => {
                document.getElementById('load').classList.remove('active')
              }, 500);
            })}
            if(localStorage.getItem('access') === 'I'){
              Axios.get(process.env.REACT_APP_API + '/api/auth/real-estates/' + localStorage.getItem('id'),
              {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
              .then(response => {
                this.setState({user: response.data})
                setTimeout(() => {
                  document.getElementById('load').classList.remove('active')
                }, 500);
              })}      
          Axios.post(process.env.REACT_APP_API + '/api/auth/get-integration-list', {
            type: localStorage.getItem('access'),
            user_id: localStorage.getItem('id')
          }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
              .then(response => {
                  this.setState({canalPro: response.data.filter(result => result.platform === 1)})
                  this.setState({allCanalPro: response.data.filter(result => result.platform === 1)})
                  this.setState({chavesMao: response.data.filter(result => result.platform === 2)})
                  this.setState({allChavesMao: response.data.filter(result => result.platform === 2)})

                  console.log(response.data.filter(result => result.platform === 2))
              })
    
              Axios.post(process.env.REACT_APP_API + '/api/public/property-filter-no-pagination',  {workflow:['7']},
              { headers: { Authorization: 'Bearer ' + localStorage.getItem('token')}})
              .then(response => {
                  for(var i = 0; response.data.length > i; i++){
                      this.state.imoveis.push({
                        value: response.data[i].id,
                        label: response.data[i].sku + " - " + response.data[i].title
                      })
                    }
              })

        })
        .catch(response =>{
          localStorage.removeItem("history_url")
          setTimeout(() => {
            window.open("/login", '_parent')
          }, 300);
        })
  }

  tog_small() {
    this.setState(prevState => ({
      modal_small: !prevState.modal_small
    }));
    this.removeBodyCss();
  }

  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  render() {
    const {canalPro, allCanalPro, chavesMao, allChavesMao, user} = this.state;

    const options = {
      // pageStartIndex: 0,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: false,
      sizePerPageList:
        [{
          text: '5 resultados p/ página', value: 5
        }, {
          text: '10 resultados p/ página', value: 10
        }, {
          text: 'Todos imóveis', value: canalPro.length
        }]

    };


    const canalProImoveisObject = []

    {canalPro.map(imovel => {
      const novoImovel = {
        id: imovel?.properties?.id,
        edit: <i className="ri-eye-fill"></i>,
        delete: <i className="ri-delete-bin-6-line"></i>,
        sku: imovel?.properties?.sku,
        title: imovel?.properties?.title,
        channel: 1,
        image: <img src={imovel?.properties?.photos.length > 0 ? imovel?.properties?.photos.sort((a, b) => a.order - b.order)[0]?.small_image : ''} style={{
          objectFit:'cover', width:'100px', height:'80px', borderRadius:'5px'
        }}></img>,
        integration_id: imovel?.id,
        created_at: moment(imovel?.properties?.created_at).format('DD/MM/YYYY'),
        sale_value: (imovel?.properties?.sale_value / 100).toLocaleString('pt-br', {minimumFractionDigits: 2}),
        workflow: imovel?.properties?.workflow === 0 ? <Badge className="bg-warning me-1 rounded-pill">Pendente</Badge>
        : imovel?.properties?.workflow === 1 ? <Badge className="bg-danger me-1 rounded-pill">Rep. Fotos</Badge>
        : imovel?.properties?.workflow === 2 ? <Badge className="bg-danger me-1 rounded-pill">Rep. Aut. Venda</Badge>
        : imovel?.properties?.workflow === 3 ? <Badge className="bg-danger me-1 rounded-pill">Rep. Prop.</Badge>
        : imovel?.properties?.workflow === 4 ? <Badge className="bg-danger me-1 rounded-pill">Rep. Imóvel</Badge>
        : imovel?.properties?.workflow === 11 ? <Badge className="bg-danger me-1 rounded-pill">Rep. Fora do padrão</Badge>
        : imovel?.properties?.workflow === 5 ? <Badge className="bg-dark me-1 rounded-pill">Repetido</Badge>
        : imovel?.properties?.workflow === 6 ? <Badge className="badge-soft-dark rounded-pill me-1">Vendido (ext)</Badge>
        : imovel?.properties?.workflow === 8 ? <Badge className="badge-soft-dark rounded-pill me-1">Vendido (fs)</Badge>
        : imovel?.properties?.workflow === 9 ? <Badge className="bg-danger me-1 rounded-pill">Rep. Imóvel</Badge>
        : imovel?.properties?.workflow === 10 ? <Badge className="badge-soft-dark rounded-pill me-1">Bloqueado</Badge>
        : imovel?.properties?.workflow === 13 ? <Badge className="bg-warning rounded-pill me-1">Pendente Atualização</Badge>
        : imovel?.properties?.workflow === 14 ? <Badge className="bg-danger rounded-pill me-1">Bloqueado Atualização</Badge>
        : <Badge className="bg-success me-1 rounded-pill">Aprovado</Badge>,
        status: imovel?.properties?.status === 1 ? <i className="ri-checkbox-blank-circle-fill" style={{color:'#62c90e'}}></i> : <i className="ri-checkbox-blank-circle-fill" style={{color:'#da2c3e'}}></i>,
        slug: imovel?.properties?.slug,
        mobile:<Container style={{padding:'0'}}>
        <Row style={{marginTop:'15px'}}>
          <Col className="col-auto">
            <img src={imovel?.properties?.photos.length > 0 ? imovel?.properties?.photos.sort((a, b) => a.order - b.order)[0]?.small_image : ''} style={{
              objectFit:'cover', width:'100px', height:'80px', borderRadius:'5px'
            }}></img>
          </Col>
          <Col>
          <p style={{lineHeight:'1.2'}}>{imovel?.properties?.sku}</p>
          <small>{imovel?.properties?.title}</small>
          <div style={{marginTop:'10px'}}><p style={{lineHeight:'1.2'}}>R$ {(imovel?.properties?.sale_value / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}</p></div>
          </Col>
        </Row>
        <Row style={{marginTop:'15px', justifyContent:'flex-end'}}>
          <Col className="col-auto" style={{textAlign:'right'}}><i className="ri-eye-fill" onClick={() => {
               localStorage.setItem('edit_property_sku', imovel.properties?.sku)
               setTimeout(() => {
                  window.open("./../imovel/" + imovel?.properties?.slug , '_parent')
               }, 300);
            }}></i></Col>
          <Col className="col-auto" style={{textAlign:'right'}}><i className="ri-delete-bin-6-line" onClick={() => {
              this.setState({ confirm_both: true })
              this.setState({ delete_id: imovel?.id })
              this.setState({ delete_sku: imovel?.properties?.sku })
              this.setState({ deleted_channel: imovel?.platform })
              this.setState({ deletePropertyId: imovel?.properties.id })
          }}></i></Col>
        </Row>
      </Container>,
      }
      canalProImoveisObject.push(novoImovel); 
    })}

    const chavesMaoImoveisObject = []

    {chavesMao.map(imovel => {
      const novoImovel = {
        id: imovel?.properties?.id,
        edit: <i className="ri-eye-fill"></i>,
        delete: <i className="ri-delete-bin-6-line"></i>,
        sku: imovel?.properties?.sku,
        title: imovel?.properties?.title,
        channel: 2,
        image: <img src={imovel?.properties?.photos.length > 0 ? imovel?.properties?.photos.sort((a, b) => a.order - b.order)[0]?.small_image : ''} style={{
          objectFit:'cover', width:'100px', height:'80px', borderRadius:'5px'
        }}></img>,
        integration_id: imovel?.id,
        created_at: moment(imovel?.properties?.created_at).format('DD/MM/YYYY'),
        sale_value: (imovel?.properties?.sale_value / 100).toLocaleString('pt-br', {minimumFractionDigits: 2}),
        workflow: imovel?.properties?.workflow === 0 ? <Badge className="bg-warning me-1 rounded-pill">Pendente</Badge>
        : imovel?.properties?.workflow === 1 ? <Badge className="bg-danger me-1 rounded-pill">Rep. Fotos</Badge>
        : imovel?.properties?.workflow === 2 ? <Badge className="bg-danger me-1 rounded-pill">Rep. Aut. Venda</Badge>
        : imovel?.properties?.workflow === 3 ? <Badge className="bg-danger me-1 rounded-pill">Rep. Prop.</Badge>
        : imovel?.properties?.workflow === 4 ? <Badge className="bg-danger me-1 rounded-pill">Rep. Imóvel</Badge>
        : imovel?.properties?.workflow === 11 ? <Badge className="bg-danger me-1 rounded-pill">Rep. Fora do padrão</Badge>
        : imovel?.properties?.workflow === 5 ? <Badge className="bg-dark me-1 rounded-pill">Repetido</Badge>
        : imovel?.properties?.workflow === 6 ? <Badge className="badge-soft-dark rounded-pill me-1">Vendido (ext)</Badge>
        : imovel?.properties?.workflow === 8 ? <Badge className="badge-soft-dark rounded-pill me-1">Vendido (fs)</Badge>
        : imovel?.properties?.workflow === 9 ? <Badge className="bg-danger me-1 rounded-pill">Rep. Imóvel</Badge>
        : imovel?.properties?.workflow === 10 ? <Badge className="badge-soft-dark rounded-pill me-1">Bloqueado</Badge>
        : imovel?.properties?.workflow === 13 ? <Badge className="bg-warning rounded-pill me-1">Pendente Atualização</Badge>
        : imovel?.properties?.workflow === 14 ? <Badge className="bg-danger rounded-pill me-1">Bloqueado Atualização</Badge>
        : <Badge className="bg-success me-1 rounded-pill">Aprovado</Badge>,
        status: imovel?.properties?.status === 1 ? <i className="ri-checkbox-blank-circle-fill" style={{color:'#62c90e'}}></i> : <i className="ri-checkbox-blank-circle-fill" style={{color:'#da2c3e'}}></i>,
        slug: imovel?.properties?.slug,
        mobile:<Container style={{padding:'0'}}>
        <Row style={{marginTop:'15px'}}>
          <Col className="col-auto">
            <img src={imovel?.properties?.photos.length > 0 ? imovel?.properties?.photos.sort((a, b) => a.order - b.order)[0]?.small_image : ''} style={{
              objectFit:'cover', width:'100px', height:'80px', borderRadius:'5px'
            }}></img>
          </Col>
          <Col>
          <p style={{lineHeight:'1.2'}}>{imovel?.properties?.sku}</p>
          <small>{imovel?.properties?.title}</small>
          <div style={{marginTop:'10px'}}><p style={{lineHeight:'1.2'}}>R$ {(imovel?.properties?.sale_value / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}</p></div>
          </Col>
        </Row>
        <Row style={{marginTop:'15px', justifyContent:'flex-end'}}>
          <Col className="col-auto" style={{textAlign:'right'}}><i className="ri-eye-fill" onClick={() => {
               localStorage.setItem('edit_property_sku', imovel.properties?.sku)
               setTimeout(() => {
                  window.open("./../imovel/" + imovel?.properties?.slug , '_parent')
               }, 300);
            }}></i></Col>
          <Col className="col-auto" style={{textAlign:'right'}}><i className="ri-delete-bin-6-line" onClick={() => {
              this.setState({ confirm_both: true })
              this.setState({ delete_id: imovel?.id })
              this.setState({ delete_sku: imovel?.properties?.sku })
              this.setState({ deleted_channel: imovel?.platform })
              this.setState({ deletePropertyId: imovel?.properties.id })
          }}></i></Col>
        </Row>
      </Container>,
      }
      chavesMaoImoveisObject.push(novoImovel); 
    })}


    return (
        <div className="page-content" id="integrationPage">
        <Container fluid={true}>
          <Breadcrumbs title="Integrações" breadcrumbItems={this.state.breadcrumbItems} />
          {this.state.verifyPremium ?
            <>
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col>
                          <Card color="info" className="text-white-50">
                            <CardBody>
                              <Row>
                                <Col xs={12} md={3}>
                                  <h3 className="integrationLabel">Canal Pro</h3>
                                </Col>
                                <Col xs={8} sm={9} md={6}>
                                  <Input id="canalpro" name="canalpro" value={user.integration_url_cp} readonly></Input>
                                </Col>
                                <Col xs={4} sm={3} md={3}>
                                  <Button className="copyButton" onClick={() => {
                                  let text = document.getElementById("canalpro")
                                  text.select()
                                  text.setSelectionRange(0, 99999)
                                  document.execCommand("copy")
                                  }}>Copiar</Button>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                      <Row style={{marginBottom:'20px'}}>
                        <Col xs={8} md={6} lg={4}>
                          <Label>Adicionar imóvel:</Label>
                          <Select
                          value={this.state.imovel}
                          placeholder="Selecione"
                          noOptionsMessage={({ inputValue: string }) => string | null }
                          onChange={(e) => {
                              this.setState({ imovel: e })
                              this.setState({addPropertyId: e.value})
                          }}
                          options={this.state.imoveis}
                          classNamePrefix="select2-selection"
                          />
                        </Col>
                        <Col xs={4} md={2} lg={1}>
                          <Button style={{marginTop:'21px'}}
                            type="button"
                            onClick={() => {this.tog_small(); this.setState({selected_channel: 1})}}
                            color="primary" className="waves-effect waves-light"
                            data-toggle="modal"
                            data-target=".bs-example-modal-sm">Adicionar</Button>
                        </Col>
                      </Row>
                      <Row style={{justifyContent:'flex-end', marginBottom: '10px'}}>
                        <Col style={{display:'flex', flexDirection:'row', maxWidth: '240px'}}>
                          <Input
                          placeholder="Buscar código"
                          onChange={(e) => this.setState({canalPro: allCanalPro.filter(imovel => imovel.sku.toLowerCase().includes(e.target.value.toLowerCase()))})}></Input>
                        </Col>
                      </Row>
                      <div className="table-responsive">
                      <BootstrapTable
                        keyField='id'
                        data={canalProImoveisObject}
                        columns={this.state.viewport}
                        // expandRow={expandRow}
                        pagination={paginationFactory(options)}
                        // selectRow={false}
                      />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card>
                  <CardBody>
                      <Row>
                        <Col>
                          <Card color="info" className="text-white-50">
                            <CardBody>
                              <Row>
                                <Col xs={12} md={3}>
                                  <h3 className="integrationLabel">Chaves na mão</h3>
                                </Col>
                                <Col xs={8} sm={9} md={6}>
                                  <Input id="chaves" name="chaves" value={user.integration_url_cm} readonly></Input>
                                </Col>
                                <Col xs={4} sm={3} md={3}>
                                  <Button className="copyButton" onClick={() => {
                                  let text = document.getElementById("chaves")
                                  text.select()
                                  text.setSelectionRange(0, 99999)
                                  document.execCommand("copy")
                                  }}>Copiar</Button>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                      <Row style={{marginBottom:'20px'}}>
                        <Col xs={8} md={6} lg={4}>
                          <Label>Adicionar imóvel:</Label>
                          <Select
                          value={this.state.imovel}
                          placeholder="Selecione"
                          noOptionsMessage={({ inputValue: string }) => string | null }
                          onChange={(e) => {
                              this.setState({ imovel: e })
                              this.setState({addPropertyId: e.value})
                          }}
                          options={this.state.imoveis}
                          classNamePrefix="select2-selection"
                          />
                        </Col>
                        <Col xs={4} md={2} lg={1}>
                          <Button style={{marginTop:'21px'}}
                            type="button"
                            onClick={() => {this.tog_small(); this.setState({selected_channel: 2})}}
                            color="primary" className="waves-effect waves-light"
                            data-toggle="modal"
                            data-target=".bs-example-modal-sm">Adicionar</Button>
                        </Col>
                      </Row>
                      <Row style={{justifyContent:'flex-end', marginBottom: '10px'}}>
                        <Col style={{display:'flex', flexDirection:'row', maxWidth: '240px'}}>
                          <Input
                          placeholder="Buscar código"
                          onChange={(e) => this.setState({chavesMao: allChavesMao.filter(imovel => imovel.sku.toLowerCase().includes(e.target.value.toLowerCase()))})}></Input>
                        </Col>
                      </Row>
                      <div className="table-responsive">
                      <BootstrapTable
                        keyField='id'
                        data={chavesMaoImoveisObject}
                        columns={this.state.viewport}
                        // expandRow={expandRow}
                        pagination={paginationFactory(options)}
                        // selectRow={false}
                      />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          :
            <NoPremium/>
          }
        </Container>
        <Modal
          size="sm"
          isOpen={this.state.modal_small}
          toggle={this.tog_small}
        >
          <ModalHeader toggle={() => this.setState({ modal_small: false })}>
              {this.state.selected_channel === 1 ? 'Integrar com Canal Pro' : 'Integrar com Chaves na Mão'}
          </ModalHeader>
          <ModalBody>
            <div style={{ marginBottom:'10px'}}>
              <p>Você está integrando o imóvel: <br></br>{this.state.imovel.label}</p>
            </div>
            {this.state.selected_channel === 1 &&    
            <>    
              <div style={{ marginBottom:'20px'}}>
                <h5 style={{fontSize:'14px'}}>Escolha o tipo de anúncio:</h5> 
              </div>
              <div className="form-check mb-3">
                  <Input className="form-check-input" type="radio" name="exampleRadios" onClick={(e) => this.setState({selected_type: e.target.value})} id="simples" value={0} defaultChecked />
                  <Label className="form-check-label" htmlFor="simples">
                      Simples
                  </Label>
              </div>
              <div className="form-check mb-3">
                  <Input className="form-check-input" type="radio" name="exampleRadios" onClick={(e) => this.setState({selected_type: e.target.value})} id="destaque" value={1} />
                  <Label className="form-check-label" htmlFor="destaque">
                      Destaque
                  </Label>
              </div>
              <div className="form-check mb-3">
                  <Input className="form-check-input" type="radio" name="exampleRadios" onClick={(e) => this.setState({selected_type: e.target.value})} id="superDestaque" value={2} />
                  <Label className="form-check-label" htmlFor="superDestaque">
                      Super destaque
                  </Label>
              </div>
            </>
            }
            <div style={{display:'flex', justifyContent:'space-between', marginTop:'40px'}}>
              <Button className="btn-dark" onClick={() => this.setState({ modal_small: false })}>Cancelar</Button>
              <Button onClick={() => {
                const data = new FormData()
                data.append('property_id', this.state.addPropertyId)
                data.append('user_id', localStorage.getItem('id'))
                data.append('type', localStorage.getItem('access'))
                data.append('platform', this.state.selected_channel)
                data.append('integration_type', this.state.selected_type)
                Axios.post(process.env.REACT_APP_API + '/api/auth/add-integration', data, 
                {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                .then(response => {
                  setTimeout(() => {
                    window.location.reload()
                  }, 300);
                })
                .catch(response => {})
              }}>Integrar</Button>
            </div>
          </ModalBody>
        </Modal>
        {this.state.confirm_both ? (
          <SweetAlert
            title="Atenção!"
            warning
            showCancel
            confirmBtnText="Tenho certeza"
            cancelBtnText="Cancelar"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() =>
              {this.setState({
                confirm_both: false,
                success_dlg: true,
                dynamic_title: "Deletado",
                dynamic_description: "Seu imóvel foi excluído da lista de integração."
              })

              const data = new FormData();
              data.append('user_id', localStorage.getItem('id'))
              data.append('type', localStorage.getItem('access'))
              data.append('platform', this.state.deleted_channel)
              Axios.post(process.env.REACT_APP_API + '/api/auth/remove-integration/' + this.state.delete_id, data,
              {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
              .then(response => {

              }) 
            }}
            onCancel={() =>
              this.setState({
                confirm_both: false,
                error_dlg: true,
                dynamic_title: "Cancelado",
                dynamic_description: "Seu imóvel ainda está na lista de integração."
              })
            }
          >
            <p className="alertText">Você tem certeza que deseja excluir o imóvel <b>{this.state.delete_sku}</b> da lista de integração?</p>
          </SweetAlert>
        ) : null}
          {this.state.success_dlg ? (
            <SweetAlert
              success
              title={this.state.dynamic_title}
              onConfirm={() => {
                this.setState({ success_dlg: false })
                setTimeout(() => {
                  window.location.reload()
                }, 300);
              }}
            >
              {this.state.dynamic_description}
            </SweetAlert>
          ) : null}

          {this.state.error_dlg ? (
            <SweetAlert
              error
              title={this.state.dynamic_title}
              onConfirm={() => this.setState({ error_dlg: false })}
            >
              {this.state.dynamic_description}
            </SweetAlert>
          ) : null}
        <div id="load" className="load">
          <Spinner className="me-2" color="success" />
        </div>
      </div>
    );
  }
}


export default PropertyIntegration;