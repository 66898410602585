export const Campaign = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" width="40" height="38">
            <g id="Outer_Lines" data-name="Outer Lines">
                <path d="M24.054,25.677a2.6,2.6,0,0,0,2.759-.163,2.727,2.727,0,0,0,1.107-2.69l-.4-2.427,1.691-1.711a2.75,2.75,0,0,0,.646-2.812,2.657,2.657,0,0,0-2.125-1.811l-2.306-.348-1.038-2.186a2.628,2.628,0,0,0-4.774,0l-1.038,2.186-2.306.348a2.657,2.657,0,0,0-2.125,1.811,2.75,2.75,0,0,0,.646,2.812L16.481,20.4l-.4,2.427a2.727,2.727,0,0,0,1.107,2.69,2.594,2.594,0,0,0,2.759.163L22,24.555Zm-5.067-1.756a.582.582,0,0,1-.643-.039.726.726,0,0,1-.29-.732l.484-2.929a1,1,0,0,0-.276-.867l-2.049-2.075a.754.754,0,0,1-.171-.773.662.662,0,0,1,.525-.465l2.832-.428a1,1,0,0,0,.754-.56l1.267-2.666a.628.628,0,0,1,1.16,0l1.267,2.666a1,1,0,0,0,.754.56l2.832.428a.662.662,0,0,1,.525.465.754.754,0,0,1-.171.773l-2.049,2.075a1,1,0,0,0-.276.867l.484,2.929a.726.726,0,0,1-.29.732.587.587,0,0,1-.643.039l-2.534-1.384a1,1,0,0,0-.958,0Z" />
                <path d="M51.309,29.76l3.173-5.3A3.6,3.6,0,0,0,51.4,19H50.63A6.089,6.089,0,0,0,45,16a8.092,8.092,0,0,0-1,.071V8a4,4,0,0,0-4-4h-.184A2.966,2.966,0,0,0,40,3a3,3,0,0,0-3-3H7A3,3,0,0,0,4,3a2.966,2.966,0,0,0,.184,1H4A4,4,0,0,0,0,8V18a6.006,6.006,0,0,0,6,6h.527A16.046,16.046,0,0,0,16,34.822V46H11a3,3,0,0,0-3,3v3a3,3,0,0,0-3,3v3a2,2,0,0,0,2,2H37a2,2,0,0,0,1.834-1.207A16.308,16.308,0,0,0,45,60c8.271,0,15-6.056,15-13.5C60,41.107,56.088,33.669,51.309,29.76Zm-2.363-9.667-.916,3.665a1,1,0,0,0,.728,1.212A1.017,1.017,0,0,0,49,25a1,1,0,0,0,.969-.758L50.78,21H51.4a1.607,1.607,0,0,1,1.373,2.434L49.434,29H40.566l-3.334-5.566A1.607,1.607,0,0,1,38.6,21h.615l.81,3.242A1,1,0,0,0,41,25a1.017,1.017,0,0,0,.243-.03,1,1,0,0,0,.728-1.212l-.917-3.666A4.128,4.128,0,0,1,45,18,4.1,4.1,0,0,1,48.946,20.093ZM30.023,46H28V34.813a16.07,16.07,0,0,0,8.663-8.44l2.028,3.386C34.056,33.55,30.249,40.654,30.023,46ZM42,8v8.615A5.777,5.777,0,0,0,39.376,19H38.6a3.78,3.78,0,0,0-.6.055V6h2A2,2,0,0,1,42,8ZM6,3A1,1,0,0,1,7,2H37a1,1,0,0,1,0,2H7A1,1,0,0,1,6,3ZM6,22a4,4,0,0,1-4-4V8A2,2,0,0,1,4,6H6V20a15.9,15.9,0,0,0,.139,2Zm2-2V6H36V20c0,.041,0,.079,0,.12a3.67,3.67,0,0,0-.525.706,3.559,3.559,0,0,0-.114,3.327A13.994,13.994,0,0,1,8,20ZM18,35.473a15.616,15.616,0,0,0,8,.012V46H18ZM10,49a1,1,0,0,1,1-1H30.1a12.363,12.363,0,0,0,1.217,4H10Zm27,9H7V55a1,1,0,0,1,1-1H32.536A14.516,14.516,0,0,0,37,57.9Zm8,0c-7.168,0-13-5.159-13-11.5,0-4.93,3.864-12.082,8.332-15.5h9.335C54.136,34.418,58,41.571,58,46.5,58,52.841,52.168,58,45,58Z" />
                <path d="M48,40a1,1,0,0,0,2,0,5.009,5.009,0,0,0-4-4.9V35a1,1,0,0,0-2,0v.1a5,5,0,0,0,0,9.8v5.917A3,3,0,0,1,42,48a1,1,0,0,0-2,0,5.009,5.009,0,0,0,4,4.9V53a1,1,0,0,0,2,0v-.1a5,5,0,0,0,0-9.8V37.184A3,3,0,0,1,48,40Zm-6,0a3,3,0,0,1,2-2.816v5.632A3,3,0,0,1,42,40Zm6,8a3,3,0,0,1-2,2.816V45.184A3,3,0,0,1,48,48Z" />
            </g>
        </svg>
    );
};