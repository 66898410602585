export const Delivery = () => {
    return (
        <svg 
            id="Layer_1" 
            enableBackground="new 0 0 512 512" 
            viewBox="0 0 512 512"
            width="38px"
            height="38px"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="m269.03 175.41c0 3.31-2.69 6-6 6h-83.89c-3.31 0-6-2.69-6-6s2.69-6 6-6h83.89c3.32 0 6 2.69 6 6zm-6 56.58h-83.89c-3.31 0-6 2.69-6 6s2.69 6 6 6h83.89c3.31 0 6-2.69 6-6s-2.68-6-6-6zm0 62.57h-83.89c-3.31 0-6 2.69-6 6s2.69 6 6 6h83.89c3.31 0 6-2.69 6-6s-2.68-6-6-6zm0 62.58h-83.89c-3.31 0-6 2.69-6 6s2.69 6 6 6h83.89c3.31 0 6-2.69 6-6s-2.68-6-6-6zm232.41-239.1-26.6 29.23c-1.14 1.25-2.75 1.96-4.44 1.96s-3.3-.71-4.44-1.96l-26.6-29.23c-1.6-1.76-2.01-4.29-1.05-6.46s3.11-3.57 5.49-3.57h20.39c-3.09-45.18-40.83-81-86.79-81-47.97 0-87 39.03-87 87s39.03 87 87 87c20.03 0 39.58-6.97 55.05-19.63 2.56-2.1 6.34-1.72 8.44.84 2.1 2.57 1.72 6.34-.84 8.44-13.72 11.23-30.27 18.5-47.68 21.17v273.17h34.81c3.31 0 6 2.69 6 6s-2.69 6-6 6h-400.18c-3.31 0-6-2.69-6-6s2.69-6 6-6h34.81v-313.69c0-3.31 2.69-6 6-6h54.65v-60.88c0-3.31 2.69-6 6-6h151.17c7.49-47.22 48.48-83.43 97.77-83.43 52.57 0 95.7 41.19 98.81 93h20.79c2.38 0 4.53 1.4 5.49 3.57s.55 4.71-1.05 6.47zm-378.98 59.27h-48.65v307.69h48.65zm134.11 250.88h-58.97v56.81h58.98v-56.81zm75.14 56.81h48.65v-272.07c-.99.03-1.97.06-2.96.06-16.47 0-32.01-4.05-45.69-11.19zm-53.31-371c0-1.19.03-2.38.07-3.57h-144.01v374.57h51.14v-62.81c0-3.31 2.69-6 6-6h70.98c3.31 0 6 2.69 6 6v62.81h51.14v-290.6c-25.01-17.99-41.32-47.32-41.32-80.4zm205.03 6h-26.06l13.03 14.32zm-73.71-36-47.26 47.26-17.38-17.38c-2.34-2.34-6.14-2.34-8.49 0-2.34 2.34-2.34 6.14 0 8.48l21.62 21.62c1.17 1.17 2.71 1.76 4.24 1.76s3.07-.59 4.24-1.76l51.51-51.51c2.34-2.34 2.34-6.14 0-8.48-2.34-2.33-6.14-2.33-8.48.01z" />
        </svg>
    );
};